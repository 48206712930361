import React from 'react'
import PropTypes from 'prop-types'

const VideoPlayer = ({ className, url, isIframe }) => {
    if (isIframe) {
        return (
            <iframe
                className={className}
                src={url}
                title={url}
                width="640"
                height="360"
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
            />
        )
    }

    return (
        <video className={className} controls autoPlay>
            <source src={url} type="video/mp4" />
        </video>
    )
}

VideoPlayer.propTypes = {
    className: PropTypes.string,
    url: PropTypes.string.isRequired,
    isIframe: PropTypes.bool,
}

VideoPlayer.defaultProps = {
    className: null,
    isIframe: false,
}

export default VideoPlayer
