const id = 7
const name = 'cathy'
const programId = 273

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Yoga by Cathy - Dein Yoga Kurs von Cathy Hummels',
        meta: {
            description:
                'Yoga by Cathy - Dein Yoga Kurs von Cathy Hummels. Mit Yogaübungen Bauch, Beine und Po straffen',
            keywords:
                'cathy hummels, yoga, yoga programm, yoga training, yoga kurs',
            'og:title': 'Yoga by Cathy - Dein Yoga Kurs von Cathy Hummels',
            'og:url': 'https://www.yogabycathy.de/',
            'og:description':
                'Yoga by Cathy - Dein Yoga Kurs von Cathy Hummels. Mit Yogaübungen Bauch, Beine und Po straffen.',
        },
    },

    bodyClass: 'mlp-cathy',

    logo: 'logos/Cathy',

    header: {
        successStories: false,
    },

    topHeader: 'white ',

    footer: {
        workouts: true,
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: {
        align: 'center',
        content: [
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/cathy/lp/usp/usp-1.jpg',
                title: 'Yoga für eine schöne Figur',
                text:
                    'In jedem Yoga Workout erwartet dich ein anderer Fokus: Yoga Shape & Balance strafft und formt Bauch, Beine und Po mit einfachen, aber effektiven Übungen.',
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/cathy/lp/usp/usp-2.jpg',
                title: 'Mehr Ausgeglichenheit',
                text:
                    'Jedes Workout beginnt mit einer kleinen Meditation und endet mit einer beruhigenden Entspannung. Finde deine Balance, lass den Alltag hinter dir und baue Stress ab.',
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/cathy/lp/usp/usp-3.jpg',
                title: 'Ein positives Körpergefühl',
                text:
                    'Während der Übungen nimmst du nicht nur deinen Körper intensiv wahr, sondern konzentrierst dich auch auf deine Atmung. Fühle dich mit jedem Workout wohler in deinem Körper.',
            },
        ],
    },

    why: {
        title:
            'Yoga Workouts für Zuhause, die Bauch, Beine und Po in Bestform bringen - ideal für Yoga Einsteiger!',
        paragraphs: [
            'In 12 Wochen bringt dich Cathy in Bestform. Yoga Shape & Balance ist Yoga für eine schöne Figur. Die Übungen sind so ausgewählt, dass aus typischen Problemzonen echte Hingucker werden - und das mit Yoga! In Cathys Workouts ist jeder willkommen - du brauchst kein Yoga Profi sein.',
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/cathy/lp/testimonial/testimonial_2.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/cathy/lp/testimonial/testimonial-mb_2x.jpg',
        quote:
            'Mein Körper hat sich durch Yoga recht schnell verändert, und ich mochte diese Veränderungen, aber viel wichtiger wurde mir bald die Wirkung, die Yoga auf mein Seelenleben hat.',
        author: 'Cathy Hummels',
    },

    iconGrid: {
        title: 'Diese Ziele wirst du erreichen',
        content: [
            {
                icon: 'Slim',
                title: 'Figur straffen',
                text:
                    'Mit Yoga Shape & Balance bringst du Bauch, Beine und Po in Bestform.',
            },
            {
                icon: 'Strong',
                title: 'Kräftige deine Muskulatur',
                text:
                    'Kräftigende Yoga Übungen sorgen für den Traumbody: Stärkung auf eine sanfte Art.',
            },
            {
                icon: 'Yoga',
                title: 'Mehr Ausgeglichenheit',
                text:
                    'In jedem Workout erwartet dich eine Meditation zu Beginn und eine Entspannung am Ende. Ideal für deine innere Balance.',
            },
            {
                icon: 'Challenge',
                title: 'Herausforderungen meistern',
                text:
                    'Cathy begleitet dich durch jedes Workout und hat immer eine kleine Challenge für dich dabei - stell dich der Herausforderung. ',
            },
        ],
    },
    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        img: `${process.env.PUBLIC_URL}/static/img/cathy/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/cathy/devices_mobile.jpg`,
    },

    packageUsp: {
        title: 'Mein Komplett-Paket für deine Traumfigur',
        text: [
            {
                title: 'Einfach und intensiv',
                text:
                    'Keine komplizierten Übungen. Du trainierst auf deinem Level in deinem Tempo',
            },
            {
                title: 'Wenig Aufwand',
                text:
                    'Nur dreimal pro Woche 25 min Training lässt sich in jeden Alltag integrieren',
            },
            {
                title: 'Trainiere wann und wo du willst',
                text:
                    'Mit der mobilen App oder am Smart TV einfach überall trainieren',
            },
            {
                title: 'Kein Fitnessstudio notwendig',
                text: 'Für das Training genügt eine Matte und etwas Platz',
            },
            {
                title: 'In Top Form wie Cathy',
                text:
                    'Du trainierst für eine schöne Figur und straffst selbst hartnäckige Problemzonen',
            },
        ],
        cta: {
            text: '7 Tage kostenlos trainieren',
        },
    },

    /* eslint-enable max-len */
})
