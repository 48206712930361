import _ from 'lodash'
import * as types from '../actionTypes'
import URLS from '../../config/urls'

const fetchCampaingUrl = '/api/v1/public/campaigns/:active'

export function setLpData(data) {
    return {
        type: types.SET_LP_DATA,
        payload: data,
    }
}

export const getCampaignData = id => async (dispatch, getState) => {
    const data = await window
        .fetch(
            `${URLS.API_BASE_URL}${fetchCampaingUrl}?q=channelId==${id}&sort=region`
        )
        .then(resp => resp.json())
        .catch(() => null)
    const activeCampaing = _.get(data, 'content[0]')
    const { lpData } = getState()

    dispatch({
        type: types.SET_CAMPAIGN_DATA,
        payload: activeCampaing,
        stateOfLpData: lpData,
    })
}
