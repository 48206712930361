import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import data from '../../data-influencer/sot'
import * as lpDataActions from '../../store/actions/lpData'

import {
    Footer,
    HeadTagInjector,
    PoweredBy,
    VoucherBox,
} from '../../components'

import HeaderLogo from '../../components/header/logos/HeaderLogo'

class RedeemVoucherSOT extends Component {
    componentDidMount() {
        const {
            lpDataActions: { setLpData },
        } = this.props
        setLpData(data)
    }

    render() {
        const { cookies, lpData } = this.props

        if (!lpData) {
            return <h3>... loading data</h3>
        }

        return (
            <>
                <HeadTagInjector data={lpData.head} />

                <main
                    className={`${lpData.bodyClass} ${
                        cookies.consent ? '' : 'no-consent'
                    }`}
                >
                    <section className="page redeem-page">
                        <div className="redeem-image">
                            <HeaderLogo data={lpData.influencerLogo} />
                        </div>

                        <div className="redeem">
                            <div className="redeem-container">
                                {/* eslint-disable max-len */}
                                <h2 className="redeem__title summer-loving">
                                    Dein Start in ein neues Leben!
                                </h2>

                                <p className="redeem__text">
                                    Deinen Gutschein kannst du ab sofort für
                                    Sophia Thiel powered by Gymondo einlösen und
                                    somit nicht nur Sophias Programm nutzen,
                                    sondern gleichzeitig über 50 Gymondo
                                    Trainingsprogramme. Viel Spaß!
                                </p>
                                {/* eslint-enable max-len */}
                                <VoucherBox />
                                <PoweredBy />
                            </div>
                        </div>
                    </section>
                </main>

                <Footer />
            </>
        )
    }
}

RedeemVoucherSOT.propTypes = {
    cookies: PropTypes.object,
    lpData: PropTypes.object,
    lpDataActions: PropTypes.object.isRequired,
}

RedeemVoucherSOT.defaultProps = {
    cookies: {},
    lpData: {},
}

function mapStateToProps(state) {
    return {
        cookies: state.cookies,
        lpData: state.lpData,
        links: state.links,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        lpDataActions: bindActionCreators(lpDataActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedeemVoucherSOT)
