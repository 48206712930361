import moment from 'moment-timezone'
import URLS from '../config/urls'

const id = 1
const name = 'sot'
const programId = 260

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Sophia Thiel - Dein 12-Wochen-Fitnessprogramm',
        meta: {
            description:
                'Sophia Thiel hilft dir auf dem Weg zum Traumbody. Du kannst das schaffen - mit effektivem Training, gesunder Ernährung und persönlichen Geheimtipps von Sophia.',
            keywords:
                'sophia thiel, training, personal training, fitness, fitnessprogramm, fitness programm, fitness coach',
            'og:title': 'Sophia Thiel - Dein 12-Wochen-Fitnessprogramm',
            'og:url': 'https://www.sophia-thiel.com/',
            'og:description':
                'Sophia Thiel hilft dir auf dem Weg zum Traumbody. Du kannst das schaffen - mit effektivem Training, gesunder Ernährung und persönlichen Geheimtipps von Sophia.',
        },
        favicon: 'favicon-sot.ico',
    },

    bodyClass: 'mlp-sot',
    voucherPattern: '[a-zA-Z0-9-]+',

    campaignConfig: {
        font: 'summer-loving',
    },

    activeCampaign: {
        desktopTitle: '',
    },

    footer: {
        challenges: true,
        equipment: true,
        customerService:
            '//gymondo.zendesk.com/hc/de/sections/360001915753-Sophia-Thiel',
        press: true,
    },

    topHeader: 'white ',
    influencerLogo: {
        svg: 'logos/LogoSOT',
        url: '/sot',
        className: 'sot',
    },
    loginModal: true,

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },

        login: {
            gymondo: `${URLS.GYM_BASE_URL}/de/checkout/?login=true&channel=${name}&channel_id=${id}`,
            sot: '//classic.sophia-thiel.com/login',
        },
    },

    modals: {
        login: {
            title: "Hier geht's zu deinem Program!",
            body:
                'Sophias Programm gibt es ab dem 01.10.2018 "powered by Gymondo". Damit wir Dich zum richtigen Login weiterleiten können, wähle bitte aus, ob du dich vor dem 01.10.2018 oder nach dem 01.10.2018 erstmalig registriert hast.',
            ctaLeft: 'Vor dem 1.10.2018',
            ctaRight: 'Ab dem 1.10.2018',
        },
    },

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    title: {
        text: 'Schlank & fit in 12 Wochen',
        font: 'summer-loving',
    },

    banner: {
        new: {
            splash: 'Jetzt neu:',
            text:
                'Jederzeit & überall mit der mobilen App und auf deinem Smart TV trainieren',
            font: 'summer-loving',
        },
    },

    usp: [
        {
            id: 0,
            font: 'summer-loving',
            align: 'left',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/usp/usp-0-3.jpg',
                    title: 'Effektives Training',
                    text: [
                        'Mein Erfolgsprogramm zum sofort Mitmachen bei dir zu Hause',
                        'Schnell sichtbare Ergebnisse für Anfänger und Fortgeschrittene durch abwechslungsreiche Übungen & detaillierte Erklärungen',
                        'Extra: Jede Woche die besten Übungen für Deine Bikini-Figur: sexy Beine, einen Knackpo und einen flachen Bauch',
                        'Dauerhafter Erfolg: nach dem 12-Wochenprogramm perfektionieren wir gemeinsam deine Figur mit neuen Übungen und Workouts in meinem Jahresprogramm',
                    ],
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/usp/usp-0-4.jpg',
                    title: 'Gesunde Ernährung',
                    text: [
                        'Mein Ernährungsgeheimnis und über 1000 effektive Fett-weg-Rezepte',
                        'Mit meinem Bodysystem bringst du deinen Körper in Form und lernst dein Gewicht dauerhaft zu halten',
                        'Flexible Ernährung für alle: Ernährungskonzept & Rezepte für Vegetarier und Veganer',
                        'Immer und überall schlank bleiben: Extra-Tipps für Mahlzeiten to go & Kochen für die ganze Familie',
                    ],
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/usp/usp-0-5.jpg',
                    title: '100% Motivation',
                    text: [
                        'Ich zeige dir den Weg, motiviere dich jede Woche Workout für Workout und begleite dich bis an dein Ziel',
                        'Hunderttausende Erfolgsstories: Tausche dich in der exklusiven Facebook-Community mit Gleichgesinnten aus und erhalte regelmäßig Tipps, Tricks & neue Rezepte',
                        'Keine Zwänge: Plane deine Ernährung und dein Training genauso ein, wie es zeitlich in deinen Alltag passt',
                        'Noch schneller ans Ziel mit meinen 4 wöchigen Powerchallenges für einen schönen Bauch und tollen Po',
                    ],
                },
            ],
        },
        {
            id: 1,
            font: 'summer-loving',
            align: 'left',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/usp/usp-1-3.jpg',
                    title: 'Früher',
                    text: [
                        'Ich war schon als Mädchen immer pummelig. Von anderen Kindern wurde ich oft gehänselt, das tat sehr weh. Ich war unsicher, mein fehlendes Selbstbewusstsein machte mir oft zu schaffen.',
                        'In der Pubertät wurde ich zunehmend unglücklicher mit meinem Gewicht. Ob ich je einen Freund haben würde? Ich versuchte es mit zahlreichen Diäten. Doch keine Diät führte mich an mein Ziel, sondern immer nur zu Fressattacken, durch die ich immer wieder zunahm. So konnte es nicht weitergehen, ich musste etwas ändern - und zwar früher als später!',
                    ],
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/usp/usp-1-4.jpg',
                    title: 'Mein Weg',
                    text: [
                        'Im Dezember 2012 habe ich mit dem Fitnesstraining begonnen. Über Monate beschäftigte ich mich intensiv mit Trainingsprinzipien und Ernährung.',
                        'In dieser Zeit habe ich mir ein enormes Wissen angeeignet und mein eigenes, optimales Konzept zusammengestellt. Was neu war: Ich habe auf nichts verzichtet und dennoch abgenommen. Von 80 Kilogramm bin ich runter auf 50*. Trotz Abiturstress konnte ich Training und Essen ganz einfach in meinen Alltag integrieren.',
                    ],
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/usp/usp-1-5.jpg',
                    title: 'Heute',
                    text: [
                        'Wie extrem sich mein Körper verändert hat, kann ich selbst manchmal nicht glauben. Ich habe viel über mich gelernt und weiss heute, dass ich mit Willen, Disziplin & Beharrlichkeit alles erreichen kann.',
                        'Ich bin topfit, selbstbewusst und finde mich richtig sexy und weiblich. Meine Familie und Freunde geben mir Kraft und  inzwischen folgen mir über 3 Millionen Menschen auf Facebook, Instagram und Youtube. Ich bin so dankbar für das tolle Feedback und die Kraft, die ihr mir immer wieder gebt. Und mit dem neuen Lebensgefühl kamen auch neue Ziele: Fitness ist mein Leben und ich möchte vielen jungen Mädchen und Frauen eine Inspiration sein, dass sie genau dies auch können! Und du kannst das auch - ich zeige dir den Weg!',
                    ],
                },
            ],
        },
    ],

    why: {
        title:
            'Mein Komplettsystem für deine Traumfigur: Einfach, Effektiv, Flexibel',
        paragraphs: [
            'Schon Hunderttausende haben mit meinem Erfolgsprogramm ihr Ziel erreicht. Und jetzt bist du dran! Straffe Beine, ein knackiger Po, ein flacher Bauch - das wirst du mit mir und nur 3 x 20min Training pro Woche bei dir zu Hause schaffen. Ich zeige Dir, wie du dauerhaft Fett verbrennst, Muskulatur aufbaust und dranbleibst - ohne Hungern. Los geht’s!',
        ],
    },

    successStories: {
        title: 'Über Hunderttausend Erfolgsstories',

        // no translation needed here for now, since it's just
        // a name and a number
        content: [
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/success/success-0.jpg',
                text: { title: 'Eva', font: 'summer-loving', text: '-7 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/success/success-1.jpg',
                text: {
                    title: 'Vanessa',
                    font: 'summer-loving',
                    text: '-19,5 kg',
                },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/success/success-2.jpg',
                text: {
                    title: 'Selina',
                    font: 'summer-loving',
                    text: '-9,5 kg',
                },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/success/success-3.jpg',
                text: { title: 'Sabine', font: 'summer-loving', text: '-9 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/success/success-4.jpg',
                text: { title: 'Lisa', font: 'summer-loving', text: '-20 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/success/success-5.jpg',
                text: { title: 'Sophie', font: 'summer-loving', text: '-6 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/success/success-6.jpg',
                text: { title: 'Defina', font: 'summer-loving', text: '-5 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/success/success-7.jpg',
                text: {
                    title: 'Larissa',
                    font: 'summer-loving',
                    text: '-6 kg',
                },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/success/success-8.jpg',
                text: { title: 'Nadine', font: 'summer-loving', text: '-6 kg' },
            },
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/testimonial/testimonial.jpg',
        quote: 'Jeder kann es schaffen, jeder hat das Potenzial',
    },

    iconGrid: {
        title: 'Mein Konzept - in 3 Phasen zum Ziel',
        font: 'summer-loving',
        content: [
            {
                icon: 'KickStart',
                text:
                    'Du lernst alle Basics rund um Training und Ernährung kennen und legst den Grundstein für eine gesunde Gewichtsabnahme.',
                title: 'Kick-Start',
            },
            {
                icon: 'BowlIcon',
                text:
                    'Gesunde Rezepte - Rezepte und Inspirationen für Snacks, Lebensmittel und Inhaltsstoffe für einen erholsamen Schlaf.',
                title: 'Reshape',
            },
            {
                icon: 'FeelGood',
                text:
                    'Training und Ernährung werden weiter angepasst, um deinen Körper zu formen und zu straffen.',
                title: 'Feel-Good',
            },
        ],
    },

    benefits: {
        title:
            'Deine Traumfigur, Mehr Power, Wohlbefinden und Selbstbewusstsein - Du schaffst das!',
        content: [
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/goals/goal-0.jpg',
                text: {
                    title: 'Knackiger Po',
                    font: 'summer-loving',
                    text: 'Cellulite adé',
                },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/goals/goal-1.jpg',
                text: {
                    title: 'Definierte Arme',
                    font: 'summer-loving',
                    text: 'sportlich & sexy',
                },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/goals/goal-2.jpg',
                text: {
                    title: 'Flacher Bauch',
                    font: 'summer-loving',
                    text: 'schöne Bauchmuskeln',
                },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/goals/goal-3.jpg',
                text: {
                    title: 'Schöner Rücken',
                    font: 'summer-loving',
                    text: 'weg mit den Pölsterchen',
                },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/goals/goal-4.jpg',
                text: {
                    title: 'Schlanke Beine',
                    font: 'summer-loving',
                    text: 'endlich straffe Oberschenkel',
                },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/goals/goal-5.jpg',
                text: {
                    title: 'Tolle Silhouette',
                    font: 'summer-loving',
                    text: 'anziehen, was du möchtest',
                },
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        newBadge: 'Neu',
        font: 'summer-loving',
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/device/devices-0.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/device/devices-0-mb.jpg',
    },

    packageUsp: {
        title: 'Mein Komplettpaket für deine Traumfigur',
        text: [
            {
                title: 'Einfach und verständlich',
                text:
                    'Schritt für Schritt führe ich mit wöchentlich neuen Videos durchs Programm.',
            },
            {
                title: 'Wenig Aufwand',
                text:
                    'Nur dreimal die Woche 20min Training lässt sich in jeden Alltag integrieren.',
            },
            {
                title: 'Komplettes Ernährungskonzept',
                text:
                    'Mit meinen Schlank- und Durchhaltetipps und einfachen und leckeren Rezepten.',
            },
            {
                title: 'Trainiere wann und wo du willst',
                text:
                    'Mit der mobilen App oder am Smart TV einfach überall trainieren.',
            },
            {
                title: 'Kein Fitnessstudio notwendig',
                text: 'Für das Training zu Hause genügt eine Gymnastikmatte.',
            },
            {
                title: 'Nie wieder Problemzonen',
                text:
                    'Mit meinen Fokus-Trainings sagst du hartnäckigen Pölsterchen den Kampf an.',
            },
        ],
        cta: {
            text: '7 Tage kostenlos trainieren',
        },
    },

    product: {
        deadline: moment.tz([2019, 4, 1], 'Europe/Berlin').startOf('day'),
        title: 'Fit & stark mit Sophia jetzt auch als Buch',
        tracking: {
            category: 'sot_landingpage',
            action: 'click_book_ad',
        },
        content: [
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/product/fit-stark.jpg',
                title: 'Fit & stark mit Sophia',
                link:
                    '//www.amazon.de/Fit-stark-mit-Sophia-Erfolgreich/dp/3898839109',
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/lp/product/meal-prep.jpg',
                title: 'Mit Meal Prep zur Traumfigur',
                link:
                    '//www.amazon.de/Mit-Meal-Prep-zur-Traumfigur/dp/3742307096',
            },
        ],
    },
    /* eslint-enable max-len */
})
