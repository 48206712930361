import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import cn from 'classnames'

import BreakPointsEnum from '../../config/css-breakpoints'

const CampaignBanner = ({ lpData, links }) => {
    const { activeCampaign, campaignConfig } = lpData

    return (
        <a
            className="content-banner"
            href={links.signup}
            title={activeCampaign.desktopTitle}
        >
            <h3 className="content-banner__title">
                <MediaQuery maxWidth={BreakPointsEnum.XS}>
                    {matches => (
                        <>
                            <span
                                className={cn(
                                    'content-banner__splash',
                                    _.get(campaignConfig, 'font')
                                )}
                            >
                                {matches
                                    ? activeCampaign.mobileTitle
                                    : activeCampaign.desktopTitle}
                            </span>

                            {matches
                                ? activeCampaign.mobileText
                                : activeCampaign.desktopText}
                        </>
                    )}
                </MediaQuery>
            </h3>
        </a>
    )
}

CampaignBanner.propTypes = {
    lpData: PropTypes.object.isRequired,
    links: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        lpData: state.lpData,
        links: state.links,
    }
}

export default connect(mapStateToProps, null)(CampaignBanner)
