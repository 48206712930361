const name = 'levelup'
const id = 18
const programId = 339

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Das Level Up für deine Fitness - mit Team Harrison und Gymondo',
        meta: {
            description:
                'Team Harrisons neues Gymondo-Programm ist da: Level Up! Straffe mit Sarah und Dominic in 6 Wochen deinen Körper und erreiche deine Wunschfigur.',
            keywords:
                'bestform, gesund, team harrison, schlank, cardio intervall, training, fettverbrennung, personal training',
            'og:title':
                'Das Level Up für deine Fitness - mit Team Harrison und Gymondo',
            'og:url': 'https://levelup.gymondo.fitness/',
            'og:description':
                'Team Harrisons neues Gymondo-Programm ist da: Level Up! Straffe mit Sarah und Dominic in 6 Wochen deinen Körper und erreiche deine Wunschfigur.',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-harrison2',

    logo: 'logos/Harrison2',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Gratiswoche starten',
        preview:
            'https://akgymondo.akamaized.net/prgpreviews/6BbwQoKbPiRnjto4bcqZIs_v1_de_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/harrison2/usp/usp-1.jpg`,
                    title: 'Stärke deine Muskulatur',
                    text:
                        'Du brauchst nur 20-25 Minuten, um deinen gesamten Körper mit effektiven Workouts und innovativen Übungen zu stärken.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/harrison2/usp/usp-2.jpg`,
                    title: 'Straffe deinen Körper',
                    text:
                        'Dominic und Sarah haben die besten Übungen dabei, um Problemzonen zu straffen und dich mit viel Spaß fit zu machen.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/harrison2/usp/usp-3.jpg`,
                    title: 'Erreiche deine Ziele',
                    text:
                        'Trainiere 3x wöchentlich und erreiche in nur 6 Wochen einen sportlichen Körper, in dem du dich so richtig wohl fühlst.',
                },
            ],
        },
    ],

    why: {
        title: 'Erreiche den perfekten Look mit Sarah und Dominic',
        paragraphs: [
            'Team Harrison ist zurück! Mit ihrem neuen Programm Level Up bringen Sarah und Dominic dein Training auf das nächste Level. Dich erwarten 10 knackige Workouts mit Übungen, die du garantiert noch nie zuvor gesehen hast. Stärke deine Muskulatur und straffe deinen ganzen Körper mit anspruchsvollem Bodyweight-Training. Egal, welches Ziel du verfolgst - Team Harrison macht dich fit.',
        ],
    },

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/harrison2/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/harrison2/testimonial/testimonial-mb.jpg`,
        quote:
            'Du willst deinen Körper straffen und mit viel Spaß deine Wunschfigur erreichen? Starte unser neues 6-Wochen-Programm Level Up und erreiche deine Ziele mit uns!',
        author: 'Team Harrison',
    },

    iconGrid: {
        title: 'Mit Level Up erzielst du langfristige Resultate:',
        content: [
            {
                icon: 'Slim',
                title: 'Straffe deinen Körper',
                text:
                    'Erreiche einen straffen, definierten Körper und fühl dich besser als je zuvor!',
            },
            {
                icon: 'Strong',
                title: 'Steigere deine Kraft',
                text:
                    'Starke, durchtrainierte Muskulatur sorgt für einen echten Stoffwechsel-Boost, verbrennt Kalorien und senkt dein Verletzungsrisiko.',
            },
            {
                icon: 'Yoga',
                title: 'Verbessere deine Ausdauer',
                text:
                    'Effektives Training steigert deine kardiovaskuläre Ausdauer und deine Leistungsfähigkeit, sodass du noch fitter wirst.',
            },
            {
                icon: 'Challenge',
                title: 'Stell dich der Herausforderung!',
                text:
                    'Sarah & Dominic Harrison begleiten dich durch jedes Workout und stellen deine Fitness auf die Probe.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte sind auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV verfügbar.',
        img: `${process.env.PUBLIC_URL}/static/img/harrison2/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/harrison2/device/devices-mb.jpg`,
    },

    packageUsp: {
        title: 'Dein Level Up-Gesamtpaket',
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text:
                    'Level Up by Team Harrison ist genau auf deine persönlichen Bedürfnisse und Vorlieben zugeschnitten.',
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'In nur 20-25 Minuten kommst du mit Gymondo deinen individuellen Zielen einen Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame hochauflösende Workout-Videos direkt per Smartphone, Web oder Smart-TV, sogar offline.',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause oder unterwegs ganz ohne Geräte und Equipment - du brauchst nichts als deine Matte.',
            },
            {
                title: 'Fitness für jedes Level',
                text:
                    'Ob Anfänger oder echte Sportskanone - hier findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 Tage gratis testen',
        },
    },
    /* eslint-enable max-len */
})
