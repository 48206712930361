const name = 'andrea'
const id = 12
const programId = 317

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Love Your Curves by Andrea Espada',
        meta: {
            description:
                'Get ready to boost your metabolism, tone your muscles and “Love Your Curves" with Andrea Espada.',
            keywords:
                'exercise, fitness, HIIT, home workouts, Andrea Espada, Emily Meisner, body weight, tone, glutes, abs, booty, loveyourcurves, fitness program',
            'og:title': 'Love Your Curves by Andrea Espada',
            'og:url': 'https://loveyourcurves.gymondo.fitness/',
            'og:description':
                'Get ready to boost your metabolism, tone your muscles and “Love Your Curves" with Andrea Espada.',
        },
        favicon: 'favicon.ico',
    },

    locale: 'en',

    bodyClass: 'mlp-andrea',

    logo: 'logos/Andrea',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Start your free trial',
        btnLogin: 'Log in',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/317_480.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: 'Start your free trial',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/andrea/lp/usp/usp1.jpg',
                    title: 'Workouts to "Love Your Curves"',
                    text:
                        'Each workout is diverse and targets every muscle in your body with emphasis on your abs and glutes. No equipment is required and there are variations to suit your fitness level. Just have fun and do your best.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/andrea/lp/usp/usp2.jpg',
                    title: 'Get Fit, Feel Happy',
                    text:
                        'Training with Andrea and Emily will not only be effective but will keep you in a good mood as they are so much fun. The sore muscles in your stomach will definitely not only come from the crunches! Have fun with Andrea, who will work out with you, and coach Emily, who will always give you great training tips.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/andrea/lp/usp/usp3.jpg',
                    title: 'Boost positive endorphins',
                    text:
                        "These full-body workouts will allow you to focus on yourself, concentrate on the correct execution, and dedicate time for you. By training consistently with Andrea and Emily, you'll feel incredible and see the results of your dedication.",
                },
            ],
        },
    ],

    why: {
        title: 'Home workouts to make you love your curves!',
        paragraphs: [
            "You are uniquely you and it's time to LOVE YOUR CURVES. Andrea Espada along with her trainer and friend, Emily Meisner, created unique and fun workouts that you will LOVE to do with them for 3 days a week over the next 12 weeks. These 12 weeks will help you build strength using your own body weight, increase your endurance, and shape your curves just like Andrea. Get ready to boost your metabolism, tone your muscles and “Love Your Curves”. Start now!",
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/andrea/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/andrea/lp/testimonial/testimonial-mb.jpg',
        quote:
            'All women have beautiful curves. Every person should love their bodies the way they are. I want to inspire people to feel good about themselves, dedicate time for themselves and love their curves.',
        author: 'Andrea Espada',
    },

    iconGrid: {
        title: 'You will achieve these goals',
        content: [
            {
                icon: 'Slim',
                title: 'Tone up',
                text:
                    "With Andrea's workouts, you'll build toned shapely muscles to feel confident",
            },
            {
                icon: 'Strong',
                title: 'Strengthen your muscles',
                text:
                    'Strong muscles boost metabolism, burns calories, and decreases the risk of injury',
            },
            {
                icon: 'Fit',
                title: 'More endurance',
                text:
                    "Improve cardiovascular fitness - time flies when you're having fun",
            },
            {
                icon: 'Challenge',
                title: 'Master challenges',
                text:
                    'Andrea and Emily accompany you through every workout and always have a small challenge for you - face the challenge.',
            },
        ],
    },

    deviceList: {
        title: 'Unlock your all-access membership to Love Your Curves.',
        subtitle:
            'Get access to Love Your Curves and 20+ additional fitness programs from Yoga to HIIT. Available on Web, iOS, Android, Apple TV, Android TV and Amazon Fire TV.',
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/andrea/lp/device/devices-v2.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/andrea/lp/device/devices-mb-v2.jpg',
    },

    packageUsp: {
        title: 'The complete package for Love Your Curves',
        text: [
            {
                title: 'Fun and effective ',
                text:
                    'Unique workout plan to keep you consistent. You train at your level and at your pace.',
            },
            {
                title: 'Adapted to your lifestyle',
                text:
                    'Only three times a week 20 min training can be integrated into every everyday life.',
            },
            {
                title: 'Train when and where you want',
                text:
                    'With the mobile app or on Smart TV you can train anywhere.',
            },
            {
                title: 'No gym necessary',
                text: 'A mat and some space are sufficient for the training.',
            },
            {
                title: 'Love your curves like Andrea',
                text:
                    'You train for a beautiful figure and feel confident both inside and out',
            },
        ],
        cta: {
            text: 'Start your free trial',
        },
    },
    /* eslint-enable max-len */
})
