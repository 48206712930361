/* eslint react/display-name: 0, react/prop-types: 0 */
import React from 'react'

export default ({ className, onClick }) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 500 500"
            className={className}
            onClick={onClick}
        >
            <path
                d="M462.8,240.1C455.9,121.7,353.8,30.6,235.2,37l-1,0.1C115.8,44,24.8,146.3,31.4,264.9c3.2,57.7,28.7,110.6,71.7,149
            c43.1,38.5,98.7,57.9,156.4,54.6l0.4,0c7.4-0.4,14.7-1.2,22-2.4C389.8,448.6,469.4,351.6,462.8,240.1z M188,362.8V149.9
            l162.1,102.8L188,362.8z"
            />
        </svg>
    )
}
