import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const ImgTextGridItem = ({ className, data }) => {
    const { img, text } = data

    return (
        <div className={cn('itg-element', className)}>
            <img className="itg-element__img" src={img} alt={text.title} />

            <div className="itg-element__content">
                <h4 className={cn('itg-element__title', text.font)}>
                    {text.title}
                </h4>
                <span className="itg-element__text">{text.text}</span>
            </div>
        </div>
    )
}

ImgTextGridItem.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

ImgTextGridItem.defaultProps = {
    className: '',
}

export default ImgTextGridItem
