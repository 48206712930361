const name = 'maishorties'
const id = 327
const programId = 388

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Mai Shorties by Vanessa Mai | Gymondo®',
        meta: {
            description:
                'Erreiche mit Vanessa Mai und den Mai Shorties in 10 Minuten deine Fitnessziele! Teste Gymondo jetzt kostenlos und entdecke die Vielfalt der Fitnesswelt.',
            keywords:
                'Vanessa Mai, Vanessa Mai Fitness, Vanessa Mai Workout, Mini-Workouts, Workouts in 10, Vanessa Mai Gymondo, 10-Minuten-Workout, Kurze Workouts, Schnelle Workouts, Fitness zu Hause',
            'og:title': 'Mai Shorties by Vanessa Mai | Gymondo®',
            'og:url': 'https://vanessa-mai.gymondo.fitness/mai-shorties',
            'og:description':
                'Erreiche mit Vanessa Mai und den Mai Shorties in 10 Minuten deine Fitnessziele! Teste Gymondo jetzt kostenlos und entdecke die Vielfalt der Fitnesswelt.',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-mai-shorties',

    logo: 'logos/MaiShorties',

    topHeader: 'white',

    loginModal: false,

    header: {
        btnGratis: 'GRATISWOCHE STARTEN',
        btnLogin: 'LOGIN',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/421_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 TAGE GRATIS TESTEN',
        },
    },

    why: {
        title: 'Kurz & knackig: Workout-Quickies mit Vanessa Mai',
        paragraphs: [
            'Keine Zeit? Kein Problem! Unsere 10-Minuten-Workouts von und mit Vanessa Mai machen dich in Rekordgeschwindigkeit fit. In 16 Trainingseinheiten erwartet dich schwitziges Cardiotraining vom Allerfeinsten. Im Fokus steht dabei jeweils eine andere Muskelgruppe, die ganz nebenbei effektiv gestärkt und gestrafft wird. Du glaubst, 10 Minuten reichen nicht aus, um sichtbare Ergebnisse zu erzielen? Vanessa Mai und Coach David Flacke beweisen dir das Gegenteil!',
        ],
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/mai-shorties/usp/usp-1.jpg`,
                    title: 'Schnell & schwitzig',
                    text:
                        'Kein Schnickschnack, nur effektives Training: Spare Zeit und erziele echte Ergebnisse in nur 10 Minuten pro Workout.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/mai-shorties/usp/usp-2.jpg`,
                    title: 'Perfekter Einstieg',
                    text:
                        'Trainiere deinen ganzen Körper und werde in nur 4 Wochen Schritt für Schritt fitter, stärker und beweglicher – optimal für Einsteiger.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/mai-shorties/usp/usp-3.jpg`,
                    title: 'Maximale Motivation',
                    text:
                        'Vanessa und Coach David sind ein eingespieltes Team, bei dem der Spaß nie zu kurz kommt. Lass dich motivieren!',
                },
            ],
        },
    ],

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/mai-shorties/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/mai-shorties/testimonial/testimonial-mb.jpg`,
        quote:
            '"Intensität ist alles! Mit den neuen Mai Shorties wirst du in 10 Minuten so richtig fit."',
        author: 'Vanessa Mai',
    },

    iconGrid: {
        title: 'Das erwartet dich in Mai Shorties',
        content: [
            {
                icon: 'Fit',
                title: 'Schwitzen & straffen',
                text:
                    'Vanessa hat mit Coach David die effektivsten Übungen zum Straffen und Abnehmen in 10-minütige Workouts verpackt.',
            },
            {
                icon: 'MidLevel',
                title: 'Für Anfänger und Fortgeschrittene',
                text:
                    'Egal, ob du schon Fitness-Erfahrung hast oder gerade erst durchstartest – Mai Shorties sind perfekt für jedes Level.',
            },
            {
                icon: 'Team',
                title: 'Eingespieltes Team',
                text:
                    'Vanessa und Coach David sind ein unschlagbares Team, das nicht nur für Spaß, sondern auch für Ergebnisse sorgt.',
            },
            {
                icon: 'Heart',
                title: 'Die perfekte Kombination',
                text:
                    'Mai Shorties ist ein echtes Allrounder-Programm, das von Cardio über Krafttraining bis HIIT immer wieder für Abwechslung sorgt.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte sind auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV verfügbar.',
        subtitle: '',
        img: `${process.env.PUBLIC_URL}/static/img/mai-shorties/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/mai-shorties/device/devices-mb.jpg`,
    },

    packageUsp: {
        title: 'Dein Mai Shorties-Gesamtpaket',
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text:
                    'Dein Programm Mai Shorties ist perfekt auf deine individuellen Wünsche und Bedürfnisse zugeschnitten.',
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'Mit Gymondo sparst du Zeit und Geld – und kommst deinen Fitnesszielen zu Hause Schritt für Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame über 1400 hochauflösende Workout-Videos direkt per Smartphone, Web oder Smart-TV – sogar offline.',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause oder unterwegs ganz ohne Geräte und Equipment – du brauchst nichts als deine Matte.',
            },
            {
                title: 'Fitness für jedes Level',
                text:
                    'Ob Anfänger oder echte Sportskanone – bei uns findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 TAGE GRATIS TESTEN',
        },
    },
    /* eslint-enable max-len */
})
