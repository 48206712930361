import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import PreviewItem from './PreviewItem'
/**
 * Shows a line of n workout previews next to each other (n = 3 by default).
 *
 * @param {Object} props
 * @param {Object} props.workoutList Contains the workout preview object from data file
 * @returns {React.Component}
 */

const WorkoutPreview = ({ className, data }) => {
    const { locale, workoutPreview } = data
    const { title, content } = workoutPreview

    return (
        <section className={cn('preview-container', className)}>
            <h2 className="mlp-title">{title}</h2>

            <div className="preview-list">
                {content.map(video => (
                    <PreviewItem
                        video={video}
                        key={video.title}
                        locale={locale}
                    />
                ))}
            </div>
        </section>
    )
}

WorkoutPreview.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

WorkoutPreview.defaultProps = {
    className: 'content',
}

export default WorkoutPreview
