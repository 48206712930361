export default Object.freeze({
    /* eslint-disable max-len */
    head: {
        title: 'Get Strong by Dominic Harrison',
        meta: {
            description:
                'Trainingsvideos für Dich zuhause ✔ 3 x 20 Minuten pro Woche reichen für Deine Traumfigur ✔ Starte jetzt Deine Gratis Woche ✔',
            keywords:
                'bestform, fit, gesund, schlank, abnehmen, cardio, training, personal training, fettverbrennung, fitness, Online-Fitness, fitnessprogramm, fitness programm, fitness coach, bodyweight',
            'og:title': 'Get strong by Dominic Harrison',
            'og:url': 'https://get-strong.gymondo.fitness/',
            'og:description':
                'Trainingsvideos für Dich zuhause ✔ 3 x 20 Minuten pro Woche reichen für Deine Traumfigur ✔ Starte jetzt Deine Gratis Woche ✔',
        },
    },

    bodyClass: 'get-strong',

    logo: 'logos/GetStrong',
    header: {
        successStories: false,
        preview: 'https://akgymondo.akamaized.net/prgpreviews/252_480.mp4',
    },

    topHeader: 'white ',

    footer: {
        workouts: true,
    },

    links: {
        signup: {
            program_id: 252,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: {
        align: 'center',
        content: [
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-strong/lp/usp/usp-1.jpg',
                title: 'Trainiere einfach zu Hause',
                text:
                    'Die Workouts kannst du problemlos in deinen Alltag einbauen. Trainiere wann und wo du willst. Du benötigst keine zusätzlichen Geräte und auch nicht viel Platz, sondern trainierst nur mit deinem eigenen Körpergewicht. Das einzige, was du brauchst ist eine Trainingsmatte und los geht´s!',
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-strong/lp/usp/usp-2.jpg',
                title: 'Ernährungstipps und Rezepte',
                text:
                    'Zu deinem GET STRONG Trainingsprogramm bekommst du bei Gymondo den passenden Ernährungsplan, um dein Ziel noch schneller zu erreichen. Egal ob du Abnehmen oder einfach nur gesund und fit bleiben möchtest, Gymondo unterstützt dich auch in Ernährungsfragen. Als Inspiration warten über 2000 gesunde, richtig leckere Rezepte auf dich.',
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-strong/lp/usp/usp-3.jpg',
                title: 'Workouts für einen Top Body',
                text:
                    'Dich erwarten pro Trainingseinheit 2 Sätze intensives Krafttraining für die Muskulatur von Brust, Schulter, Armen und Rücken. Effektive Übungen für die gerade und schräge Bauchmuskulatur bringen deinen Bauch zum Brennen und abgerundet wird das Training dann durch Cardio Shots. Das sind kurze, harte Ausdauer Parts zur Definition.',
            },
        ],
    },

    why: [
        {
            title:
                'Das Full Body Fitness Programm mit Personaltrainer Dominic Harrison',
            paragraphs: [
                'In Topform wie Dominic Harrison kommst du mit seinen intensiven Cross Training Workouts. Dominic kombiniert Kraftübungen für den Oberkörper und Core mit Cardio Shots für die Definition. Die Mischung aus funktionalem Krafttraining mit dem eigenen Körpergewicht und Ausdauer machen dich stark und fit für den Alltag und jede andere Sportart. Go hit it! Get Strong!',
            ],
        },
        {
            title: 'Was bringt mir GET STRONG by Dominic Harrison?',
            paragraphs: [
                'Du baust Kraft und Muskulatur auf, die du im Alltag und auch für deine Leistung bei anderen Sportarten gebrauchen kannst. Zusätzlich pushen die kurzen, intensiven Cardio Parts deine Fettverbrennung und verbessern deine Kondition. Mehr Kraft! Mehr Power! Mehr Performance! Und das ohne viel Aufwand und ohne Stress mit intensiven Workouts zu Hause. Jedes Training dauert nur 20 Minuten und lässt sich perfekt in jeden Alltag einbauen. Die Übungen sind eine echte Challenge und werden dir einiges abverlangen. Aber durchhalten lohnt sich und kannst dich schon bald über sichtbare Ergebnisse freuen: Ein starker, definierter Oberkörper und stahlharte Bauchmuskeln. Also Hintern hoch! Get up and get strong!',
            ],
        },
    ],

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-strong/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-strong/lp/testimonial/testimonial_mb.jpg',
        quote:
            '"Raus aus der Trainings-Routine! GET STRONG ist perfekt für alle, die sich herausfordern und ihre Fitness auf das nächste Level bringen wollen. Training mit dem eigenen Körpergewicht ist viel effektiver als du denkst und du baust funktionale Kraft auf. Das Ergebnis ist ein starker Oberkörper und definierter Bauch. Get Strong, guys!"',
        author: 'Dominic Harrison',
    },

    iconGrid: {
        title: 'Das bekommst du im GET STRONG Programm:',
        content: [
            {
                icon: 'Strong',
                text:
                    'Krafttraining - Intensives Bodyweight Training mit dem Fokus Oberkörper und Core',
            },
            {
                icon: 'CardioHeart',
                text:
                    'Cardiotraining - kurze Ausdauer Parts aktivieren deinen Stoffwechsel und feuern die Fettverbrennung an',
            },
            {
                icon: 'BowlIcon',
                text:
                    'Rezepte - Über 2000 gesunde Rezepte von Ernährungsexperten entwickelt',
            },
            {
                icon: 'Drops',
                text:
                    'Motivation - Dominic Harrison motiviert dich durchzuhalten, Ausreden haben keine Chance',
            },
        ],
    },

    workoutPreview: {
        title: 'Workoutbeispiele aus GET STRONG by Dominic Harrison',
        content: [
            {
                title: 'Oberkörpertraining',
                url:
                    '//d11y8h2xe0qeoe.cloudfront.net/programs/252-getstrongbydominicharrison/v2/252_preview1.mp4',
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-strong/lp/workouts/preview1.jpg',
            },
            {
                title: 'Training für dein Sixpack',
                url:
                    '//d11y8h2xe0qeoe.cloudfront.net/programs/252-getstrongbydominicharrison/v2/252_preview2.mp4',
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-strong/lp/workouts/preview2.jpg',
            },
            {
                title: 'Cardio zur Definition',
                url:
                    '//d11y8h2xe0qeoe.cloudfront.net/programs/252-getstrongbydominicharrison/v2/252_preview3.mp4',
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-strong/lp/workouts/preview3.jpg',
            },
        ],
    },
    /* eslint-enable max-len */
})
