import PrefixEnum from '../prefixes'

export default Object.freeze({
    'healthy-inside-out.gymondo.fitness': PrefixEnum.HEALTHY_INSIDE_OUT,
    'sophia.gymondo.de': PrefixEnum.HEALTHY_INSIDE_OUT,
    'sophia-thiel.gymondo.de': PrefixEnum.HEALTHY_INSIDE_OUT,
    'www.sophia-thiel.com': PrefixEnum.HEALTHY_INSIDE_OUT,
    'sophia-thiel.com': PrefixEnum.HEALTHY_INSIDE_OUT,
    'www.sophia-thiel.de': PrefixEnum.HEALTHY_INSIDE_OUT,
    'sophia-thiel.de': PrefixEnum.HEALTHY_INSIDE_OUT,
})
