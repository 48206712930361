import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import cn from 'classnames'
import BreakPointsEnum from '../config/css-breakpoints'

const LpQuote = ({ className, data }) => {
    const { img, imgMobile, quote, author } = data

    return (
        <MediaQuery maxWidth={BreakPointsEnum.XS}>
            {matches => (
                <section
                    className={cn('lp-quote', className)}
                    style={{
                        backgroundImage: `url(${
                            matches && imgMobile ? imgMobile : img
                        })`, //
                    }}
                >
                    <div className="content">
                        <div className="lp-quote__wrapper">
                            <div className="lp-quote__message">{quote}</div>
                            {author && (
                                <div className="lp-quote__author">{author}</div>
                            )}
                        </div>
                    </div>
                </section>
            )}
        </MediaQuery>
    )
}

LpQuote.propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
        author: PropTypes.string,
        img: PropTypes.string.isRequired,
        imgMobile: PropTypes.string,
        quote: PropTypes.string.isRequired,
    }),
}

LpQuote.defaultProps = {
    className: '',
    data: PropTypes.shape({
        author: '',
        img: null,
        imgMobile: null,
        quote: '',
    }),
}

export default LpQuote
