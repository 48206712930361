import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import data from '../../data-influencer/sot'
import * as lpDataActions from '../../store/actions/lpData'

import { Footer, HeadTagInjector, PoweredBy } from '../../components'

import HeaderLogo from '../../components/header/logos/HeaderLogo'

class VoucherCustomerSupport extends Component {
    componentDidMount() {
        const {
            lpDataActions: { setLpData },
        } = this.props
        setLpData(data)
    }

    render() {
        const { cookies, lpData } = this.props

        if (!lpData) {
            return <h3>... loading data</h3>
        }

        return (
            <>
                <HeadTagInjector data={lpData.head} />

                <main
                    className={`${lpData.bodyClass} ${
                        cookies.consent ? '' : 'no-consent'
                    }`}
                >
                    <section className="page redeem-page">
                        {/* eslint-disable max-len */}
                        <div
                            className="redeem-image"
                            style={{
                                backgroundImage:
                                    'url(//d11y8h2xe0qeoe.cloudfront.net/static/influencer/sot/voucher-page/header.jpg)', //
                            }}
                        >
                            <HeaderLogo data={lpData.influencerLogo} />
                        </div>

                        <div className="redeem">
                            <div className="redeem-container">
                                <h2 className="redeem__title summer-loving">
                                    Fit und schlank mit Sophia
                                </h2>

                                <p className="redeem__text">
                                    Deinen Gutschein kannst du ab sofort für
                                    Sophia Thiel powered by Gymondo einlösen und
                                    somit nicht nur Sophias Programm nutzen,
                                    sondern gleichzeitig über 50 Gymondo
                                    Trainingsprogramme. Für die Einlösung
                                    kontaktiere einfach unseren Kundensupport
                                    unter Angabe deines Gutscheicodes.
                                </p>

                                <a
                                    className="redeem__link"
                                    href="https://gymondo.zendesk.com/hc/de/sections/360001915753-Sophia-Thiel"
                                    title="Kontaktiere den Kundensupport"
                                >
                                    Kontaktiere den Kundensupport
                                </a>
                                {/* eslint-enable max-len */}
                                <PoweredBy />
                            </div>
                        </div>
                    </section>
                </main>

                <Footer />
            </>
        )
    }
}

VoucherCustomerSupport.propTypes = {
    cookies: PropTypes.object.isRequired,
    lpData: PropTypes.object,
    lpDataActions: PropTypes.object.isRequired,
}

VoucherCustomerSupport.defaultProps = {
    lpData: {},
}

function mapStateToProps(state) {
    return {
        cookies: state.cookies,
        lpData: state.lpData,
        links: state.links,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        lpDataActions: bindActionCreators(lpDataActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoucherCustomerSupport)
