import 'whatwg-fetch'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './utils/polyfills'
import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'

import configureStore from './store/createStore'
import GymondoInfluencer from './GymondoInfluencer'
import { unregister } from './registerServiceWorker'

import './index.css'

console.warn('works...')

ReactDOM.render(
    <Provider store={configureStore()}>
        <GymondoInfluencer />
    </Provider>,
    document.getElementById('root')
)

unregister()
