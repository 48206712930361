import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import BtnRevWithSignupLink from '../cta/BtnRevWithSignupLink'
import PackageUspDescription from './PackageUspDescription'

const PackageUsp = ({ className, data }) => {
    const { title, text, cta } = data

    return (
        <div className="lp-packageusp__bg">
            <section className={cn('lp-packageusp', className)}>
                <h3 className="lp-packageusp__title mlp-title">{title}</h3>

                <div className="lp-packageusp__content">
                    {text.map(textItem => (
                        <PackageUspDescription
                            key={textItem.text}
                            data={textItem}
                        />
                    ))}

                    {cta && (
                        <BtnRevWithSignupLink
                            className="btn-rev--primary"
                            text={cta.text}
                            wrapper={false}
                        />
                    )}
                </div>
            </section>
        </div>
    )
}

PackageUsp.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

PackageUsp.defaultProps = {
    className: '',
}

export default PackageUsp
