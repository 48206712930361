import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

const TemporaryRedirect = ({ endDate, TargetAfterEnd, TargetBeforeEnd }) => {
    const now = moment()
    const end = moment(endDate)

    if (now.isBefore(end)) {
        // end date was not reached yet
        return <TargetBeforeEnd />
    }

    // end date not reached yet
    return <TargetAfterEnd />
}

TemporaryRedirect.propTypes = {
    endDate: PropTypes.instanceOf(Date).isRequired,
    TargetAfterEnd: PropTypes.element.isRequired,
    TargetBeforeEnd: PropTypes.element.isRequired,
}

export default TemporaryRedirect
