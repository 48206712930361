import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CookieConsent from '@gymondo/frontend-core/components/cookie-consent'
import LOCALES from '@gymondo/frontend-core/enums/locales.enum'

import URLS from '../config/urls'
import * as lpDataActions from '../store/actions/lpData'
import ENV from '../config/env'

import { Footer, HeadTagInjector } from '../components'

class PageBuilder extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    componentDidMount() {
        const { lpDataActions: lpActions, influencerData } = this.props
        lpActions.setLpData(influencerData)
    }

    render() {
        const { lpData, links, children } = this.props

        if (!lpData) {
            // @todo put a loading animation
            // might now be needed, tho, because it's a synchronous reading
            // operation (lpData from data file)
            return <></>
        }

        const cookieConsentLocale =
            lpData.locale === 'en' ? LOCALES.EN_US : LOCALES.DE_DE
        const baseUrl = `${URLS.GYM_BASE_URL}/${lpData.locale || 'de'}`
        const cookiePolicyLink = `${baseUrl}/cookie-policy/`
        const privacyPolicyLink = `${baseUrl}/privacy/`
        const iframeUrl = `${URLS.GYM_BASE_URL}/train/cookie-iframe/`

        return (
            <>
                <CookieConsent
                    locale={cookieConsentLocale}
                    GTMId={ENV.GTM_ID}
                    cookiePolicyLink={cookiePolicyLink}
                    privacyPolicyLink={privacyPolicyLink}
                    iframeUrl={iframeUrl}
                />
                <HeadTagInjector data={lpData.head} />
                {children}
                <Footer lpData={lpData} links={links} />
            </>
        )
    }
}

PageBuilder.propTypes = {
    // will be the PageLayout component
    children: PropTypes.node,
    influencerData: PropTypes.object.isRequired,
    // needed for the PageLayout
    lpData: PropTypes.object,
    lpDataActions: PropTypes.object,
    links: PropTypes.object,
}

PageBuilder.defaultProps = {
    children: null,
    lpData: {},
    lpDataActions: {},
    links: {},
}

function mapStateToProps(state) {
    return {
        lpData: state.lpData,
        links: state.links,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        lpDataActions: bindActionCreators(lpDataActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageBuilder)
