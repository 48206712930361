import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

// we are using "dynamic" props list here and we are not
// destructuring the props object in the parameter list
const BtnRev = ({ className, disabled, href, isATag, onClick, text }) => {
    if (isATag) {
        return (
            <a className={cn('btn-rev', className)} href={href} title={text}>
                {text}
            </a>
        )
    }

    return (
        <button
            type="button"
            disabled={disabled}
            className={cn('btn-rev', className)}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

BtnRev.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    isATag: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
}

BtnRev.defaultProps = {
    className: '',
    href: null,
    isATag: false,
    disabled: false,
    onClick: () => {},
}

export default BtnRev
