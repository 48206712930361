import React from 'react'

import influencerData from '../../data-influencer/sot'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const SophiaThiel = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default SophiaThiel
