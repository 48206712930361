import qs from 'qs'

// TODO: fix me - no hardcoded anything

const { hostname } = window.location
const queryParameter = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
})
const isLocalhost = () => hostname === 'localhost'

const DEV_ENV = `//service.${queryParameter.env || 'test'}.gymondo.io`
const STAGE_ENV = `//service.${queryParameter.env || 'stage'}.gymondo.io`
const PROD_ENV = '//www.gymondo.com'
const STAGEDOMAINS = ['influencer.service.stage.gymondo.io']
const TESTDOMAINS = ['influencer.service.test.gymondo.io']

const isTestDomain = () => TESTDOMAINS.indexOf(hostname) > -1
const isStageDomain = () => STAGEDOMAINS.indexOf(hostname) > -1

const getBaseUrl = () => {
    if (isLocalhost() || isTestDomain()) {
        return DEV_ENV
    }

    if (isStageDomain()) {
        return STAGE_ENV
    }

    return PROD_ENV
}

const getApiBaseUrl = () => {
    if (isLocalhost() || isTestDomain()) {
        return DEV_ENV
    }

    return `//${hostname}`
}

export default Object.freeze({
    GYM_BASE_URL: getBaseUrl(),
    GYM_EXTERNAL_LINK_SERVICE: `${getBaseUrl()}/checkout/?`,
    API_BASE_URL: getApiBaseUrl(),
    isTestDomain: isTestDomain(),
})
