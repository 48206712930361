import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import GymondoLogo from '../../images/svg/logos/GymondoLogoBlack'

import getConfig from './footer.config'

const Footer = ({ lpData, links }) => {
    const { footer } = lpData

    const config = getConfig(lpData.locale)

    const getURL = link => {
        if (link.hidden && footer && footer[link.hidden]) {
            return null
        }

        if (link.custom && links[link.custom]) {
            return links[link.custom]
        }

        if (link.dynamic && footer && footer[link.dynamic]) {
            return footer[link.dynamic]
        }

        return link.url
    }

    return (
        <footer className="footer">
            <a href={config.gymondo} title="Gymondo besuchen">
                <GymondoLogo className="footer-logo" />
            </a>

            <section className="footer-lists">
                <div className="footer-lists__group">
                    <ul className="gym-ft-list">
                        <li className="gym-ft-list__title">
                            Copyright ©{new Date().getFullYear()}.{' '}
                            {config.copyright}
                        </li>

                        {config.privacy.links.map(link => (
                            <li className="gym-ft-list__item" key={link.label}>
                                <a href={link.url} title={link.label}>
                                    {link.label}
                                </a>
                            </li>
                        ))}
                    </ul>

                    <div className="social-icons">
                        {config.icons.map(icon => (
                            <a
                                href={icon.url}
                                className="social-icons__link"
                                target="_blank"
                                rel="noopener noreferrer"
                                key={icon.title}
                                title={icon.title}
                            >
                                <icon.svg className="social-icons__icon" />
                            </a>
                        ))}
                    </div>
                </div>

                <div className="footer-lists__group">
                    {config.facts.map(fact => (
                        <ul className="gym-ft-list" key={fact.title}>
                            <li className="gym-ft-list__title">{fact.title}</li>
                            {fact.links.map(link => {
                                const url = getURL(link)

                                return (
                                    url && (
                                        <li
                                            className="gym-ft-list__item"
                                            key={link.label}
                                        >
                                            <a href={url} title={link.label}>
                                                {link.label}
                                            </a>
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    ))}
                </div>
            </section>

            <p className="footer-note">
                <strong>{config.note.strong}</strong>
                {config.note.text}
            </p>
        </footer>
    )
}

Footer.defaultProps = {
    lpData: {},
    links: {},
}

Footer.propTypes = {
    lpData: PropTypes.object,
    links: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        links: state.links,
        lpData: state.lpData,
    }
}

export default connect(mapStateToProps)(Footer)
