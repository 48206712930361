import React from 'react'
import { Redirect } from 'react-router'
import _ from 'lodash'

import hostnameTable from './hostnameTable'
import FallbackRedirect from './FallbackRedirect'

/**
 * This component will check the hostname (location.hostname)
 * and will prefix that path (like "/redeem").
 *
 *  Example: localhost/redeem will be transformed to localhost/sot/redeem
 *              (because localhost is defined as /sot by default)
 */

const DeepLinkController = () => {
    const { hostname, pathname, search } = window.location
    const prefixToSet = hostnameTable[hostname]

    if (!prefixToSet) {
        return <FallbackRedirect />
    }

    // 1st: prevent infinite redirects (eg by url)
    //  /redeem -> /sot/redeem FINE
    //  /redeem -> /sot/sot/redeem NOT FINE

    // pathname = /redeem
    //  if pathname.contains(prefix) -> go to default (based on hostname (prefix))
    // handle 404 pages!

    if (_.includes(pathname, prefixToSet)) {
        return <Redirect to={prefixToSet} />
    }

    return <Redirect to={hostnameTable[hostname] + pathname + search} />
}

export default DeepLinkController
