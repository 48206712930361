import _ from 'lodash'

import * as types from '../actionTypes'
import UrlsEnum from '../../config/urls'

const createSignupLink = (lpData, activeCampaign) => {
    if (!lpData || !lpData.links) {
        return false
    }

    const locale = lpData.locale || 'de'

    // transform {attr1: value, attr2: value} to [['attr1', 'value'], ['attr2', 'value']]
    const linkTupels = _.toPairs(lpData.links.signup)

    // in case there's an active campaign running, we have to apply the voucher
    // to the CTAs, too
    if (_.isEmpty(activeCampaign) === false) {
        linkTupels.push(['voucher', activeCampaign.voucherCode])
    }

    // @todo encodeURIComponent (which implies to decode on the gymondo-web)
    const linkParameters = linkTupels.map(tupel => tupel.join('=')).join('&')

    return `${UrlsEnum.GYM_BASE_URL}/${locale}/checkout/?${linkParameters}`
}

export default function linksReducer(state = null, action) {
    switch (action.type) {
        // when the landing page data is set, we are immediatley generating
        // the links we need for the pages
        case types.SET_LP_DATA:
            return {
                ...(state || {}),
                signup: createSignupLink(action.payload),
            }
        case types.SET_CAMPAIGN_DATA:
            return {
                ...(state || {}),
                signup: createSignupLink(action.stateOfLpData, action.payload),
            }
        default:
            return state
    }
}
