import * as types from '../actionTypes'

const defaultState = {
    mobile: {
        visible: false,
    },
}

export default function menuReducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_MENU_MOBILE:
            return {
                ...state,
                mobile: action.payload,
            }

        default:
            return state
    }
}
