import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'

import BreakPointsEnum from '../../config/css-breakpoints'

import {
    BtnRevWithSignupLink,
    IconGrid,
    LpQuote,
    LpDeviceList,
    LpWhy,
    PackageUsp,
    TopHeader,
    TopSection,
    Usp,
    LpCharity,
} from '../../components'

const PageLayout = ({ lpData, bodyClasses }) => (
    <main className={cn(lpData.bodyClass, bodyClasses)}>
        <TopHeader lpData={lpData} showInfluencerLogo />

        <section className="page">
            <TopSection data={lpData} />

            <LpWhy data={lpData.why} />
            <Usp className="content" uspList={lpData.usp[0]} />

            <LpQuote data={lpData.motivation} />
            <IconGrid className="content" data={lpData.iconGrid} />

            <MediaQuery minWidth={BreakPointsEnum.SM}>
                <BtnRevWithSignupLink
                    className="btn-rev--primary"
                    text={lpData.cta.text.general}
                    wrapper
                />
            </MediaQuery>

            <LpDeviceList className="content" data={lpData.deviceList} />
            <LpCharity className="content" data={lpData} />
            <PackageUsp className="content" data={lpData.packageUsp} />
        </section>
    </main>
)

PageLayout.propTypes = {
    lpData: PropTypes.object.isRequired,
    bodyClasses: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
    return {
        bodyClasses: state.bodyClasses,
        lpData: state.lpData,
    }
}

export default connect(mapStateToProps)(PageLayout)
