const id = 2
const name = 'ivana'
const programId = 265

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Sweat & Dance by Ivana Santacruz',
        meta: {
            description: 'Tanz dich fit mit Ivana Santacruz und ihrer Crew',
            keywords:
                'ivana santacruz, training, personal training, fitness, fitnessprogramm, fitness programm, fitness coach, dance',
            'og:title': 'Sweat & Dance by Ivana Santacruz',
            'og:url': 'https://www.sweat-and-dance.com/',
            'og:description':
                'Tanz dich fit mit Ivana Santacruz und ihrer Crew',
        },
    },

    bodyClass: 'mlp-ivana',

    logo: 'logos/IvanaSantacruz',
    header: {
        preview: 'https://akgymondo.akamaized.net/prgpreviews/265_360.mp4',
    },

    topHeader: 'white ',

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: {
        align: 'center',
        content: [
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ivana/lp/usp/usp-0-3.jpg',
                title: 'Deine Freiheit und Flexibilität',
                text:
                    'Die Dance Workouts kannst du problemlos in deinen Alltag einbauen. Trainiere wann und wo du willst. Du benötigst keine zusätzlichen Geräte und auch nicht viel Platz, sondern trainierst nur mit deinem eigenen Körpergewicht. Egal ob du Einsteiger oder schon Profi bist - jeder kann mitmachen!',
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ivana/lp/usp/usp-0-4.jpg',
                title: 'Spaß und ein positives Körpergefühl',
                text:
                    'Training, das sich nicht wie Training anfühlt, sondern viel mehr wie eine Party - das ist Sweat & Dance. Genieße es deinen Körper sinnlich und sexy zu bewegen und fühl dich einfach wohl dabei. Du wirst sehen: Die Anstrengung rückt in den Hintergrund, du fühlst sie garnicht und die Zeit vergeht wie im Flug.',
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ivana/lp/usp/usp-0-5.jpg',
                title: 'Intervall Training für einen Top Body',
                text:
                    'Dich erwarten in jedem Dance Workout mindestens 2 intensive Cardio Blöcke, die dich außer Atem und ins Schwitzen bringen. Durch die Wechsel von Anstrengung und Erholung wird der Stoffwechsel und die Fettverbrennung angeheizt und der Nachbrenneffekt gefördert. Gezielte Bodyweight Übungen runden das Training ab.',
            },
        ],
    },

    why: [
        {
            title:
                'In Top Form mit dem Dance Fitness Programm von Ivana Santacruz',
            paragraphs: [
                "Ich zeige dir, wie du mit nur 3 x 20min effektivem Training pro Woche bei dir zu Hause und gesunder Ernährung ohne Hungern nicht nur Fett verbrennst, sondern auch schöne, weibliche Muskeln aufbaust und dich fit und wohl in deinem Körper fühlst. Straffe Beine, knackiger Po, flacher Bauch - gemeinsam mit mir erreichst du dein Ziel. Lass' uns loslegen!",
            ],
        },
        {
            title:
                'Warum ist Sweat & Dance by Ivana Santacruz das Richtige für mich?',
            paragraphs: [
                'Tanzen macht nicht nur Spaß, sondern ist gleichzeitig ideal um Fett zu verbrennen. Mit Ivana erreichst du beides: Du baust Schritt für Schritt ein neues, positives Körpergefühl auf, hast Fun neue Moves auszuprobieren und lernst dich cool und sexy zu bewegen. Gleichzeitig kommst du richtig ins Schwitzen und verbrennst massenhaft Kalorien! Die kurzen, intensiven Cardio Dance Parts pushen deine Fettverbrennung und verbessern deine Ausdauer - Intervalltraining pur! Besser geht es nicht! Vergiss eintöniges, langweiliges Fitness Training. Lerne zu tanzen wie Ivana und das ohne viel Aufwand und ohne Stress zu Hause. Jede Dance Einheit dauert nur 20 Minuten und lässt sich perfekt in den Alltag einbauen. Also keine Ausrede - get up and dance!',
            ],
        },
    ],

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ivana/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ivana/lp/testimonial/testimonial-mb.jpg',
        quote:
            '"Musik, Tanzen, Fitness... das sind meine Leidenschaften und in meinem Dance Programm bringe ich das endlich alles zusammen! Und das Beste daran: Jeder kann mitmachen! Ihr werden schwitzen, Spaß haben, Euch auspowern, sexy fühlen und dabei von Woche zu Woche immer fitter werden. Let´s sweat! Let´s dance! Let´s go!"',
        author: 'Ivana Santacruz',
    },

    iconGrid: {
        title: 'Das erwartet dich im Programm:',
        content: [
            {
                icon: 'Dance',
                text:
                    'Tanzen - erlebe die neusten Choreographien und angesagtesten Dance Combos zu Top Musik',
            },
            {
                icon: 'Cardio',
                text:
                    'Kondition - Jedes Dance Workout ist ein intensives Intervall Training und pusht deine Fitness',
            },
            {
                icon: 'Scales',
                text:
                    'Abnehmen - kurze Cardio Parts aktivieren deinen Stoffwechsel und feuern die Fettverbrennung an',
            },
            {
                icon: 'Body',
                text:
                    'Körpergefühl - lerne Schritt für Schritt deinen Körper sexy, cool und sinnlich zu bewegen',
            },
        ],
    },

    workoutPreview: {
        title: 'Workout Previews aus Sweat & Dance by Ivana Santacruz',
        content: [
            {
                title: 'Latin Vibes',
                url: '//akgymondo.akamaized.net/prgwopreviews/265_preview1.mp4',
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ivana/lp/workouts/workout-1.jpg',
            },
            {
                title: 'Hip Hop Vibes',
                url: '//akgymondo.akamaized.net/prgwopreviews/265_preview2.mp4',
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ivana/lp/workouts/workout-2.jpg',
            },
            {
                title: 'Interval Dance',
                url: '//akgymondo.akamaized.net/prgwopreviews/265_preview3.mp4',
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ivana/lp/workouts/workout-3.jpg',
            },
        ],
    },
    /* eslint-enable max-len */
})
