const name = 'shape360'
const id = 247
const programId = 368

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: '360 Shape | Workouts mit Team Harrison | Gymondo®',
        meta: {
            description:
                'Straffe deinen Körper und trainiere mit Sarah & Dominic Harrison in 360 Shape. Exklusive Workouts mit den Harrisons – nur bei Gymondo. Jetzt gratis testen.',
            keywords: 'Team Harrison, Workouts mit Team Harrison, Harrisons ',
            'og:title': '360 Shape | Workouts mit Team Harrison | Gymondo®',
            'og:url': 'https://360shape.gymondo.fitness/harrisons-360-shape',
            'og:description':
                'Straffe deinen Körper und trainiere mit Sarah & Dominic Harrison in 360 Shape. Exklusive Workouts mit den Harrisons – nur bei Gymondo. Jetzt gratis testen.',
        },
        favicon: 'favicon.ico',
    },
    locale: 'de',

    bodyClass: 'mlp-shape-360',

    logo: 'logos/Shape360',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Gratiswoche starten',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/386_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 TAGE KOSTENLOS TRAINIEREN',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/shape-360/usp/usp-1.jpg`,
                    title: 'Straffe deinen Körper',
                    text:
                        'Du brauchst nur 20-25 Minuten pro Trainingseinheit, um deinen gesamten Körper in 10 brandneuen Workouts zu straffen und zu definieren.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/shape-360/usp/usp-2.jpg`,
                    title: 'Trainiere effektiv ohne Geräte',
                    text:
                        'Sarah und Dominic haben sich mal wieder selbst übertroffen und innovative Übungen im Gepäck, für die du keinerlei Geräte oder Hanteln benötigst.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/shape-360/usp/usp-3.jpg`,
                    title: 'Erreiche deine Ziele in 6 Wochen',
                    text:
                        'Du brauchst nur 2-3 Workouts pro Woche, um so richtig fit zu werden und in 6 Wochen deine persönliche Feelgood-Shape zu erreichen.',
                },
            ],
        },
    ],

    why: {
        title: 'Fit von Kopf bis Fuß – mit 360 Shape by Team Harrison',
        paragraphs: [
            'Aller guten Dinge sind ... 5!  Wieso? Weil 360 Shape bereits das fünfte Trainingsprogramm der Harrisons mit Gymondo ist – und das innovativste zugleich. Sarah und Dominic haben sich dieses Mal etwas ganz Besonderes für dich ausgedacht: Mit 360 Shape kannst du nicht nur deinen Körper straffen, deine Muskulatur definieren und deine Wunschfigur erreichen; du bekommst zusätzlich zu 10 brandneuen Workouts auch das volle Harrison-Erlebnis in 8 Kochvideos von und mit Sarah und Dominic höchstpersönlich. Bist du bereit für 6 Wochen volle Harrison-Power?',
        ],
    },

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/shape-360/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/shape-360/testimonial/testimonial-mb.jpg`,
        quote:
            '"Unser neues Programm 360 Shape ist unglaublich gut geworden! Wir freuen uns darauf, dir neue Übungen zu zeigen und mit dir deine Ziele zu erreichen."',
        author: 'Team Harrison',
    },

    iconGrid: {
        title: 'Das erwartet dich in 360 Shape by Team Harrison:',
        content: [
            {
                icon: 'Fit',
                title: '10 brandneue Workouts',
                text:
                    'Sarah und Dominic erwarten dich in 10 knackigen Workouts. Gemeinsam strafft und definiert ihr deinen ganzen Körper in 6 Wochen.',
            },
            {
                icon: 'MidLevel',
                title: 'Mittlere Intensität',
                text:
                    '360 Shape eignet sich perfekt für Fortgeschrittene und Anfänger, die so richtig durchstarten wollen. Mit Team Harrison läufst du zur Höchstform auf.',
            },
            {
                icon: 'BowlIcon',
                title: '8 inspirierende Kochvideos',
                text:
                    'Koche gemeinsam mit Team Harrison in 8 einzigartigen Kochvideos Sarahs und Dominics Lieblingsrezepte nach. Gesunde Ernährung kann so lecker sein!',
            },
            {
                icon: 'Heart',
                title: 'Sarah und Dominic hautnah',
                text:
                    'Ob beim Kochen oder beim Training: Erlebe Sarah und Dominic hautnah und ganz privat. So nah warst du Team Harrison noch nie.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte sind auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV verfügbar.',
        img: `${process.env.PUBLIC_URL}/static/img/shape-360/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/shape-360/device/devices-mb.jpg`,
    },

    packageUsp: {
        title: 'Dein 360 Shape-Gesamtpaket',
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text:
                    '360 Shape by Team Harrison ist genau auf deine persönlichen Bedürfnisse und Vorlieben zugeschnitten.',
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'In nur 20-25 Minuten kommst du mit Gymondo deinen individuellen Zielen einen Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame über 1300 hochauflösende Workout-Videos direkt per Smartphone, Web oder Smart-TV, sogar offline.',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause oder unterwegs ganz ohne Geräte und Equipment – du brauchst nichts als deine Matte.',
            },
            {
                title: 'Fitness für jedes Level',
                text:
                    'Ob Anfänger oder echte Sportskanone – bei uns findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 TAGE GRATIS TESTEN',
        },
    },
    /* eslint-enable max-len */
})
