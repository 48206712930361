import BigRefundRedirect from '../../pages/BIGRefundRedirect'
import DanielAminati from '../../pages/daniel-aminati/DanielAminati'
import RedeemVoucher from '../../pages/daniel-aminati/RedeemVoucher'
import VoucherCustomerSupport from '../../pages/daniel-aminati/VoucherCustomerSupport'

import PrefixEnum from '../prefixes'

export default Object.freeze([
    /* INDEX */
    { path: PrefixEnum.DANIEL_AMINATI, component: DanielAminati },

    /* VOUCHER */
    { path: `${PrefixEnum.DANIEL_AMINATI}/redeem`, component: RedeemVoucher },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/unredeemable`,
        component: VoucherCustomerSupport,
    },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/kochdichkrass`,
        component: VoucherCustomerSupport,
    },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/de/kochdichkrass`,
        component: VoucherCustomerSupport,
    },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/cookbook`,
        component: VoucherCustomerSupport,
    },
    { path: `${PrefixEnum.DANIEL_AMINATI}/amazon`, component: RedeemVoucher },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/gutschein`,
        component: RedeemVoucher,
    },
    { path: `${PrefixEnum.DANIEL_AMINATI}/express`, component: RedeemVoucher },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/traumbody`,
        component: RedeemVoucher,
    },

    /* MARKETING LANDINPAGE */
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/information`,
        component: DanielAminati,
    },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/de/information`,
        component: DanielAminati,
    },
    { path: `${PrefixEnum.DANIEL_AMINATI}/shake`, component: DanielAminati },

    /* SELECT PRODUCT PAGES */
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/de/buy/select-product/*`,
        component: DanielAminati,
    },
    { path: `${PrefixEnum.DANIEL_AMINATI}/de/buy/*`, component: DanielAminati },

    /* BIG INSURANCE */
    { path: `${PrefixEnum.DANIEL_AMINATI}/big`, component: DanielAminati },
    { path: `${PrefixEnum.DANIEL_AMINATI}/de/big`, component: DanielAminati },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/de/home/big/refund`,
        component: BigRefundRedirect,
    },

    /* CHANGING PAYMENT METHOD PAGE */
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/de/home/payment/change`,
        component: () => {
            window.location =
                '//classic.machdichkrass.de/de/home/payment/change'
        },
    },

    /* OBSOLETE URLS */
    { path: `${PrefixEnum.DANIEL_AMINATI}/aktion`, component: DanielAminati },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/de/aktion`,
        component: DanielAminati,
    },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/split/mobile`,
        component: DanielAminati,
    },
    {
        path: `${PrefixEnum.DANIEL_AMINATI}/de/split/mobile`,
        component: DanielAminati,
    },
    { path: `${PrefixEnum.DANIEL_AMINATI}/acer`, component: DanielAminati },
])
