const name = 'healthyinsideout'
const id = 22
const programId = 355

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Healthy Inside Out by Sophia Thiel | Gymondo®   ',
        meta: {
            description:
                'Trainiere gemeinsam mit Sophia Thiel und erreiche mit Healthy Inside Out in 12 Wochen deinen persönlichen Feelgood-Body. Teste Gymondo jetzt 7 Tage kostenlos!',
            keywords:
                'Sophia Thiel, Sophia Thiel workouts, Healthy Inside Out by Sophia Thiel, Gymondo',
            'og:title': 'Healthy Inside Out by Sophia Thiel | Gymondo®',
            'og:url': 'https://sophia-thiel.com/healthyinsideout',
            'og:description':
                'Trainiere gemeinsam mit Sophia Thiel und erreiche mit Healthy Inside Out in 12 Wochen deinen persönlichen Feelgood-Body. Teste Gymondo jetzt 7 Tage kostenlos!',
        },
        favicon: 'favicon.ico',
    },
    bodyClass: 'mlp-healthyinsideout',

    logo: 'logos/HelthyInsideOut',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Gratiswoche starten',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/370_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/healthyinsideout/usp/usp-1.jpg`,
                    title: 'Effektives Training',
                    text:
                        'Sophia weiß genau, wie du schnell funktionelle Muskulatur aufbaust und deinen ganzen Körper stärkst. Mit ihrem 3er-Split und 13 brandneuen Workouts bringst du dein Training auf die Überholspur.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/healthyinsideout/usp/usp-2.jpg`,
                    title: 'Positives Mindset',
                    text:
                        'In 12 Motivationsvideos gibt dir Sophia hilfreiche Tipps zu Fitness, Wellness und Lifestyle. Erfahre alles, was du für einen gesunden Körper und eine positive Lebenseinstellung wissen musst.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/healthyinsideout/usp/usp-3.jpg`,
                    title: 'Leckere, gesunde Rezepte',
                    text:
                        'Gesunde Ernährung sollte lecker und alltagstauglich sein. Mit über 30 schmackhaften neuen Rezepten und tollen Kochvideos zeigt dir Sophia, wie locker-leicht gesunde Ernährung sein kann.',
                },
            ],
        },
    ],

    why: {
        title:
            'Stärke deinen Körper und trainiere dein Mindset mit Sophia Thiel',
        paragraphs: [
            "Sophia Thiel ist endlich zurück – und feiert ihr Comeback gemeinsam mit Gymondo! Sophias neues Programm Healthy Inside Out ist viel mehr als nur ein Trainingsprogramm zum Muskelaufbau. In den nächsten 12 Wochen erwartet dich Sophia mit einem Rundum-sorglos-Paket für mehr Wohlbefinden, Energie und Motivation. Schnapp dir deine Kurzhanteln und trainiere gemeinsam mit Sophia im effektiven 3er-Split! Du hast keine Kurzhanteln parat? Kein Problem. Sophia zeigt dir in jedem Workout 3 verschiedene Übungsvarianten, die du mit Gewichten, Widerstandsbändern oder einfachen Haushaltsgegenständen wie Wasserflaschen ausüben kannst. Dazu gibt's völlig neue Recovery-Workouts, die dein Training optimal ergänzen. Und das war noch längst nicht alles!",
        ],
    },

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/healthyinsideout/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/healthyinsideout/testimonial/testimonial-mb.jpg`,
        quote:
            'Es stehen 3 Dinge im Vordergrund: Spaß, Nachhaltigkeit und die richtige Balance. Ich wünsche mir für dich, dass du eine gesunde Beziehung zu dir, deinem Körper, zu Essen und Sport aufbaust und langfristig deine Ziele erreichst.',
        author: 'Sophia Thiel',
    },

    iconGrid: {
        title: 'Mit Healthy Inside Out erzielst du langfristige Resultate:',
        content: [
            {
                icon: 'Strong',
                title: 'Stärke deinen Körper',
                text:
                    'Steigere deine Muskelkraft im 3er-Split und trainiere deinen Körper mit 9 knackigen Muskelaufbau-Workouts und 4 tollen Recovery-Workouts, die dein Training perfekt unterstützen.',
            },
            {
                icon: 'Plant',
                title: 'Trainiere dein Mindset',
                text:
                    'Dein Mindset ist das, was dich antreibt. Erreiche all deine Ziele, indem du dein Mindset trainierst und positive Routinen etablierst.',
            },
            {
                icon: 'FoodType',
                title: 'Entdecke leckere Rezepte',
                text:
                    'Sophia zeigt dir mit 30 schmackhaften neuen Rezepten und tollen Kochvideos, wie einfach gesunde Ernährung sein kann.',
            },
            {
                icon: 'Heart',
                title: 'Healthy Inside Out!',
                text:
                    'Bist du bereit für dein Rundum-sorglos-Paket für mehr Wohlbefinden, Energie und Motivation? Sophia freut sich schon auf dich!',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte sind auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV verfügbar.',
        img: `${process.env.PUBLIC_URL}/static/img/healthyinsideout/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/healthyinsideout/device/devices-mb.jpg`,
    },

    packageUsp: {
        title: 'Dein Healthy Inside Out-Gesamtpaket',
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text:
                    'Healthy Inside Out by Sophia Thiel ist genau auf deine persönlichen Bedürfnisse und Vorlieben zugeschnitten.',
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'In nur 20-25 Minuten kommst du mit Gymondo deinen individuellen Zielen einen Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame hochauflösende Workout-Videos direkt per Smartphone, Web oder Smart-TV, sogar offline.',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause oder unterwegs – du brauchst nichts als deine Matte und ein paar Kurzhanteln.',
            },
            {
                title: 'Fitness für jedes Level',
                text:
                    'Ob Anfänger oder echte Sportskanone – hier findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 TAGE GRATIS TESTEN',
        },
    },
    /* eslint-enable max-len */
})
