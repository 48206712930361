import React, { Component } from 'react'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import PropTypes from 'prop-types'
import cn from 'classnames'

import BtnPay from '../cta/BtnPay'

import BreakPointsEnum from '../../config/css-breakpoints'
import KEYCODES from '../../config/keycodes'
import URLS from '../../config/urls'

class VoucherBox extends Component {
    constructor(props) {
        super(props)

        this.state = {
            voucher: '',
        }

        this.forwardToSignup = this.forwardToSignup.bind(this)
        this.generateRedeemLink = this.generateRedeemLink.bind(this)
        this.handleKeyEvent = this.handleKeyEvent.bind(this)
        this.setVoucherCode = this.setVoucherCode.bind(this)
    }

    handleKeyEvent(event) {
        const keyCode = event.keyCode || event.which

        if (keyCode === KEYCODES.ENTER) {
            this.forwardToSignup()
        }
    }

    setVoucherCode(event) {
        const { value } = event.target

        if (event.target.checkValidity()) {
            this.setState({ voucher: value })
        } else {
            event.target.value = value.substring(0, value.length - 1)
        }
    }

    generateRedeemLink() {
        const { lpData } = this.props
        const { voucher } = this.state

        const influencerData =
            (lpData && lpData.links && lpData.links.signup) || {}
        const { channel } = influencerData
        const channelId = influencerData.channel_id

        if (!voucher || !channel || !channelId) {
            return ''
        }

        return `${URLS.GYM_BASE_URL}/de/checkout/?channel=${channel}&channel_id=${channelId}&voucher=${this.state.voucher}` /* eslint-disable-line */
    }

    forwardToSignup() {
        const { voucher } = this.state
        if (!voucher) {
            return
        }

        window.location = this.generateRedeemLink()
    }

    render() {
        const { voucher } = this.state
        const { lpData } = this.props

        return (
            <div className="voucher-box">
                <label // eslint-disable-line
                    htmlFor="voucher-code"
                    className="label voucher-box__label"
                >
                    Gutschein
                </label>

                <div className="input-container">
                    <input
                        id="voucher-code"
                        className="input input--primary voucher-code__input"
                        type="text"
                        placeholder="Gutschein eingeben"
                        pattern={lpData.voucherPattern}
                        onChange={this.setVoucherCode}
                        onKeyUp={this.handleKeyEvent}
                        autoComplete="off"
                    />

                    <MediaQuery maxWidth={BreakPointsEnum.SM}>
                        {matches => (
                            <BtnPay
                                className={cn(
                                    'btn-pay--tertiary',
                                    { 'btn--disabled': !voucher },
                                    { 'btn--fluid': matches }
                                )}
                                onClick={this.forwardToSignup}
                                disabled={!voucher}
                                text="Einlösen"
                            />
                        )}
                    </MediaQuery>
                </div>
            </div>
        )
    }
}

VoucherBox.propTypes = {
    lpData: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        lpData: state.lpData,
    }
}

export default connect(mapStateToProps)(VoucherBox)
