import React from 'react'

import influencerData from '../../data-influencer/lisa'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const Lisa = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default Lisa
