import _ from 'lodash'
import * as types from '../actionTypes'
import ClassListEnum from '../../config/body-classes'

const removeClassFromState = (state, classNameToRemove) =>
    // className !== classNameToRemove because the return value of
    // !== operation of _.remove is the array without the removed element,
    // the === operation would result in an array with the removed element only
    _.remove(state, className => className !== classNameToRemove)

export default function bodyClassReducer(state = [], action) {
    switch (action.type) {
        case types.SET_COOKIE_CONSENT:
            // when the cookie consent was given, we remove the class from the body class list
            if (action.payload === true) {
                return Object.assign(
                    [],
                    removeClassFromState(state, ClassListEnum.COOKIE_CONSENT)
                )
            }

            // otherwise, (default state), we add the cookie consent class
            return Object.assign([], state, [
                ...state,
                ClassListEnum.COOKIE_CONSENT,
            ])

        case types.SET_CAMPAIGN_DATA:
            // when a campaign is active, or a content banner is displayed,
            // add a body class accordingly
            const { payload, stateOfLpData } = action // eslint-disable-line no-case-declarations
            // eslint-disable-next-line no-case-declarations
            const activeCampaign = payload

            if (
                !_.isEmpty(activeCampaign) ||
                !_.isEmpty(stateOfLpData.banner)
            ) {
                return Object.assign([], state, [
                    ...state,
                    ClassListEnum.ACTIVE_CONTENT_BANNER,
                ])
            }

            return Object.assign(
                [],
                removeClassFromState(state, ClassListEnum.ACTIVE_CONTENT_BANNER)
            )
        default:
            return state
    }
}
