import _ from 'lodash'

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const ProductItem = ({ className, data, tracking }) => {
    const { img, title, link } = data
    const { category, action } = tracking
    const button = data.button || 'Jetzt ansehen'

    // track the title of the product clicked
    const trackProductView = () => {
        const dataLayer = window.dataLayer || []

        if (!_.isEmpty(tracking)) {
            dataLayer.push({
                event: 'event_tracking',
                eventCategory: category,
                eventAction: action,
                eventLabel: title,
            })
        }
    }

    return (
        <div className={cn('product-item', className)}>
            <a
                href={link}
                className="product-item__link"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img className="product-item__img" src={img} alt={title} />
            </a>

            <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-rev btn-rev--secondary product-item__button"
                onClick={trackProductView}
            >
                {button}
            </a>
        </div>
    )
}

ProductItem.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
    tracking: PropTypes.object,
}

ProductItem.defaultProps = {
    className: '',
    tracking: {},
}

export default ProductItem
