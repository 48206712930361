export default Object.freeze({
    /* eslint-disable max-len */
    head: {
        title: 'Get In Shape by Team Harrison',
        meta: {
            description:
                'Trainingsvideos für Dich zuhause ✔ 3 x 20 Minuten pro Woche reichen für Deine Traumfigur ✔ Starte jetzt Deine Gratis Woche ✔',
            keywords:
                'bestform, fit, gesund, schlank, abnehmen, cardio, training, personal training, fettverbrennung, fitness, Online-Fitness, fitnessprogramm, fitness programm, fitness coach, bodyweight',
            'og:title': 'Get in Shape by Team Harrison',
            'og:url': 'https://get-in-shape.gymondo.fitness/',
            'og:description':
                'Trainingsvideos für Dich zuhause ✔ 3 x 20 Minuten pro Woche reichen für Deine Traumfigur ✔ Starte jetzt Deine Gratis Woche ✔',
        },
    },

    bodyClass: 'get-in-shape',

    logo: 'logos/GetInShape',
    header: {
        successStories: false,
        preview: 'https://akgymondo.akamaized.net/prgpreviews/253_480.mp4',
    },

    topHeader: 'white ',

    footer: {
        workouts: true,
    },

    links: {
        signup: {
            program_id: 253,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: {
        align: 'center',
        content: [
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-in-shape/lp/usp/usp-1.jpg',
                title: 'Trainiere einfach zu Hause',
                text:
                    'Die Workouts kannst du problemlos in deinen Alltag einbauen. Trainiere wann und wo du willst. Du benötigst keine zusätzlichen Geräte und auch nicht viel Platz. Das einzige, was du brauchst ist eine Trainingsmatte und los geht´s! Komm in Bewegung und trainiere, damit aus den typischen, weiblichen Problemzonen nach und nach Wohlfühlzonen werden. Was Sarah schafft, schaffst du auch. Es gibt keine Ausreden mehr!',
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-in-shape/lp/usp/usp-2.jpg',
                title: 'Ernährungstipps und Rezepte',
                text:
                    'Passend zu deinem GET IN SHAPE Trainingsprogramm bekommst du bei Gymondo den passenden Ernährungsplan, um dein Ziel noch schneller zu erreichen. Egal ob du Abnehmen oder einfach nur gesund und fit bleiben möchtest, Gymondo unterstützt dich auch in Ernährungsfragen. Als Inspiration warten über 2000 gesunde, richtig leckere Rezepte auf dich.',
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-in-shape/lp/usp/usp-3.jpg',
                title: 'Workouts perfekt für dein Ziel',
                text:
                    'Deine Workouts sind vollgepackt mit einfachen, aber effektiven Übungen, die den Körper komplett straffen und die Muskulatur festigen. Dich erwarten pro Trainingseinheit 2 Zirkel mit Übungen für straffe Beine, einen Knackpo, einen flachen Bauch und eine schmale Taille. Extra Bauch-, und Rückentraining sorgt für sexy Kurven und eine aufrechte Haltung.',
            },
        ],
    },

    why: [
        {
            title: 'Das Fitness Programm für eine Top Figur mit Team Harrison',
            paragraphs: [
                'Trainiere exklusiv mit Team Harrisons! Fit werden und effektiv straffen mit den besten Einsteiger-Workouts für die Figur. Personaltrainer Dominic Harrison coacht und motiviert dich durch einfache, aber wirkungsvolle Figur Workouts. Zusammen mit Sarah steigst du auch nach längerer Pause sanft und sicher ins Training ein, spürst schnell den Toning-Effekt und hast am Ende jede Menge Spaß bei den Übungen für Pärchen. Starte jetzt!',
            ],
        },
        {
            title: 'Was bringt mir GET IN SHAPE by Team Harrison?',
            paragraphs: [
                'Du kommst in Top Form wie Sarah Harrison, und das ohne viel Aufwand und ohne Stress mit einfachen Workouts zu Hause. Jedes Training dauert nur 20 Minuten und lässt sich perfekt in jeden Alltag einbauen. Die Übungen sind so gewählt, dass der Einstieg auch nach längerer Sportpause mühelos gelingt. Du fühlst die straffende Wirkung schon direkt beim Mitmachen und kannst dich nach und nach über sichtbare Ergebnisse freuen: Ein flacher Bauch, eine schmale Taille, straffe Beine und Oberarme und eine aufrechte Haltung. Get in Shape!',
            ],
        },
    ],

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-in-shape/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-in-shape/lp/testimonial/testimonial_mb.jpg',
        quote:
            '"Dominic hat mich nach der Schwangerschaft wieder fit gemacht und das kann er mit dir auch! Training muss keine Quälerei und auch nicht aufwendig sein. Wichtig sind einfach die richtigen Übungen und dann lassen die Ergebnisse nicht lange auf sich warten. Sixpack und Muskelpakete müssen nicht sein, aber ein straffer, fitter Körper schon. Man fühlt sich einfach wohl."',
        author: 'Sarah Harrison',
    },

    iconGrid: {
        title: 'Das bekommst du im GET IN SHAPE Programm:',
        content: [
            {
                icon: 'Shape',
                text:
                    'Workouts - Top Figurtraining zur Straffung für Einsteiger geeignet',
            },
            {
                icon: 'BowlIcon',
                text:
                    'Rezepte - Über 2000 gesunde Rezepte von Ernährungsexperten entwickelt',
            },
            {
                icon: 'Partner',
                text:
                    'Partnerübungen - Spaß mit Couple Exercises, die du mit und ohne Partner machen kannst',
            },
            {
                icon: 'Drops',
                text:
                    'Motivation - Team Harrison motiviert dich durchzuhalten, Ausreden haben keine Chance',
            },
        ],
    },

    workoutPreview: {
        title: 'Workoutbeispiele aus GET IN SHAPE by Team Harrison',
        content: [
            {
                title: 'Ganzkörper Training',
                url:
                    '//d11y8h2xe0qeoe.cloudfront.net/programs/253-getinshapebyteamharrison/v2/253_preview1.mp4',
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-in-shape/lp/workouts/preview1.jpg',
            },
            {
                title: 'Bauch Beine Po Training',
                url:
                    '//d11y8h2xe0qeoe.cloudfront.net/programs/253-getinshapebyteamharrison/v2/253_preview2.mp4',
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-in-shape/lp/workouts/preview2.jpg',
            },
            {
                title: 'Training für einen flachen Bauch',
                url:
                    '//d11y8h2xe0qeoe.cloudfront.net/programs/253-getinshapebyteamharrison/v2/253_preview3.mp4',
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/get-in-shape/lp/workouts/preview3.jpg',
            },
        ],
    },
    /* eslint-enable max-len */
})
