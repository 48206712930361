/**
 * @todo make this a little bit more sophisticated at some point maybe.
 *  With a spinner or whatsoever :-)
 */

import RedirectUrlsEnum from '../config/urls-redirect'

const FallbackRedirect = () => {
    window.location = RedirectUrlsEnum.FALLBACK
}

export default FallbackRedirect
