import React from 'react'
import Loadable from 'react-loadable'
import PropTypes from 'prop-types'

import DynamicCompLoader from './DynamicCompLoader'

// use this component for loading SVG icons dynamically by name (string)
const DynamicSvgIcon = ({ className, name, onClick }) => {
    // the component must return a <Component />,
    // not a function that returns a Component!
    const Icon = Loadable({
        loader: () => import(`../images/svg/${name}`),
        loading: DynamicCompLoader,
    })

    return <Icon className={className} onClick={onClick} />
}

DynamicSvgIcon.defaultProps = {
    className: '',
    onClick: () => {},
}

DynamicSvgIcon.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
}

export default DynamicSvgIcon
