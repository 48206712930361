import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import IconGridItem from './IconGridItem'

const IconGrid = ({ className, data }) => {
    const { content } = data

    return (
        <section className={cn('lp-ig-container', className)}>
            <h3 className="lp-ig-container__title mlp-title">{data.title}</h3>

            <div className="lp-ig-list">
                {content.map(gridElement => (
                    <IconGridItem
                        key={gridElement.icon}
                        font={data.font}
                        data={gridElement}
                    />
                ))}
            </div>
        </section>
    )
}

IconGrid.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

IconGrid.defaultProps = {
    className: '',
}

export default IconGrid
