import React from 'react'

export default ({ className, onClick }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={className}
            onClick={onClick}
        >
            <path
                fillRule="nonzero"
                d="M21.765.037H1.275C.57.037.005.608.005 1.307v20.49c0 .704.571 1.27 1.27 1.27h11.029v-8.912H9.301v-3.478h3.003V8.112c0-2.976 1.819-4.597 4.47-4.597 1.269 0 2.362.096 2.682.138v3.11h-1.84c-1.445 0-1.723.688-1.723 1.69v2.219h3.44l-.448 3.477h-2.992v8.918h5.872c.704 0 1.27-.571 1.27-1.27V1.312a1.27 1.27 0 0 0-1.27-1.275z"
            />
        </svg>
    )
}
