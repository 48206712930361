import * as types from '../actionTypes'

const defaultState = {
    consent: true,
}

export default function cookiesReducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_COOKIE_CONSENT:
            return {
                ...state,
                consent: action.payload,
            }

        default:
            return state
    }
}
