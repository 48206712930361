import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const LpMotivationalQuote = ({ className, data }) => {
    const { img, quote } = data

    return (
        <section className={cn('lp-motivation', className)}>
            <div className="lp-motivation__img-wrapper">
                <img src={img} alt={quote} />
            </div>

            <div className="lp-motivation__quote-wrapper">
                <span className="lp-motivation__quote summer-loving">
                    {quote}
                </span>
            </div>
        </section>
    )
}

LpMotivationalQuote.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

LpMotivationalQuote.defaultProps = {
    className: '',
}

export default LpMotivationalQuote
