import _ from 'lodash'
import qs from 'qs'

import AdrienneHosts from './hostnames/adrienne'
import CathyHummels from './hostnames/cathy'
import DanielAminatiHosts from './hostnames/daniel-aminati'
import DanielAminatiMTCHosts from './hostnames/daniel-aminati-mtc'
import IvanaSantacruzHosts from './hostnames/ivana-santacruz'
import SophiaThielHosts from './hostnames/sot'
import GetStrongHosts from './hostnames/get-strong'
import GetInShapeHosts from './hostnames/get-in-shape'
import PaolaHosts from './hostnames/paola'
import HarrisonHosts from './hostnames/harrison'
import Harrison2Hosts from './hostnames/harrison2'
import LisaHosts from './hostnames/lisa'
import UrsulaHosts from './hostnames/ursula'
import AndreaHosts from './hostnames/andrea'
import LabrantHosts from './hostnames/labrant'
import VanessaHosts from './hostnames/vanessa'
import Lisa2Hosts from './hostnames/lisa2'
import MaiShape from './hostnames/mai-shape'
import HealthyInsideOut from './hostnames/healthyinsideout'
import TotalBodyHosts from './hostnames/total-body'
import Shape360 from './hostnames/shape-360'
import PureFlow from './hostnames/pure-flow'
import DanceWorks from './hostnames/dance-works'
import StrongMindBody from './hostnames/strong-mind-body'
import MaiShorties from './hostnames/mai-shorties'
import Power from './hostnames/power'

import PrefixEnum from './prefixes'

// in case a prefix for a influencer is set explicitely (for testing for example),
// we will use that prefix as a default prefix
const queryParameter = qs.parse(window.location.search)

const defaultPrefix = `${
    _.has(queryParameter, 'prefix')
        ? `${PrefixEnum[queryParameter.prefix]}`
        : '/mdk'
}`

const DEFAULTS = {
    localhost: defaultPrefix,
    'influencer.service.test.gymondo.io': defaultPrefix,
    'influencer.service.stage.gymondo.io': defaultPrefix,
    'stage.influencer.gymondo.de': defaultPrefix,
}

export default Object.freeze(
    _.merge(
        DEFAULTS,
        AdrienneHosts,
        CathyHummels,
        DanielAminatiHosts,
        DanielAminatiMTCHosts,
        IvanaSantacruzHosts,
        SophiaThielHosts,
        GetStrongHosts,
        GetInShapeHosts,
        PaolaHosts,
        HarrisonHosts,
        Harrison2Hosts,
        LisaHosts,
        UrsulaHosts,
        AndreaHosts,
        LabrantHosts,
        VanessaHosts,
        Lisa2Hosts,
        MaiShape,
        HealthyInsideOut,
        TotalBodyHosts,
        Shape360,
        PureFlow,
        DanceWorks,
        StrongMindBody,
        MaiShorties,
        Power
    )
)
