import URLS from '../../config/urls'

const BASE_URL = URLS.GYM_BASE_URL
const GYMONDO_DE = `${BASE_URL}/de/`
const GYMONDO_EN = `${BASE_URL}/en/`
const instructionsLink = 'instructions/'

const config = {
    de: {
        home: 'Home',
        signup: 'Preis',
        instructions: {
            label: "So geht's",
            url: `${GYMONDO_DE}${instructionsLink}`,
        },
        button: 'Gratis Woche starten',
    },
    en: {
        home: 'Home',
        signup: 'Price',
        instructions: {
            label: 'How it works',
            url: `${GYMONDO_EN}${instructionsLink}`,
        },
        button: 'Try 7 days free',
    },
}

const getConfig = locale => config[locale] || config.de

export default getConfig
