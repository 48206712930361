import React from 'react'
import PropTypes from 'prop-types'

// TODO: wait a sec, if JavaScript is disabled in the
// browser, how will this component work then in the first place? :-\

/**
 * This component will insert a noscript tag into the application. It can handle the
 * given elements (like GTM)
 *
 * @param {Object} obj
 * @param {String} obj.type Specifies what type of tracking will be used (see mapping)
 * @return {React.Component} Returns the noscript tag
 */
const NoScriptInjector = ({ type }) => {
    const iFrameStyle = { display: 'none', visibility: 'hidden' } //
    const noScriptMap = {
        // the id for the Google Tag Manager is always the same, no matter what influencer
        gtm: (
            <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-WX2BGL"
                height="0"
                width="0"
                title="gtmnoscript"
                style={iFrameStyle}
            />
        ),
    }

    return (
        <noscript>
            {Object.prototype.hasOwnProperty.call(noScriptMap, type) &&
                noScriptMap[type]}
        </noscript>
    )
}

NoScriptInjector.propTypes = {
    type: PropTypes.string.isRequired,
}

export default NoScriptInjector
