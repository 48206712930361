import * as types from '../actionTypes'

export default function lpDataReducer(state = null, action) {
    const { payload } = action
    switch (action.type) {
        case types.SET_LP_DATA:
            return {
                ...(state || {}),
                ...payload,
            }
        case types.SET_CAMPAIGN_DATA:
            return {
                ...(state || {}),
                activeCampaign: payload,
            }
        default:
            return state
    }
}
