import React from 'react'
import PropTypes from 'prop-types'

import DynamicSvgIcon from './DynamicSvgIcon'

const PoweredBy = ({ data }) => {
    const { text, logo, link } = data

    return (
        <div className="powered-by">
            {text}
            <a href={link.href} title={link.title}>
                <DynamicSvgIcon name={logo} className="powered-by__svg" />
            </a>
        </div>
    )
}

PoweredBy.propTypes = {
    data: PropTypes.object,
}

PoweredBy.defaultProps = {
    data: {
        text: 'powered by',
        logo: 'logos/GymondoLogo',
        link: {
            href: '//www.gymondo.com',
            title: 'Gymondo besuchen',
        },
    },
}

export default PoweredBy
