import React from 'react'
import PropTypes from 'prop-types'

const PackageUspDescription = ({ data }) => {
    const { title, text } = data

    return (
        <div className="lp-packageusp__description">
            {title && <h4 className="lp-packageusp__subtitle">{title}</h4>}
            {text && <span className="lp-packageusp__text">{text}</span>}
        </div>
    )
}

PackageUspDescription.propTypes = {
    data: PropTypes.object.isRequired,
}

export default PackageUspDescription
