import URLS from '../../config/urls'

const BASE_URL = URLS.GYM_BASE_URL
const GYMONDO_DE = `${BASE_URL}/de/`
const GYMONDO_EN = `${BASE_URL}/en/`
const cookieURL = 'privacy/#5-cookies'

const config = {
    /* eslint-disable max-len */
    de: {
        text:
            'Diese Webseite verwendet Cookies um die Nutzung unseres Angebot für dich zu optimieren. Wenn du deinen Besuch fortsetzt, stimmst du der Verwendung zu. ',
        link: {
            label: 'Weitere Infos zu Cookies',
            mobileLabel: 'Cookies',
            url: `${GYMONDO_DE}${cookieURL}`,
        },
        mobileText1: 'Wir verwenden ',
        mobileText2: '. Wenn du dem zustimmst, trainiere einfach weiter.',
    },
    en: {
        text:
            'This website uses cookies to make it easier for you to use. If you continue using the website, you agree to their use. ',
        link: {
            label: 'More information.',
            mobileLabel: 'Cookies',
            url: `${GYMONDO_EN}${cookieURL}`,
        },
        mobileText1: 'We use ',
        mobileText2: '. If you agree, just keep training.',
    },
    /* eslint-enable max-len */
}

const getConfig = locale => config[locale] || config.de

export default getConfig
