const id = 5
const name = 'mtc'
const programId = 270

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Daniel Aminati - 4 Wochen Coaching für mehr Motivation',
        meta: {
            description:
                'Daniel Aminati coacht dich für mehr Motivation, Erfolg und Selbstliebe',
            keywords:
                'motivation, gesund, daniel aminati, motivierter, selbstbewusster, positiv, erfolg, erfolgreich',
            'og:title':
                'Daniel Aminati - 4 Wochen Coaching für mehr Motivation',
            'og:url': 'https://www.make-the-change.de/',
            'og:description':
                'Daniel Aminati coacht dich für mehr Motivation, Erfolg und Selbstliebe',
        },
    },

    bodyClass: 'mlp-mtc',
    logo: 'logos/DanielAminatiMTC',

    topHeader: 'white ',

    header: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mtc/lp_v2/header/header.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mtc/lp_v2/header/header-mb.jpg',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    cta: {
        text: {
            general: '7 Tage kostenlos testen',
        },
    },

    usp: [
        {
            id: 0,
            font: 'guillon',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mtc/lp_v2/usp/usp-1.jpg',
                    title: 'Mehr Motivation',
                    text:
                        'Du kommst im Leben nicht weiter, weil dir der Antrieb fehlt? Mit Daniel legst du den Schalter um und holst du dir deine Motivation zurück. Erfolg, Selbstliebe und Disziplin sind eine Frage des Mindsets.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mtc/lp_v2/usp/usp-2.jpg',
                    title: 'Positiv Denken',
                    text:
                        'Think positive! Für Daniel nicht nur ein daher gesagter Spruch, sondern ein Lebensmotto. Vielleicht auch bald für dich. Lerne, welchen Einfluss positive Gedanken auf dich und deine Umwelt haben.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mtc/lp_v2/usp/usp-3.jpg',
                    title: 'Endlich Erfolg',
                    text:
                        'Wieso manche Menschen erfolgreicher sind als andere? Finde es heraus und lerne, wie auch du erfolgreich wirst. Daniel teilt seine Geschichte mit dir und verrät dir seinen ganz persönlichen Erfolgsplan.',
                },
            ],
        },
    ],

    why: {
        title:
            'Willkommen in deinem neuen Leben! Daniels 4 Wochen Coaching für mehr Motivation, Erfolg und Selbstliebe.',
        paragraphs: [
            'Erfolg und Motivation beginnen im Kopf. Daniel Aminati coacht dich auf deinem Weg zu einem besseren Selbst. Du wirst nach dem 4 wöchigen Programm motivierter, selbstbewusster und erfolgreicher durch dein Leben gehen.',
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mtc/lp_v2/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mtc/lp_v2/testimonial/testimonial-mb.jpg',
        quote:
            'Erfolg heißt für mich in Bewegung zu bleiben. Erfolg ist ein Zustand, der sich verändert. Und wir uns mit ihm.',
        author: 'Daniel Aminati',
    },

    iconGrid: {
        title: 'Diese Ziele wirst du erreichen',
        content: [
            {
                icon: 'ThumbUp',
                text:
                    'Erfolg - Daniel Aminati gibt dir Insights an die Hand, die dich willensstark machen, dein Bewusstsein erweitern und den Fokus auf das legen, worauf es ankommt. So erreichst du deine Ziele!',
            },
            {
                icon: 'Smiley',
                text:
                    'Glück erfahren - Video für Video wirst du mehr von Daniel lernen und in deinem Alltag anwenden können. Du entwickelst so deine Persönlichkeit weiter - make the change!',
            },
            {
                icon: 'Fist',
                text:
                    'Motivation - Motivation kommt von innen. Daniels Coaching bringt dich auf den richtigen Weg, um deine Motivation zu wecken und dauerhaft zu halten.',
            },
            {
                icon: 'Dance',
                text:
                    'Selbstbewusstsein - Daniel zeigt dir, wie du selbstbewusster durch dein Leben gehst. Mit hilfreichen Tipps und kleinen Aufgaben stärkst du dein Selbstbewusstsein.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        img: `${process.env.PUBLIC_URL}/static/img/mtc/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/mtc/devices_mobile.jpg`,
    },

    packageUsp: {
        title: 'Das Komplett-Paket für deinen Erfolg',
        text: [
            {
                title: 'Einfach und verständlich',
                text:
                    'Schritt für Schritt führt dich Daniel mit wöchentlich neuen Videos durchs Programm.',
            },
            {
                title: 'Wenig Aufwand',
                text:
                    'Nur dreimal wöchentlich 10 Minuten. Egal wie stressig dein Alltag ist, soviel Zeit hat jeder.',
            },
            {
                title: 'Komplettes Konzept',
                text:
                    'Du bekommst zu den Videos, Begleitmaterial per Mail und persönliche Tipps von Daniel.',
            },
            {
                title: 'Gedanken programmieren',
                text:
                    'Mit kleine Aufgaben, Übungen und Meditationen coacht Daniel dich zu einem positiven Mindset.',
            },
            {
                title: 'Daniels Empfehlungen',
                text:
                    'Freu dich auf seine persönlichen Buch Tipps, Podcasts und Playlisten.',
            },
        ],
        cta: {
            text: '7 Tage kostenlos testen',
        },
    },
    /* eslint-enable max-len */
})
