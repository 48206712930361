const name = 'pureflow'
const id = 248
const programId = 367

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: `Sarah's Pure Flow | Yoga mit Sarah Harrison | Gymondo®`,
        meta: {
            description:
                'Entspanne deine Muskulatur & stärke deinen Körper mit Sarah Harrison. Exklusive Workouts & Programme mit Team Harrison – nur bei Gymondo. Jetzt gratis testen.',
            keywords: 'Team Harrison, Workouts mit Team Harrison, Harrisons ',
            'og:title': `Sarah's Pure Flow | Yoga mit Sarah Harrison | Gymondo®`,
            'og:url': 'https://pureflow.gymondo.fitness/sarahs-pure-flow',
            'og:description':
                'Entspanne deine Muskulatur & stärke deinen Körper mit Sarah Harrison. Exklusive Workouts & Programme mit Team Harrison – nur bei Gymondo. Jetzt gratis testen.',
        },
        favicon: 'favicon.ico',
    },
    locale: 'de',

    bodyClass: 'mlp-pure-flow',

    logo: 'logos/PureFlow',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Gratiswoche starten',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/387_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 TAGE KOSTENLOS TRAINIEREN',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/pure-flow/usp/usp-1.jpg`,
                    title: 'Stärke deinen Körper',
                    text:
                        'Du brauchst nur 25 Minuten pro Einheit, um deinen gesamten Körper zu stärken und deine Muskeln zu kräftigen.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/pure-flow/usp/usp-2.jpg`,
                    title: 'Entspanne deine Muskulatur',
                    text:
                        'Gemeinsam mit Coach Romina zeigt dir Sarah, wie du deine Muskulatur dehnst, entspannst und beweglicher machst.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/pure-flow/usp/usp-3.jpg`,
                    title: 'Vergiss Alltagsstress',
                    text:
                        'Yoga hilft dir dabei, deine innere Mitte zu finden, Stress abzubauen und neue Energie zu tanken – und das in nur zwei Wochen!',
                },
            ],
        },
    ],

    why: {
        title: 'Finde den perfekten Flow mit Sarah Harrison',
        paragraphs: [
            `Endlich – Sarah Harrisons erstes Yoga-Programm ist da! Schnapp dir deine tägliche Dosis Entspannung und stärke deine Muskulatur sanft mit Sarah und Coach Romina in Sarah's Pure Flow. Dich erwarten vier einzigartige Yoga-Sessions mit Sarahs absoluten Lieblingsübungen über je 25 Minuten. Stärke Körper & Geist mit dynamischen Moves, entspanne deine Muskulatur und finde deine innere Mitte. Sarah's Pure Flow bringt dir in 2 Wochen die Welt des Yoga näher und eignet sich perfekt, um 360 Shape oder dein laufendes Programm zu ergänzen.`,
        ],
    },

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/pure-flow/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/pure-flow/testimonial/testimonial-mb.jpg`,
        quote:
            '"Ich kann es kaum erwarten, dir meine Lieblings-Yogaflows zu zeigen und meine Leidenschaft mit dir zu teilen. Jedes Workout macht dich gleichzeitig stärker und entspannter. Lass uns loslegen!"',
        author: 'Sarah Harrison',
    },

    iconGrid: {
        title: `Das erwartet dich in Sarah's Pure Flow:`,
        content: [
            {
                icon: 'Yoga',
                title: '4 brandneue Yoga-Einheiten',
                text:
                    'Sarah erwartet dich mit 4 einzigartigen Sessions und einer Auswahl ihrer Lieblingsbewegungen und Flows in je 25 Minuten pro Workout.',
            },
            {
                icon: 'MidLevel',
                title: 'Für Anfänger und Fortgeschrittene',
                text: `Mit Sarah's Pure Flow kannst du deine Praxis vertiefen und Yoga besser kennenlernen. Perfekt, um auf deinem Erfahrungsschatz aufzubauen.`,
            },
            {
                icon: 'Fit',
                title: 'Von echten Yogis & Yoginis',
                text:
                    'Coach Romina ist eine echte Yogini und führt dich gemeinsam mit Sarah durch das Programm. Echte Expertise trifft auf wahre Harrison-Power.',
            },
            {
                icon: 'Heart',
                title: 'Sarah Harrison hautnah',
                text:
                    'Trainiere gemeinsam mit Sarah und hol dir das volle Harrison-Erlebnis direkt in dein Wohnzimmer – wann immer du willst!',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte sind auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV verfügbar.',
        img: `${process.env.PUBLIC_URL}/static/img/pure-flow/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/pure-flow/device/devices-mb.jpg`,
    },

    packageUsp: {
        title: `Dein Sarah's Pure Flow-Gesamtpaket`,
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text: `Sarah's Pure Flow eignet sich perfekt, um dein Training zu ergänzen oder in die Welt des Yoga einzutauchen.`,
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'In nur 20-25 Minuten kommst du mit Gymondo deinen individuellen Zielen einen Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame über 1300 hochauflösende Workout-Videos direkt per Smartphone, Web oder Smart-TV, sogar offline.',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause oder unterwegs ganz ohne Geräte und Equipment – du brauchst nichts als deine Matte.',
            },
            {
                title: 'Fitness für jedes Level',
                text:
                    'Ob Anfänger oder echte Sportskanone – bei uns findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 TAGE GRATIS TESTEN',
        },
    },
    /* eslint-enable max-len */
})
