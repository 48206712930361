import React from 'react'

import influencerData from '../../data-influencer/ivana'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const IvanaSantacruz = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default IvanaSantacruz
