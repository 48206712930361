import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { connect } from 'react-redux'

import {
    BtnRevWithSignupLink,
    IconGrid,
    LpQuote,
    LpWhy,
    TopHeader,
    WorkoutPreview,
    TopSection,
    Usp,
} from '../../components'

const PageLayout = ({ lpData, bodyClasses }) => (
    <main className={cn(lpData.bodyClass, bodyClasses)}>
        <TopHeader lpData={lpData} />

        <section className="page">
            <TopSection data={lpData} />

            <LpWhy data={lpData.why[0]} />
            <Usp className="content" uspList={lpData.usp} />
            <IconGrid className="content" data={lpData.iconGrid} />
            <BtnRevWithSignupLink
                className="btn-rev--primary"
                text={lpData.cta.text.general}
                wrapper
            />
            <LpQuote data={lpData.motivation} />
            <WorkoutPreview data={lpData} />
            <LpWhy className="lp-why--bg" data={lpData.why[1]} />
        </section>
    </main>
)

PageLayout.propTypes = {
    lpData: PropTypes.object.isRequired,
    bodyClasses: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
    return {
        bodyClasses: state.bodyClasses,
        lpData: state.lpData,
    }
}

export default connect(mapStateToProps)(PageLayout)
