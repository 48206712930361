import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import data from '../../data-influencer/daniel-aminati'
import * as lpDataActions from '../../store/actions/lpData'

import { Footer, HeadTagInjector, PoweredBy } from '../../components'

import HeaderLogo from '../../components/header/logos/HeaderLogo'

class VoucherCustomerSupport extends Component {
    componentDidMount() {
        const {
            lpDataActions: { setLpData },
        } = this.props
        setLpData(data)
    }

    render() {
        const { cookies, lpData } = this.props

        if (!lpData) {
            return <h3>... loading data</h3>
        }

        return (
            <>
                <HeadTagInjector data={lpData.head} />

                <main
                    className={`${lpData.bodyClass} ${
                        cookies.consent ? '' : 'no-consent'
                    }`}
                >
                    <section className="page redeem-page">
                        <div className="redeem-image">
                            <HeaderLogo data={lpData.influencerLogo} />
                        </div>

                        <div className="redeem">
                            <div className="redeem-container">
                                <h2 className="redeem__title avenir-heavy">
                                    Mach Dich Krass By Daniel Aminati
                                </h2>

                                <p className="redeem__text">
                                    {/* eslint-disable max-len */}
                                    Deinen Gutschein kannst du ab sofort für
                                    mach dich krass powered by Gymondo einlösen.
                                    Somit hast du nicht nur Zugriff auf Daniel
                                    Aminatis Fitness Programm, sondern
                                    gleichzeitig auf über 50 Gymondo
                                    Trainingsprogramme. Für die Einlösung deines
                                    Gutscheins kontaktiere bitte einfach unseren
                                    Kundensupport und gib deinen Gutschein-Code
                                    an. Wir helfen dir gerne weiter!
                                </p>
                                {/* eslint-enable max-len */}
                                <a
                                    className="redeem__link"
                                    href="//gymondo.zendesk.com/hc/de/"
                                    title="Kontaktiere den Kundensupport"
                                >
                                    Kontaktiere den Kundensupport
                                </a>

                                <PoweredBy />
                            </div>
                        </div>
                    </section>
                </main>

                <Footer />
            </>
        )
    }
}

VoucherCustomerSupport.propTypes = {
    cookies: PropTypes.object.isRequired,
    lpData: PropTypes.object,
    lpDataActions: PropTypes.object.isRequired,
}

VoucherCustomerSupport.defaultProps = {
    lpData: {},
}

function mapStateToProps(state) {
    return {
        cookies: state.cookies,
        lpData: state.lpData,
        links: state.links,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        lpDataActions: bindActionCreators(lpDataActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoucherCustomerSupport)
