const name = 'strongmindbody'
const id = 326
const programId = 385

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Strong Mind & Body Yvonne Pferrer | Gymondo®',
        meta: {
            description:
                'Yvonne Pferrer zeigt dir, wie viel Spaß Kickboxen machen kann! Teste Gymondo jetzt kostenlos und trainiere in 10 brandneuen Workouts gemeinsam mit Yvonne.',
            keywords:
                'Kickboxen, Kickboxen Workout, Kickboxen Fitness, Yvonne Pferrer, Yvonne Pferrer Kickboxen, Yvonne Pferrer Gymondo, Training, Gymondo Influencer, Kickboxen Online, Kickboxen Training',
            'og:title': 'Strong Mind & Body Yvonne Pferrer | Gymondo®',
            'og:url': 'https://yvonne-pferrer.gymondo.fitness/strong-mind-body',
            'og:description':
                'Yvonne Pferrer zeigt dir, wie viel Spaß Kickboxen machen kann! Teste Gymondo jetzt kostenlos und trainiere in 10 brandneuen Workouts gemeinsam mit Yvonne.',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-strong-mind-body',

    logo: 'logos/StrongMindBody',

    topHeader: 'white',

    loginModal: false,

    header: {
        btnGratis: 'GRATISWOCHE STARTEN',
        btnLogin: 'LOGIN',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/413_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 TAGE GRATIS TESTEN',
        },
    },

    why: {
        title: 'Ring frei für Yvonnes knackige Kickbox-Moves',
        paragraphs: [
            'Starte mit Yvonne Pferrer deinen vierwöchigen Kickbox-Crashkurs für mehr Stärke, Energie und Kondition! Erlerne die wichtigsten Grundlagen des Kickboxens und steigere deine Kraft, deine Ausdauer und dein Selbstvertrauen in 10 schwitzigen Power-Workouts. Dich erwarten schnelle Box-Moves, effektives Cardiotraining und klassische Bodyweight-Übungen, die deinen ganzen Körper stärken und straffen. Jedes Workout fordert deinen Kampfgeist etwas mehr heraus, damit du am Ende gemeinsam mit Yvonne über dich selbst hinaus wachsen kannst.',
        ],
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/strong-mind-body/usp/usp-1.jpg`,
                    title: 'Kraftvolles Ganzkörpertraining',
                    text:
                        'Stärke und straffe deinen ganzen Körper mit einer effektiven Kombination aus Kickboxen, Cardio und Bodyweight-Übungen.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/strong-mind-body/usp/usp-2.jpg`,
                    title: 'Kontinuierliche Steigerung',
                    text:
                        'Steigere gemeinsam mit Coach Yvonne über 10 Workouts die Trainingsintensität und meistere immer größere Herausforderungen.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/strong-mind-body/usp/usp-3.jpg`,
                    title: 'Mehr Power, mehr Selbstvertrauen',
                    text:
                        'Überwinde deine inneren Grenzen, entfessle deine Kraft und finde neues Selbstvertrauen, das dich dein Leben lang begleitet.',
                },
            ],
        },
    ],

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/strong-mind-body/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/strong-mind-body/testimonial/testimonial-mb.jpg`,
        quote:
            '"Ich freue mich schon mega auf unsere Workouts. Let\'s get ready to rumble!"',
        author: 'Yvonne Pferrer',
    },

    iconGrid: {
        title: 'Das erwartet dich in Strong Mind & Body',
        content: [
            {
                icon: 'CardioHeart',
                title: '10 knackige Workouts',
                text:
                    'Yvonne hat für dich ihre liebsten Kickbox-Übungen mit intensiven Cardio-Moves kombiniert. Gemeinsam schwitzt ihr, lacht ihr und feiert Erfolge.',
            },
            {
                icon: 'MidLevel',
                title: 'Für Anfänger und Fortgeschrittene',
                text:
                    'Egal, ob du schon Erfahrung im Kampfsport hast oder gerade erst durchstartest – Strong Mind & Body eignet sich perfekt für jedes Level.',
            },
            {
                icon: 'PunchUp',
                title: 'Echtes Kickbox-Feeling',
                text:
                    'Coach Yvonne steht selbst regelmäßig im Ring und weiß genau, wie sie dir die wichtigsten Basics beibringt, ohne dabei den Spaß aus den Augen zu verlieren.',
            },
            {
                icon: 'Heart',
                title: 'Die perfekte Kombination',
                text:
                    'Kickbox-Training ist ein intensives Workout für den ganzen Körper. Du stärkst, straffst und konditionierst dich in nur 4 Wochen von Kopf bis Fuß.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte sind auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV verfügbar.',
        subtitle: '',
        img: `${process.env.PUBLIC_URL}/static/img/strong-mind-body/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/strong-mind-body/device/devices-mb.jpg`,
    },

    packageUsp: {
        title: 'Dein Strong Mind & Body-Gesamtpaket',
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text:
                    'Strong Mind & Body ist perfekt auf deine individuellen Wünsche und Bedürfnisse zugeschnitten.',
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'In nur 20–25 Minuten pro Workout kommst du mit Gymondo deinen Fitnesszielen einen Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame über 1400 hochauflösende Workout-Videos direkt per Smartphone, Web oder Smart-TV – sogar offline.',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause oder unterwegs ganz ohne Geräte und Equipment – du brauchst nichts als deine Matte.',
            },
            {
                title: 'Fitness für jedes Level',
                text:
                    'Ob Anfänger oder echte Sportskanone – bei uns findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 TAGE GRATIS TESTEN',
        },
    },
    /* eslint-enable max-len */
})
