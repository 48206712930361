import FacebookLogo from '../../images/svg/social/Facebook'
import InstagramLogo from '../../images/svg/social/Instagram'
import YouTubeLogo from '../../images/svg/social/YouTube'
import URLS from '../../config/urls'

const BASE_URL = URLS.GYM_BASE_URL
const GYMONDO_DE = `${BASE_URL}/de/`
const GYMONDO_EN = `${BASE_URL}/en/`
const links = {
    terms: 'terms-and-conditions',
    imprint: 'imprint',
    privacy: 'privacy',
    instagram: 'https://instagram.com/',
    facebook: 'https://www.facebook.com/',
    youtube: 'https://www.youtube.com/user/Gymondo/',
    instructions: 'instructions/',
    success: 'success/',
    community: 'community/facebook/',
    support: 'https://gymondo.zendesk.com/hc/',
    magazine: 'https://www.gymondo.com/magazin/',
    press: 'press/',
    jobs: 'https://gymondo-gmbh-jobs.personio.de/',
}

const config = {
    /* eslint-disable max-len */
    de: {
        gymondo: GYMONDO_DE,
        copyright: 'Alle Rechte vorbehalten.',
        privacy: {
            links: [
                {
                    label: 'AGB',
                    url: `${GYMONDO_DE}${links.terms}`,
                },
                {
                    label: 'Impressum',
                    url: `${GYMONDO_DE}${links.imprint}`,
                },
                {
                    label: 'Datenschutz',
                    url: `${GYMONDO_DE}${links.privacy}`,
                },
            ],
        },
        facts: [
            {
                title: 'Mehr Infos',
                links: [
                    {
                        label: 'Programme',
                        url: GYMONDO_DE,
                    },
                    {
                        label: 'Preis',
                        custom: 'signup',
                    },
                    {
                        label: "So geht's",
                        url: `${GYMONDO_DE}${links.instructions}`,
                    },
                    {
                        label: 'Erfolge',
                        url: `${GYMONDO_DE}${links.success}`,
                    },
                ],
            },
            {
                title: 'Kundenservice',
                links: [
                    {
                        label: 'Community',
                        url: `${GYMONDO_DE}${links.community}`,
                    },
                    {
                        label: 'Hilfe & Support',
                        url: `${links.support}de/`,
                    },
                ],
            },
            {
                title: 'Wissenswertes',
                links: [
                    {
                        label: 'Magazin',
                        url: `${links.magazine}de/`,
                    },
                    {
                        label: 'Equipment',
                        url: `${links.support}de/sections/360000784354-Equipment`,
                        hidden: 'equipment',
                    },
                    {
                        label: 'Challenges',
                        url: `${links.support}de/sections/360000784334-Challenge`,
                        hidden: 'challenges',
                    },
                ],
            },
            {
                title: 'Unternehmen',
                links: [
                    {
                        label: 'Presse',
                        url: `${GYMONDO_DE}${links.press}`,
                        hidden: 'press',
                    },
                    {
                        label: 'Jobs',
                        url: links.jobs,
                    },
                ],
            },
        ],
        note: {
            strong: 'Hinweis: ',
            text:
                'Personen, die an Diabetes erkrankt oder schwanger sind, wird die Teilnahme am Online-Programm ohne vorherige Rücksprache mit ihrem Arzt nicht empfohlen. Auch zur Behandlung krankhaften Übergewichts ist das Online-Programm nicht geeignet.',
        },
        icons: [
            {
                url: `${links.instagram}gymondo/`,
                svg: InstagramLogo,
                title: 'Instagram',
            },
            {
                url: `${links.facebook}gymondo/`,
                svg: FacebookLogo,
                title: 'Facebook',
            },
            { url: links.youtube, svg: YouTubeLogo, title: 'Youtube' },
        ],
    },
    en: {
        gymondo: GYMONDO_EN,
        copyright: 'All rights reserved.',
        privacy: {
            links: [
                {
                    label: 'Terms & Conditions',
                    url: `${GYMONDO_EN}${links.terms}`,
                },
                {
                    label: 'Legal notice',
                    url: `${GYMONDO_EN}${links.imprint}`,
                },
                {
                    label: 'Privacy policy',
                    url: `${GYMONDO_EN}${links.privacy}`,
                },
            ],
        },
        facts: [
            {
                title: 'More information',
                links: [
                    {
                        label: 'Programs',
                        url: GYMONDO_EN,
                    },
                    {
                        label: 'Price',
                        custom: 'signup',
                    },
                    {
                        label: 'How it works',
                        url: `${GYMONDO_EN}${links.instructions}`,
                    },
                    {
                        label: 'Success stories',
                        url: `${GYMONDO_EN}${links.success}`,
                    },
                ],
            },
            {
                title: 'Customer support',
                links: [
                    {
                        label: 'Community',
                        url: `${GYMONDO_EN}${links.community}`,
                    },
                    {
                        label: 'Help center',
                        url: `${links.support}en-150/`,
                    },
                ],
            },
            {
                title: 'Learn more',
                links: [
                    {
                        label: 'Blog',
                        url: `${links.magazine}en/`,
                    },
                    {
                        label: 'Equipment',
                        url: `${links.support}en-150/sections/360002434100-Equipment`,
                        hidden: 'equipment',
                    },
                    {
                        label: 'Challenges',
                        url: `${links.support}en-150/sections/360002434120-Challenge`,
                        hidden: 'challenges',
                    },
                ],
            },
            {
                title: 'Company',
                links: [
                    {
                        label: 'Press',
                        url: `${GYMONDO_EN}${links.press}`,
                        hidden: 'press',
                    },
                    {
                        label: 'Careers',
                        url: links.jobs,
                    },
                ],
            },
        ],
        note: {
            strong: 'Note: ',
            text:
                'Individuals with diabetes or women who are pregnant should not participate in the online program without first consulting their doctor. The online program is also not suitable for treating pathological obesity.',
        },
        icons: [
            {
                url: `${links.instagram}gymondo_official/`,
                svg: InstagramLogo,
                title: 'Instagram',
            },
            {
                url: `${links.facebook}groups/3014564571888762/`,
                svg: FacebookLogo,
                title: 'Facebook',
            },
            { url: links.youtube, svg: YouTubeLogo, title: 'Youtube' },
        ],
    },
    /* eslint-enable max-len */
}

const getConfig = locale => config[locale] || config.de

export default getConfig
