import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PlayButtonIcon from '../../images/svg/Preview'
import VideoModal from '../modals/VideoModal'
import getConfig from './preview-button.config'
/**
 * Displays the preview icon (optionally with description)
 * that opens video modal onClick
 *
 * @param {Object} props
 * @param {String} props.url string with video url
 * @param {Boolean} props.description defines if description should be included
 * @returns {React.Component}
 */

class PreviewButton extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false,
        }

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    openModal() {
        this.setState({ modalOpen: true })
    }

    closeModal() {
        this.setState({ modalOpen: false })
    }

    render() {
        const { modalOpen } = this.state
        const { url, description, isIframe, locale } = this.props

        const { text } = getConfig(locale)

        return (
            <>
                <div className="preview-button">
                    <PlayButtonIcon
                        className="preview__icon"
                        onClick={this.openModal}
                    />
                    {description && (
                        <div className="preview__description">{text}</div>
                    )}
                </div>

                <VideoModal
                    open={modalOpen}
                    onClose={this.closeModal}
                    url={url}
                    isIframe={isIframe}
                />
            </>
        )
    }
}

PreviewButton.defaultProps = {
    description: null,
    url: null,
    isIframe: null,
    locale: 'de',
}

PreviewButton.propTypes = {
    description: PropTypes.bool,
    url: PropTypes.string,
    isIframe: PropTypes.bool,
    locale: PropTypes.string,
}

export default PreviewButton
