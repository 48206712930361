/* eslint react/display-name: 0 */

import React from 'react'

import TemporaryRedirect from '../../pages/TemporaryRedirect'
import BigRefundRedirect from '../../pages/BIGRefundRedirect'
import {
    SophiaThiel,
    RedeemVoucherSOT,
    VoucherCustomerSupport,
} from '../../pages/sophia-thiel'

import PrefixEnum from '../prefixes'

export default Object.freeze([
    { path: PrefixEnum.SOPHIA_THIEL, component: SophiaThiel },
    { path: `${PrefixEnum.SOPHIA_THIEL}/redeem`, component: RedeemVoucherSOT },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/unredeemable`,
        component: VoucherCustomerSupport,
    },

    // REDIRECTS (see GW-1798)
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/einfachschlank`,
        component: VoucherCustomerSupport,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/einfachschlank`,
        component: VoucherCustomerSupport,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/sweets`,
        component: VoucherCustomerSupport,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/sweets`,
        component: VoucherCustomerSupport,
    },

    { path: `${PrefixEnum.SOPHIA_THIEL}/amazon`, component: RedeemVoucherSOT },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/amazon`,
        component: RedeemVoucherSOT,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/gutschein`,
        component: RedeemVoucherSOT,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/gutschein`,
        component: RedeemVoucherSOT,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/traumfigur`,
        component: RedeemVoucherSOT,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/traumfigur`,
        component: RedeemVoucherSOT,
    },

    {
        path: `${PrefixEnum.SOPHIA_THIEL}/sphthlfrwrktlp`,
        component: SophiaThiel,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/sphthlfrwrktlp`,
        component: SophiaThiel,
    },
    { path: `${PrefixEnum.SOPHIA_THIEL}/information`, component: SophiaThiel },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/information`,
        component: SophiaThiel,
    },
    { path: `${PrefixEnum.SOPHIA_THIEL}/de`, component: SophiaThiel },

    {
        path: `${PrefixEnum.SOPHIA_THIEL}/big`,
        component: props => (
            <TemporaryRedirect
                {...props}
                endDate={new Date(2019, 0, 1)}
                TargetAfterEnd={SophiaThiel}
                TargetBeforeEnd={() => {
                    window.location =
                        '//sophiathiel.big-direkt.de/?redref=SOT_7NXT'
                }}
            />
        ),
    },

    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/big`,
        component: props => (
            <TemporaryRedirect
                {...props}
                endDate={new Date(2019, 0, 1)}
                TargetAfterEnd={SophiaThiel}
                TargetBeforeEnd={() => {
                    window.location =
                        '//sophiathiel.big-direkt.de/?redref=SOT_7NXT'
                }}
            />
        ),
    },

    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/home/big/refund`,
        component: BigRefundRedirect,
    },

    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/home/payment/change`,
        component: () => {
            window.location =
                '//classic.sophia-thiel.com/de/home/payment/change'
        },
    },

    {
        path: `${PrefixEnum.SOPHIA_THIEL}/de/buy/select-product/*`,
        component: SophiaThiel,
    },
    { path: `${PrefixEnum.SOPHIA_THIEL}/de/buy/*`, component: SophiaThiel },

    {
        path: `${PrefixEnum.SOPHIA_THIEL}/information/glossybox`,
        component: SophiaThiel,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/special18/annaxo2018`,
        component: SophiaThiel,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/special18/tiger2018`,
        component: SophiaThiel,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/special18/coco2018`,
        component: SophiaThiel,
    },
    {
        path: `${PrefixEnum.SOPHIA_THIEL}/special18/love2021`,
        component: SophiaThiel,
    },
    { path: `${PrefixEnum.SOPHIA_THIEL}/shape`, component: SophiaThiel },
    { path: `${PrefixEnum.SOPHIA_THIEL}/dm`, component: SophiaThiel },
    { path: `${PrefixEnum.SOPHIA_THIEL}/intouch`, component: SophiaThiel },
    { path: `${PrefixEnum.SOPHIA_THIEL}/joy`, component: SophiaThiel },
    { path: `${PrefixEnum.SOPHIA_THIEL}/cookbook`, component: SophiaThiel },
    { path: `${PrefixEnum.SOPHIA_THIEL}/aktion`, component: SophiaThiel },
    { path: `${PrefixEnum.SOPHIA_THIEL}/de/aktion`, component: SophiaThiel },
])
