import React from 'react'

import NoScriptInjector from './components/NoScriptInjector'
import GymondoInfluencerRoutes from './routing/Routes'

const GymondoInfluencer = () => (
    <>
        <NoScriptInjector type="gtm" />
        <GymondoInfluencerRoutes />
    </>
)

export default GymondoInfluencer
