import React from 'react'
import PropTypes from 'prop-types'

import PreviewButton from '../preview-button/PreviewButton'

/**
 * Displays a workout preview item for landingpages.
 *
 * @param {Object} props
 * @param {Object} props.video Object containing the video element infos
 * @returns {React.Component}
 */

const PreviewItem = ({ video, locale }) => {
    const headStyle = {
        backgroundImage: `url(${video.img})` /* eslint-disable-line */,
    }

    return (
        <div className="preview">
            <div className="preview__hd" style={headStyle}>
                <PreviewButton
                    url={video.url}
                    isIframe={video.isIframe}
                    locale={locale}
                />
            </div>

            <div className="preview__ft">
                <p className="preview__title">{video.title}</p>
            </div>
        </div>
    )
}

PreviewItem.defaultProps = {
    locale: 'de',
}

PreviewItem.propTypes = {
    video: PropTypes.object.isRequired,
    locale: PropTypes.string,
}

export default PreviewItem
