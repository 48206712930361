const name = 'maishape'
const id = 23
const programId = 354

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'MaiShape | Trainiere mit Vanessa Mai und Gymondo®',
        meta: {
            description:
                'Stärke gemeinsam mit Vanessa Mai deine Muskulatur, straffe deinen Körper und erreiche deine Fitnessziele. Jetzt anmelden und durchstarten.',
            keywords: 'Vanessa Mai, maishape ',
            'og:title': 'MaiShape | Trainiere mit Vanessa Mai und Gymondo®',
            'og:url': 'https://maishape.gymondo.fitness/vanessa/',
            'og:description':
                'Stärke gemeinsam mit Vanessa Mai deine Muskulatur, straffe deinen Körper und erreiche deine Fitnessziele. Jetzt anmelden und durchstarten.',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-maishape',

    logo: 'logos/MaiShape',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Gratiswoche starten',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/376_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/mai-shape/usp/usp-1.jpg`,
                    title: 'Stärke deine Muskulatur',
                    text:
                        'Du brauchst nur 20 Minuten, um deinen gesamten Körper gemeinsam mit Vanessa Mai und Coach Denise von Kopf bis Fuß zu trainieren.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/mai-shape/usp/usp-2.jpg`,
                    title: 'Straffe deinen Körper',
                    text:
                        '10 knackige Workouts mit effektiven Übungen und Kombinationen sorgen für eine straffe Figur und eine definierte Körpermitte.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/mai-shape/usp/usp-3.jpg`,
                    title: 'Verbrenne Kalorien und Körperfett',
                    text:
                        'Vanessa zeigt dir, wie du mit MetCon (Metabolic Conditioning) deinen Puls zum Rasen und dein Körperfett zum Schmelzen bringst.',
                },
            ],
        },
    ],

    why: {
        title: 'Erreiche gemeinsam mit Vanessa Mai deine Fitnessziele',
        paragraphs: [
            'Gemeinsam trainieren, gemeinsam schwitzen, gemeinsam fit werden – mit Vanessa Mai und Gymondo! In Vanessas zweitem Fitnessprogramm MaiShape erwarten dich 10 brandneue Workouts, die deinen ganzen Körper stärken und straffen, deinen Stoffwechsel anregen und deinen Core besonders in den Fokus stellen – perfekt für Anfänger und Fortgeschrittene! Vanessa zeigt dir ihre Lieblingsübungen und die effektivsten Workouts, um so richtig fit zu werden.',
        ],
    },

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/mai-shape/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/mai-shape/testimonial/testimonial-mb.jpg`,
        quote:
            'MaiShape ist anders, abwechslungsreich, neu und macht einfach ganz viel Spaß!',
        author: 'Vanessa Mai',
    },

    iconGrid: {
        title: 'Mit MaiShape erzielst du langfristige Resultate:',
        content: [
            {
                icon: 'Fit',
                title: 'Trainiere deinen gesamten Körper',
                text:
                    'Intensive Workouts mit kurzen Pausen zwischen den Übungen sorgen dafür, dass du in kürzester Zeit jeden Muskel deines Körpers trainierst und stärkst.',
            },
            {
                icon: 'Strong',
                title: 'Definiere deine Bauchmuskeln',
                text:
                    'Jedes Workout in MaiShape enthält zusätzliche Core-Übungen für den extra Burn. Stärke und straffe deine Körpermitte gemeinsam mit Vanessa Mai!',
            },
            {
                icon: 'Slim',
                title: 'Erreiche deine Wohlfühl-Figur',
                text:
                    'Effektives MetCon-Training verbrennt in kurzer Zeit noch mehr Kalorien. Die perfekte Herausforderung für Anfänger und Fortgeschrittene!',
            },
            {
                icon: 'Heart',
                title: 'Bist du bereit für MaiShape?',
                text:
                    'MaiShape ist dein Rundum-sorglos-Paket für echte Ergebnisse in nur 6 Wochen. Und das Beste: Du trainierst, schwitzt und lachst gemeinsam mit Vanessa Mai.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte sind auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV verfügbar.',
        img: `${process.env.PUBLIC_URL}/static/img/mai-shape/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/mai-shape/device/devices-mb.jpg`,
    },

    packageUsp: {
        title: 'Dein MaiShape-Gesamtpaket',
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text:
                    'MaiShape by Vanessa Mai ist genau auf deine persönlichen Bedürfnisse und Vorlieben zugeschnitten.',
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'In nur 20 Minuten kommst du mit Gymondo deinen individuellen Zielen einen Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame hochauflösende Workout-Videos direkt per Smartphone, Web oder Smart-TV, sogar offline.',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause oder unterwegs – du brauchst nichts als deine Matte.',
            },
            {
                title: 'Fitness für jedes Level',
                text:
                    'Ob Anfänger oder echte Sportskanone - hier findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 TAGE GRATIS TESTEN',
        },
    },
    /* eslint-enable max-len */
})
