const name = 'danceworks'
const id = 325
const programId = 386

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'DANCEWORKS by Dianne Buswell | Exclusively at Gymondo®',
        meta: {
            description:
                'Join Dianne Buswell for DANCEWORKS, an exclusive dance fitness programme for beginners — only available at Gymondo. Start 7 days free.',
            keywords:
                'Dianne Buswell, Dianne Buswell fitness programme, Dianne Buswell dance programme Danceworks, Danceworks by Dianne Buswell Dance fitness, Dance programme, Gymondo, Dance cardio',
            'og:title':
                'DANCEWORKS by Dianne Buswell | Exclusively at Gymondo®',
            'og:url': 'https://dianne-buswell.gymondo.fitness/dance-works',
            'og:description':
                'Join Dianne Buswell for DANCEWORKS, an exclusive dance fitness programme for beginners — only available at Gymondo. Start 7 days free.',
        },
        favicon: 'favicon.ico',
    },
    locale: 'en',

    bodyClass: 'mlp-dance-works',

    logo: 'logos/DanceWorks',

    topHeader: 'white',

    loginModal: false,

    header: {
        btnGratis: 'START 7 DAYS FREE',
        btnLogin: 'LOGIN',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/402_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: 'START 7 DAYS FREE',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/dance-works/usp/usp-1.jpg`,
                    title: 'A Truly Unique 10-Week Training Event',
                    text:
                        'This exclusive content is only available at Gymondo. Become your most confident self in 10 short weeks.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/dance-works/usp/usp-2.jpg`,
                    title: 'Results in 30 Minutes or Less',
                    text:
                        'Get results in 30 minutes or less with dance-inspired workouts that can easily fit into your busy lifestyle.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/dance-works/usp/usp-3.jpg`,
                    title: 'Expert Coaching Tips & Advice',
                    text:
                        'Get coaching & breakdowns directly from Dianne & trainer Alison to crush your goals & stay on track.',
                },
            ],
        },
    ],

    why: {
        title: 'Exclusive Training Programme by Dianne Buswell',
        paragraphs: [
            'Join Dianne Buswell for this exclusive Gymondo dance fitness programme for beginners. Featuring 10 dance-inspired toning workouts, each high-energy session feels like you’re spending time with old friends as you discover what movements your body is capable of. From hip-hop to ballroom to ballet & samba, you’ll learn easy-to-follow choreographed dance moves to connect to your body, increase your flexibility & improve your stamina, while getting toned and fit. Fall in love with moving in brand new ways & let Dianne’s infectious energy inspire you in this truly unique training event only available at Gymondo. Good vibes all the way!',
        ],
    },

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/dance-works/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/dance-works/testimonial/testimonial-mb.jpg`,
        quote:
            '“Dancing has been my life since I was four years old & I just absolutely love it. I’m really looking forward to sharing this experience with you.”',
        author: 'Dianne Buswell',
    },

    iconGrid: {
        title: 'What Goals Will I Achieve? ',
        content: [
            {
                icon: 'Fit',
                title: 'Get Toned',
                text:
                    'Build lean muscle with Dianne’s favourite full-body toning exercises for dancers.',
            },
            {
                icon: 'Smiley',
                title: 'Feel Happy',
                text:
                    'Raise your spirits & your heart rate through effective, cardio-based dance training. ',
            },
            {
                icon: 'Confidence',
                title: 'Gain Confidence',
                text:
                    'Become your most confident self in 6 short weeks with Dianne Buswell as your guide.',
            },
        ],
    },

    deviceList: {
        title: 'Unlock Your All-Access Membership',
        subtitle:
            'Get access to DANCEWORKS by Dianne Buswell, plus over 40 additional training programmes, including yoga, Pilates, weight training, HIIT & so much more. Available on your favourite device—Web, iOS, Android, Apple TV, Android TV & Amazon Fire TV.',
        img: `${process.env.PUBLIC_URL}/static/img/dance-works/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/dance-works/device/devices-mb.jpg`,
    },

    charity: {
        img: `${process.env.PUBLIC_URL}/static/img/dance-works/charity/charity.png`,
        title: 'Let’s Put Our Minds Together',
        subtitle:
            'Dianne & Gymondo have partnered with Work for Good, whose mission is to partner with small businesses to financially support charities through fundraising. Join us in support of mental health charity Mind to ensure no one has to face mental health problems alone. *For every DANCEWORKS purchase, we’ll donate £1 to Mind via Work for Good.',
        btnCharity: 'JOIN THE MOVEMENT',
        legal:
            '*To participate, you must activate the DANCEWORKS programme at the very beginning of your subscription period, once your trial ends.',
    },

    packageUsp: {
        title: 'Wait...There’s More',
        text: [
            {
                title: 'Explore 40+ Training Programmes',
                text:
                    'Unlock DANCEWORKS by Dianne Buswell, plus over 40 additional training programmes—from yoga to HIIT.',
            },
            {
                title: 'Get Fit in 30 ',
                text:
                    'Easily fit fitness into your lifestyle with quick & effective 30-minute dance-inspired workouts just 2 times a week.',
            },
            {
                title: 'Train Anywhere, Anytime',
                text:
                    'Whether at home or on the go, you can train your way—anywhere, anytime.',
            },
            {
                title: 'Zero Equipment Workouts',
                text:
                    'Who says you need fancy gym equipment to get in a great workout? A mat & some space are all you need.',
            },
            {
                title: 'Get Dancer Fit With Dianne Buswell',
                text:
                    "Train to feel confident inside & out with dance-inspired workouts you'll look forward to.",
            },
        ],
        cta: {
            text: 'START 7 DAYS FREE',
        },
    },
    /* eslint-enable max-len */
})
