import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

const LpCtaWrapper = BtnComponentToWrap => propsOfBtn => {
    const { wrapper } = propsOfBtn
    const { signup } = propsOfBtn.links

    // to gymondo
    if (wrapper) {
        return (
            <div className="btn-wrapper">
                <BtnComponentToWrap {...propsOfBtn} isATag href={signup} />
            </div>
        )
    }

    return <BtnComponentToWrap {...propsOfBtn} isATag href={signup} />
}

function mapStateToProps(state) {
    return {
        links: state.links,
    }
}

const ComposedLpCta = compose(connect(mapStateToProps), LpCtaWrapper)

export default ComposedLpCta
