/**
 * Keep an ALPHABETICAL order!
 */
export default Object.freeze({
    ADRIENNE: '/adrienne',
    ANDREA: '/andrea',
    COMMON: '/common', // this is the area for shared pages
    CATHY_HUMMELS: '/cathy',
    GET_STRONG: '/get-strong',
    GET_IN_SHAPE: '/get-in-shape',
    HARRISON: '/perfect-shape',
    HARRISON2: '/level-up',
    HEALTHY_INSIDE_OUT: '/healthyinsideout',
    IVANA_SANTACRUZ: '/ivana',
    LABRANT: '/labrantfit',
    LISA: '/lisa',
    MAI_SHAPE: '/mai-shape',
    DANIEL_AMINATI: '/mdk',
    DANIEL_AMINATI_MTC: '/mtc',
    PAOLA: '/paola',
    SOPHIA_THIEL: '/sot',
    TOTAL_BODY: '/total-body',
    URSULA: '/ursula',
    VANESSA: '/vanessa',
    LISA2: '/lisa-next-level',
    SHAPE_360: '/harrisons-360-shape',
    PURE_FLOW: '/sarahs-pure-flow',
    DANCE_WORKS: '/dance-works',
    STRONG_MIND_BODY: '/strong-mind-body',
    MAI_SHORTIES: '/mai-shorties',
    POWER: '/power',
})
