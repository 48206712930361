const name = 'lisa2'
const id = 17
const programId = 338

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Booty Next Level by Lisa Del Piero',
        meta: {
            description:
                'Lisa Del Piero bringt deinen Po in 10 Wochen ordentlich zum brennen und formt dabei noch eine tolle Sanduhrfigur.',
            keywords:
                'bestform, gesund, lisa del piero, booty, training, po, HIIT, sanduhrfigur, straffen',
            'og:title': 'Booty Next Level by Lisa Del Piero',
            'og:url': 'https://bootynextlevelbylisa.gymondo.fitness/',
            'og:description':
                'Lisa Del Piero bringt deinen Po in 10 Wochen ordentlich zum brennen und formt dabei noch eine tolle Sanduhrfigur.',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-lisa2',

    logo: 'logos/Lisa2',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Gratis Woche starten',
        preview:
            'https://akgymondo.akamaized.net/prgpreviews/3lTP0IkQdxRGNQL116uMK6_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa-2/lp/usp/usp-1.jpeg',
                    title: 'Workouts ganz ohne Geräte & Equipment',
                    text:
                        'Du brauchst keine Hanteln oder Geräte, um mit effektiven Workouts in nur 25 Minuten deinen Körper zu formen.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa-2/lp/usp/usp-2.jpeg',
                    title: 'Alles, was du für einen knackigen Po brauchst',
                    text:
                        'In Booty Next Level trainierst du mit besonderem Fokus auf Po und Core deinen Körper. Effektive Übungen mit individuellen Modifikationen bauen schnell Kraft auf und straffen deinen Body.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa-2/lp/usp/usp-3.jpeg',
                    title: 'Training mit Lisa Del Piero',
                    text:
                        'Lisa Del Piero ist nicht umsonst ein echter Star am Fitnesshimmel. In Booty Next Level trainierst du mit Lisa ganz nach ihrem Plan und bringst deinen Po mit Bodyweight-Workouts auf das nächste Level.',
                },
            ],
        },
    ],

    why: {
        title: 'Bring deinen Po auf das nächste Level!',
        paragraphs: [
            'Bei Booty Next Level mit Lisa Del Piero ist der Name Programm, denn hier machst du deine Rückseite zur Schokoladenseite! Lisa und ihre Trainingspartnerin Tara haben 10 einzigartige Workouts für dich zusammengestellt, die deinen Unterkörper auf das nächste Level bringen und für den echten Knackpo-Faktor sorgen. In diesem Programm baust du Kraft auf, straffst deinen Körper und verbrennst effektiv Fett, um deinen Body nach deinen eigenen Vorstellungen zu formen. Booty Next Level bringt dir die perfekte Balance zwischen anfängerfreundlichem Training und fordernden Workouts direkt in dein Wohnzimmer. Jede Übung lässt sich individuell modifizieren und an dein Fitness-Level anpassen. Worauf wartest du noch? Lisa und Tara freuen sich schon auf dich!',
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa-2/lp/testimonial/testimonial.jpeg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa-2/lp/testimonial/testimonial-mb.jpeg',
        quote:
            'Willkommen zu meinem P(r)ogramm Booty Next Level! Wie der Name schon sagt, liegt hier der Fokus auf deinem Po und Core, aber auch das Ausdauer- und Oberkörpertraining kommt auf keinen Fall zu kurz. Ich freue mich schon auf dich und wünsche dir ganz viel Spaß beim Training!',
        author: 'Lisa Del Piero',
    },

    iconGrid: {
        title: 'Mit Booty Next Level erzielst du langfristige Resultate',
        content: [
            {
                icon: 'Slim',
                title: 'Straffe deinen Körper',
                text:
                    'Erreiche einen straffen, definierten Body und fühl dich besser als je zuvor!',
            },
            {
                icon: 'Strong',
                title: 'Steigere deine Kraft',
                text:
                    'Starke, durchtrainierte Muskulatur sorgt für einen echten Stoffwechsel-Boost, verbrennt Kalorien und senkt das Verletzungsrisiko.',
            },
            {
                icon: 'Fit',
                title: 'Verbessere deine Ausdauer',
                text:
                    'Effektives Cardiotraining steigert deine kardiovaskuläre Ausdauer und deine Leistungsfähigkeit, sodass du einfach noch mehr erreichen kannst.',
            },
            {
                icon: 'Challenge',
                title: 'Stell dich der Herausforderung!',
                text:
                    ' Lisa Del Piero & ihre Trainerin Tara Parsi begleiten dich durch jedes Workout und stellen dich mit motivierenden Challenges auf die Probe. Bist du bereit?',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa-2/lp/device/devices.jpeg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa-2/lp/device/devices-mb.jpeg',
    },

    packageUsp: {
        title: 'Dein Booty Next Level-Gesamtpaket',
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text:
                    'Booty Next Level ist genau auf deine persönlichen Bedürfnisse und Vorlieben zugeschnitten.',
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'In nur 20-25 Minuten kommst du deinen individuellen Zielen einen Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame hochauflösende Workout-Videos direkt per Computer, Smart-TV oder Handy - sogar offline!',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause und unterwegs ganz ohne Geräte oder Equipment. Du brauchst dafür nichts als deine Matte.',
            },
            {
                title: 'Bring deinen Booty auf das nächste Level!',
                text:
                    'Ob Anfänger oder echte Sportskanone - hier findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 Tage gratis testen',
        },
    },
    /* eslint-enable max-len */
})
