import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import UspItem from './UspItem'

/**
 * Shows a line of n USP elements next to each other (n = 3 by default).
 *
 * @param {Object} props
 * @param {Object} props.uspList Contains the USP object from data file
 * @returns {React.Component}
 */

const Usp = ({ className, uspList }) => {
    const { content } = uspList

    return (
        <section className={cn('usp-list', className)}>
            {content.map(usp => (
                <UspItem
                    usp={usp}
                    key={usp.title}
                    align={uspList.align}
                    font={uspList.font}
                />
            ))}
        </section>
    )
}

Usp.propTypes = {
    className: PropTypes.string,
    uspList: PropTypes.object.isRequired,
}

Usp.defaultProps = {
    className: '',
}

export default Usp
