import React from 'react'

import influencerData from '../../data-influencer/labrant'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const Labrant = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default Labrant
