import React from 'react'

import influencerData from '../../data-influencer/daniel-aminati-mtc'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const DanielAminatiMTC = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default DanielAminatiMTC
