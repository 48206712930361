import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import ImgTextGridItem from './ImgTextGridItem'

const SuccessStories = ({ className, data }) => {
    const { content, title } = data

    return (
        <section className={cn('itg', className)}>
            <h3 className="itg__title mlp-title">{title}</h3>

            <div className="itg-container">
                {content.map(item => (
                    <ImgTextGridItem key={item.img} data={item} />
                ))}
            </div>
        </section>
    )
}

SuccessStories.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

SuccessStories.defaultProps = {
    className: '',
}

export default SuccessStories
