import React from 'react'

import REDIRECT_URLS from '../config/urls-redirect'
import hostnameTable from '../routing/hostnameTable'

const BigRefundRedirect = () => {
    const { hostname, search } = window.location
    const prefix = hostnameTable[hostname]

    window.location = `${REDIRECT_URLS.BIG[prefix]}${search}`

    // we have to return something...
    return <h2>loading...</h2>
}

export default BigRefundRedirect
