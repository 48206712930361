import moment from 'moment-timezone'

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import ProductItem from './ProductItem'

const ProductGrid = ({ className, data }) => {
    const { title, content, tracking, deadline } = data
    const now = moment.tz('Europe/Berlin')

    if (!now.isSameOrBefore(deadline)) {
        return null
    }

    return (
        // hide section after deadline
        <section className={cn('product-container', className)}>
            <h3 className="product-container__title mlp-title">{title}</h3>

            <div className="product-container-list">
                {content.map(product => (
                    <ProductItem
                        key={product.title}
                        font={data.font}
                        data={product}
                        tracking={tracking}
                    />
                ))}
            </div>
        </section>
    )
}

ProductGrid.defaultProps = {
    className: '',
}

ProductGrid.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

export default ProductGrid
