import React from 'react'

/**
 * This component will be rendered when we're loading
 * components dynamically with the react-loadable plugin.
 *
 * @return {React.Component}
 */
const DynamicCompLoader = () => <span>loading...</span>

export default DynamicCompLoader
