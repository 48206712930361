import React from 'react'
import PropTypes from 'prop-types'

import BtnRevWithSignupLink from '../cta/BtnRevWithSignupLink'
import DynamicSvgIcon from '../DynamicSvgIcon' //eslint-disable-line
import PoweredBy from '../PoweredBy'
import PreviewButton from '../preview-button/PreviewButton'

const TopSection = ({ data }) => {
    const { cta, header, logo, subtitle, title, locale } = data

    return (
        <div className="page__hd">
            <div className="page-teaser">
                {title && (
                    <h2
                        className={`page__header ${
                            title.font ? title.font : ''
                        }`}
                    >
                        {title.text}
                    </h2>
                )}
                {subtitle && (
                    <h3
                        className={`page__subheader ${
                            subtitle.font ? subtitle.font : ''
                        }`}
                    >
                        {subtitle.text}
                    </h3>
                )}

                {logo && (
                    <DynamicSvgIcon className="page__header" name={logo} />
                )}

                <div className="page-teaser__controls">
                    {header && header.preview && (
                        <PreviewButton
                            description
                            url={header.preview}
                            isIframe={header.isIframe}
                            locale={locale}
                        />
                    )}
                    {title && !title.noCta && (
                        <BtnRevWithSignupLink
                            className="btn-rev--primary"
                            text={cta.text.general}
                            wrapper
                        />
                    )}
                </div>
            </div>

            {!logo && <PoweredBy data={title.coop} />}
        </div>
    )
}

TopSection.propTypes = {
    data: PropTypes.object.isRequired,
}

export default TopSection
