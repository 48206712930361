import React from 'react'
import PropTypes from 'prop-types'

import UspListText from './UspListText'

/**
 * Displays a USP (unique selling point) item for landingpages.
 *
 * @param {Object} props
 * @param {Object} props.usp Object containing the USP element infos
 * @param {String} props.font If set, use that CSS font class
 * @param {String} props.align If string equals left, text will be aligned left, else centered
 * @return {React.Component}
 */
const UspItem = ({ usp, font, align }) => {
    const classes = {
        body: `usp__bd ${font ? 'usp__bd--nm' : ''}`,
        footer: `usp__ft ${align === 'left' ? 'usp__ft--left' : ''}`,
        title: `usp__title ${font ? `usp__title--separate ${font}` : ''}`,
    }
    const headStyle = {
        backgroundImage: `url(${usp.img})`, //
    }

    return (
        <div className="usp">
            <div className="usp__hd" style={headStyle} />

            <div className={classes.body}>
                <h4 className={classes.title}>{usp.title}</h4>
            </div>

            <div className={classes.footer}>
                {Array.isArray(usp.text) ? (
                    <UspListText list={usp.text} />
                ) : (
                    <p className="page__text">{usp.text}</p>
                )}
            </div>
        </div>
    )
}

UspItem.propTypes = {
    usp: PropTypes.object.isRequired,
    font: PropTypes.string,
    align: PropTypes.string,
}

UspItem.defaultProps = {
    font: '',
    align: '',
}

export default UspItem
