/* eslint react/display-name: 0, react/prop-types: 0 */
import React from 'react'

export default ({ className, onClick }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 16"
            className={className}
            onClick={onClick}
        >
            <g
                fill="none"
                fillRule="evenodd"
                strokeLinecap="square"
                strokeWidth="3"
            >
                <path d="M2 2h16M2 8h16M2 14h16" />
            </g>
        </svg>
    )
}
