const name = 'totalbody'
const id = 21
const programId = 353

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Total Body by Vogue Williams | Exclusively at Gymondo',
        meta: {
            description:
                'Vogue Williams combines her favorite toning & cardio exercises for intense, 30-minute workouts only available at Gymondo. Start Total Body by Vogue Williams today.',
            keywords:
                'Vogue Williams, Vogue Williams workouts, Toning exercises, Total-body toning, Total Body by Vogue Williams, Gymondo, Toning, Total-body workout, Cardio exercises, 30-minute workouts, Burn calories, Sculpt & tone',
            'og:title': 'Total Body by Vogue Williams | Exclusively at Gymondo',
            'og:url': 'https://totalbodybyvogue.gymondo.fitness',
            'og:description':
                'Vogue Williams combines her favorite toning & cardio exercises for intense, 30-minute workouts only available at Gymondo. Start Total Body by Vogue Williams today.',
        },
        favicon: 'favicon.ico',
    },
    locale: 'en',

    bodyClass: 'mlp-total-body',

    logo: 'logos/TotalBody',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Start your free week',
        btnLogin: 'Log in',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/373_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: 'START 7 DAYS FREE ',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/total-body/usp/usp-1.jpg`,
                    title: 'A Truly Unique 6-Week Training Event ',
                    text:
                        'This exclusive content is only available at Gymondo. Become your most confident self in 6 short weeks. ',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/total-body/usp/usp-2.jpg`,
                    title: 'Results in 30 Minutes or Less',
                    text:
                        'Get results in 30 minutes or less with total-body workouts that can easily fit into your busy schedule.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/total-body/usp/usp-3.jpg`,
                    title: 'Expert Coaching Tips & Advice',
                    text:
                        'Get coaching & breakdowns directly from Vogue & trainer John to crush your goals & stay on track.',
                },
            ],
        },
    ],

    why: {
        title: 'Exclusive Training Program by Vogue Williams',
        paragraphs: [
            'Join Vogue Williams for this exclusive Gymondo training program for beginners. Total Body by Vogue Williams will help you be your fittest, strongest and best self in 6 short weeks. Vogue has packed it with all-new bodyweight strength and cardio workouts you can’t get anywhere else. Plus, she’ll be right there with you every step of the way. Meet Vogue on the mat. You won’t be disappointed!',
        ],
    },

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/total-body/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/total-body/testimonial/testimonial-mb.jpg`,
        quote:
            'Exercising is something I get to do just for myself. When training, I don’t have to think about anything else. Instead, I just focus on the movements and how my body feels—it’s like meditation to me. I’m really looking forward to sharing this experience with you.',
        author: 'Vogue Williams',
    },

    iconGrid: {
        title: 'What Goals Will I Achieve? ',
        content: [
            {
                icon: 'Slim',
                title: 'Get Toned',
                text:
                    'Build long, lean muscle with Vogue’s favorite full-body toning exercises.',
            },
            {
                icon: 'Fit',
                title: 'Lose Fat',
                text:
                    'Get your heart rate up & body fat down with effective, cardio-based training.',
            },
            {
                icon: 'Heart',
                title: 'Gain Confidence',
                text:
                    'Regular exercise helps build confidence by improving your body image.',
            },
        ],
    },

    deviceList: {
        title: 'Unlock Your All-Access Membership',
        subtitle:
            'Get access to Total Body by Vogue Williams and 35+ additional training programs, including yoga, Pilates, weight training, HIIT and so much more. Available on your favorite device—Web, iOS, Android, Apple TV, Android TV and Amazon Fire TV.',
        img: `${process.env.PUBLIC_URL}/static/img/total-body/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/total-body/device/devices-mb.jpg`,
    },

    packageUsp: {
        title: 'Wait...There’s More',
        text: [
            {
                title: '35+ Training Programs',
                text:
                    'Unlock Total Body by Vogue Williams, plus over 35 additional training programs—from yoga to HIIT.',
            },
            {
                title: 'Get Fit in 30',
                text:
                    'Easily fit fitness into your lifestyle with quick & effective 30-minute training sessions just 3 times a week. ',
            },
            {
                title: 'Train Anywhere, Anytime',
                text:
                    'Whether at home or on the go, you can train your way—anywhere, anytime.',
            },
            {
                title: 'Zero Equipment Workouts',
                text:
                    'Who says you need fancy gym equipment to get in a great workout? A mat & some space is all you need.',
            },
            {
                title: 'Get Total-Body Toned With Vogue Williams ',
                text:
                    'Train to feel confident inside & out with workouts you’ll look forward to.',
            },
        ],
        cta: {
            text: 'START 7 DAYS FREE',
        },
    },
    /* eslint-enable max-len */
})
