import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import * as menuAction from '../../store/actions/menu'
import * as lpDataAction from '../../store/actions/lpData'
import getConfig from './header.config'

import {
    CampaignBanner,
    CookieBanner,
    NewBanner,
    TopMenuDrawer,
    TopMenuDrawerMenu,
    TopNavigation,
} from '..'

class TopHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            topBarClass: 'top-header--initial',
        }
        this._scrollY = 0

        this.hideMobileMenu = this.hideMobileMenu.bind(this)
        this.showMobileMenu = this.showMobileMenu.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this))
        const {
            lpData: { id },
            campaignAction: { getCampaignData },
        } = this.props
        if (id) {
            getCampaignData(id)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this))
    }

    handleScroll() {
        const { scrollY } = window
        const isScrollUp = this._scrollY > scrollY

        let topBarClass = ''
        if (scrollY <= 250) {
            topBarClass = 'top-header--initial'
        } else if (isScrollUp && scrollY > 250) {
            topBarClass = 'top-header--fixed'
        } else if (!isScrollUp && scrollY > 250) {
            topBarClass = 'top-header--hidden'
        }
        this.setState({
            topBarClass,
        })

        this._scrollY = scrollY
    }

    showMobileMenu() {
        const {
            menu: {
                mobile: { visible },
            },
        } = this.props

        return visible
    }

    showNewBanner() {
        const {
            lpData: { banner },
        } = this.props

        return !this.showCampaignBanner() && banner && banner.new
    }

    hideMobileMenu() {
        const {
            menuAction: { toggleMobileMenu },
        } = this.props

        return toggleMobileMenu(false)
    }

    showCampaignBanner() {
        const {
            lpData: { activeCampaign },
        } = this.props

        return _.isObject(activeCampaign)
    }

    render() {
        const { lpData, links } = this.props
        const config = getConfig(lpData.locale)

        const { topBarClass } = this.state

        const topHeaderClass = [topBarClass]
        if (topBarClass !== 'top-header--initial') {
            topHeaderClass.push(`top-header--${lpData.topHeader}`)
        }

        return (
            <header
                className={`header top-header ${topHeaderClass
                    .reverse()
                    .join(' ')}`}
            >
                <CookieBanner locale={lpData.locale} />
                {/* if a campaign is setup in the data file, show campaign banner */}
                {this.showCampaignBanner() && <CampaignBanner />}

                {/* if no campaign is active and the new banner
                    is configured, show the new banner */}
                {this.showNewBanner() && <NewBanner data={lpData.banner.new} />}

                <section className="top-header__content">
                    <TopNavigation
                        lpData={lpData}
                        links={links}
                        config={config}
                    />
                    <TopMenuDrawer data={lpData} />
                    <TopMenuDrawerMenu
                        lpData={lpData}
                        links={links}
                        show={this.showMobileMenu}
                        closeFn={this.hideMobileMenu}
                        config={config}
                    />
                </section>
            </header>
        )
    }
}

TopHeader.propTypes = {
    links: PropTypes.object.isRequired,
    lpData: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
    menuAction: PropTypes.object.isRequired,
    campaignAction: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        links: state.links,
        lpData: state.lpData,
        menu: state.menu,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        menuAction: bindActionCreators(menuAction, dispatch),
        campaignAction: bindActionCreators(lpDataAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader)
