import React from 'react'

import influencerData from '../../data-influencer/harrison2'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const Harrison2 = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default Harrison2
