const name = 'lisa'
const id = 10
const programId = 297

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Lisa Del Piero - BootybyLisa',
        meta: {
            description:
                'Lisa Del Piero bringt deinen Po in 10 Wochen ordentlich zum brennen und formt dabei noch eine tolle Sanduhrfigur.',
            keywords:
                'bestform, gesund, lisa del piero, booty, training, po, HIIT, sanduhrfigur, straffen',
            'og:title': '#BootybyLisa - In 10 Wochen zum kräftigen Po',
            'og:url': 'https://bootybylisa.gymondo.fitness/',
            'og:description':
                'Lisa Del Piero bringt deinen Po in 10 Wochen ordentlich zum brennen und formt dabei noch eine tolle Sanduhrfigur.',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-lisa',

    logo: 'logos/Lisa',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Gratis Woche starten',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/297_480.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa/lp/usp/usp1.jpg',
                    title: 'Booty Training mit Lisa Del Piero',
                    text:
                        'Wenn du dir den perfekten Po wünscht, ist #BootyByLisa dein Programm. Lisa hat gemeinsam mit Faye nur die besten Übungen und effektive Kombinationen in die Workouts gepackt, die ihren Booty so fit gemacht haben. Das kannst du jetzt auch haben!',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa/lp/usp/usp2.jpg',
                    title: 'Viel Abwechslung, ganz ohne Equipment!',
                    text:
                        'Mit Lisa und Faye wird es nie langweilig! Jedes Workout ist ein bisschen anders aufgebaut und kommt dabei ohne Geräte aus. Wenn der Booty noch ein wenig Erholung braucht, wartet ein HIIT oder Bauchtraining auf dich. Du wirst deinen gesamten Körper formen!',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa/lp/usp/usp3-v2.jpg',
                    title: 'Effektive Workouts von nur 25 Minuten',
                    text:
                        'Die Übungen sind so kombiniert, dass du immer das Maximum rausholen kannst! In nur 25 Minuten deckst du alles ab, was du brauchst, um ein tolles Ergebnis zu bekommen. Hol dir deine Traumfigur.',
                },
            ],
        },
    ],

    why: {
        title:
            'Zusammen bringen wir deinen Po in Topform mit intensiven Booty Workouts',
        paragraphs: [
            'In 10 Wochen bringt Lisa nicht nur deinen Po in Bestform, sondern direkt deinen ganzen Körper. #BootyByLisa ist Training für eine schöne Sanduhrfigur, das von Lisa selbst und Trainerin Faye entwickelt wurde. Der Großteil der Workouts konzentriert sich dabei auf den #Booty. Zwischendurch trainierst du aber auch andere Bereiche deines Körpers, die für eine tolle Figur ausschlaggebend sind. #getstarted',
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa/lp/testimonial/testimonial-mb-v2.jpg',
        quote:
            'Seitdem ich gezielt trainiere, habe ich es geschafft, einen runden Po und eine straffe Figur zu bekommen. Wichtig ist, dass man dranbleibt und ich bin sicher, dass wir das gemeinsam schaffen werden.',
        author: 'Lisa Del Piero',
    },

    iconGrid: {
        title: 'Diese Ziele wirst du erreichen',
        content: [
            {
                icon: 'Slim',
                title: 'Figur straffen',
                text: 'Mit BootyByLisa bringst du besonders deinen Po in Form.',
            },
            {
                icon: 'Strong',
                title: 'Kräftige deine Muskulatur',
                text:
                    'Kräftigende Po- aber auch Ganzkörperübungen sorgen für den Traumbody: Komplett ohne Equipment.',
            },
            {
                icon: 'Fit',
                title: 'Steigere deine Fitness',
                text:
                    'Mehr Power im Alltag! Du wirst deine Fitness nach und nach steigern.',
            },
            {
                icon: 'Challenge',
                title: 'Herausforderungen meistern',
                text:
                    'Lisa und Faye begleiten dich durch jedes Workout und haben immer mindestens eine kleine Challenge für dich dabei.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa/lp/device/devices.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/lisa/lp/device/devices-mb.jpg',
    },

    packageUsp: {
        title: 'Das Komplett-Paket für deine Traumfigur',
        text: [
            {
                title: 'Einfach und intensiv',
                text:
                    'Keine komplizierten Übungen. Du trainierst auf deinem Level in deinem Tempo',
            },
            {
                title: 'Wenig Aufwand',
                text:
                    'Nur dreimal pro Woche 20 min Training lässt sich in jeden Alltag integrieren',
            },
            {
                title: 'Trainiere wann und wo du willst',
                text:
                    'Mit der mobilen App oder am Smart TV einfach überall trainieren',
            },
            {
                title: 'Kein Fitnessstudio notwendig',
                text: 'Für das Training genügt eine Matte und etwas Platz',
            },
            {
                title: 'In Top Form wie Lisa',
                text:
                    'Du trainierst für eine schöne Figur und straffst selbst hartnäckige Problemzonen',
            },
        ],
        cta: {
            text: '7 Tage kostenlos trainieren',
        },
    },
    /* eslint-enable max-len */
})
