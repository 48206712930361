import React from 'react'

import influencerData from '../../data-influencer/get-in-shape'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const GetInShape = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default GetInShape
