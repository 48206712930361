import React from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import PropTypes from 'prop-types'

import ModalStyles from '../../config/modal-styles'
import BtnRev from '../cta/BtnRev'

const UserLoginModal = ({ login, open, onClose, texts }) => {
    const { classNames, closeIconSize } = ModalStyles

    return (
        <Modal
            classNames={classNames}
            open={open}
            onClose={onClose}
            center
            closeIconSize={closeIconSize}
        >
            <h3 className="gym-modal__title">{texts.title}</h3>

            <div className="gym-modal__bd">
                <p className="gym-modal__text">{texts.body}</p>
            </div>

            <div className="gym-modal__actions">
                <BtnRev
                    className="btn-rev--tertiary"
                    text={texts.ctaLeft}
                    isATag
                    href={login.sot}
                />

                <BtnRev
                    className="btn-rev--secondary"
                    text={texts.ctaRight}
                    isATag
                    href={login.gymondo}
                />
            </div>
        </Modal>
    )
}

UserLoginModal.propTypes = {
    login: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    texts: PropTypes.object.isRequired,
}

export default UserLoginModal
