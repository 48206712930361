import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import BreakPointsEnum from '../../config/css-breakpoints'
import BtnRevWithSignupLink from '../cta/BtnRevWithSignupLink'
import { TopMenuDrawer } from '..'

const TopMenuDrawerMenu = ({ closeFn, links, show, lpData, config }) => (
    <MediaQuery maxWidth={BreakPointsEnum.SM}>
        {isXSResolution => (
            <nav
                className={`menu-drawer ${
                    show() && isXSResolution ? 'menu-drawer--visible' : ''
                }`}
            >
                {' '}
                {/* eslint-disable-line */}
                <div className="menu-drawer__menu">
                    <ul className="menu-drawer__list top-header--white">
                        <li className="menu-drawer__item menu-drawer__item__internal">
                            <TopMenuDrawer data={lpData} modal />
                        </li>

                        <li className="menu-drawer__item">
                            <span>{config.home}</span>
                        </li>

                        <li className="menu-drawer__item">
                            <a
                                href={links.signup}
                                onClick={closeFn}
                                title={config.signup}
                            >
                                {config.signup}
                            </a>
                        </li>

                        <li className="menu-drawer__item">
                            <a
                                href={config.instructions.url}
                                title={config.instructions.label}
                            >
                                {config.instructions.label}
                            </a>
                        </li>
                    </ul>
                    <div className="menu-drawer__secundary">
                        <BtnRevWithSignupLink
                            className="btn-rev--secondary btn--small"
                            text={
                                (lpData.header && lpData.header.btnGratis) ||
                                config.button
                            }
                            wrapper={false}
                        />
                    </div>
                </div>
            </nav>
        )}
    </MediaQuery>
)

TopMenuDrawerMenu.defaultProps = {
    show: null,
    lpData: {},
}

TopMenuDrawerMenu.propTypes = {
    closeFn: PropTypes.func.isRequired,
    links: PropTypes.object.isRequired,
    show: PropTypes.func,
    lpData: PropTypes.object,
    config: PropTypes.object.isRequired,
}

export default TopMenuDrawerMenu
