import React from 'react'

import influencerData from '../../data-influencer/mai-shorties'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const MaiShorties = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default MaiShorties
