import Adrienne from './segments/adrienne'
import Cathy from './segments/cathy'
import DanielAminati from './segments/daniel-aminati'
import DanielAminatiMTC from './segments/daniel-aminati-mtc'
import IvanaSantacruz from './segments/ivana-santacruz'
import SOTRoutes from './segments/sot'
import GetStrongRoutes from './segments/get-strong'
import GetInShapeRoutes from './segments/get-in-shape'
import PaolaRoutes from './segments/paola'
import HarrisonRoutes from './segments/harrison'
import Harrison2Routes from './segments/harrison2'
import LisaRoutes from './segments/lisa'
import UrsulaRoutes from './segments/ursula'
import AndreaRoutes from './segments/andrea'
import LabrantRoutes from './segments/labrant'
import VanessaRoutes from './segments/vanessa'
import Lisa2Routes from './segments/lisa2'
import MaiShape from './segments/mai-shape'
import HealthyInsideOut from './segments/healthyinsideout'
import TotalBody from './segments/total-body'
import Shape360 from './segments/shape-360'
import PureFlow from './segments/pure-flow'
import DanceWorks from './segments/dance-works'
import StrongMindBody from './segments/strong-mind-body'
import MaiShorties from './segments/mai-shorties'
import Power from './segments/power'

export default [].concat(
    Adrienne,
    Cathy,
    DanielAminati,
    DanielAminatiMTC,
    SOTRoutes,
    IvanaSantacruz,
    GetStrongRoutes,
    GetInShapeRoutes,
    PaolaRoutes,
    HarrisonRoutes,
    Harrison2Routes,
    LisaRoutes,
    UrsulaRoutes,
    AndreaRoutes,
    LabrantRoutes,
    VanessaRoutes,
    Lisa2Routes,
    MaiShape,
    HealthyInsideOut,
    TotalBody,
    Shape360,
    PureFlow,
    DanceWorks,
    StrongMindBody,
    MaiShorties,
    Power
)
