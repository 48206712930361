import React from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import PropTypes from 'prop-types'

import ModalStyles from '../../config/modal-styles'
import VideoPlayer from '../media/VideoPlayer'

const VideoModal = ({ open, onClose, url, isIframe }) => {
    const { classNames, closeIconSize } = ModalStyles
    const classNamesVideo = {
        modal: `${classNames.modal} preview-modal`,
    }

    return (
        <Modal
            classNames={classNamesVideo}
            open={open}
            onClose={onClose}
            center
            closeIconSize={closeIconSize}
        >
            <VideoPlayer
                className="preview__video"
                url={url}
                isIframe={isIframe}
            />
        </Modal>
    )
}

VideoModal.defaultProps = {
    isIframe: false,
}

VideoModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    isIframe: PropTypes.bool,
}

export default VideoModal
