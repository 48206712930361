import URLS from '../config/urls'

const id = 3
const name = 'mdk'
const programId = 266

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'mach dich krass - 8-Wochen-Programm von Daniel Aminati',
        meta: {
            description:
                'Dein Traumbody jetzt! Mit Daniels Programm wirst du in nur 8 Wochen schlank durch gesunde, saubere Ernährung, kraftvoll & definiert durch Ganzkörpertraining und krass motiviert durch Daniel',
            keywords:
                'abnehmen, gesund, schlank, daniel aminati, bodyweight, muskeln aufbauen, fit, gesund abnehmen',
            'og:title':
                'mach dich krass - 8-Wochen-Programm von Daniel Aminati',
            'og:url': 'https://www.machdichkrass.de/',
            'og:description':
                'Dein Traumbody jetzt! Mit Daniels Programm wirst du in nur 8 Wochen schlank durch gesunde, saubere Ernährung, kraftvoll & definiert durch Ganzkörpertraining und krass motiviert durch Daniel',
        },
        favicon: 'favicon-mdk.ico',
    },

    bodyClass: 'mlp-mdk',

    voucherPattern: '[a-zA-Z0-9-]+',

    topHeader: 'white ',

    influencerLogo: {
        svg: 'logos/DanielAminati',
        url: '/mdk',
        className: 'mdk',
    },

    loginModal: true,

    header: {
        img: `${process.env.PUBLIC_URL}/static/img/daniel-aminati/header/header.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/daniel-aminati/header/header-mb.jpg`,
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },

        login: {
            gymondo: `${URLS.GYM_BASE_URL}/de/checkout/?login=true&channel=${name}&channel_id=${id}`,
            sot: 'https://classic.machdichkrass.de/login',
        },
    },

    modals: {
        login: {
            title: "Hier geht's zu deinem Program!",
            body:
                'Die mach dich krass Website hat nicht nur ein anderes Design, seit dem 01.11.2018 gibt es das Programm auch "powered by Gymondo". Damit wir Dich zum richtigen Login für Dich weiterleiten können, wähle bitte aus, ob du das Programm vor dem 01.11.2018 erworben hast oder danach.',
            ctaLeft: 'Vor dem 01.11.2018',
            ctaRight: 'Ab dem 01.11.2018',
        },
    },

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    title: {
        text: 'Mach Dich Krass',
        font: 'avenir-heavy',
    },

    subtitle: {
        text: 'by Daniel Aminati',
    },

    banner: {
        new: {
            splash: 'Jetzt neu:',
            text:
                'Jederzeit & überall mit der mobilen App und auf deinem Smart TV trainieren',
            font: 'avenir-heavy',
        },
    },

    usp: [
        {
            id: 0,
            font: 'avenir-heavy',
            align: 'left',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/usp/usp-0-3.jpg',
                    title: 'Effektives Training',
                    text: [
                        'Das Komplettsystem für dich! Schritt für Schritt zu deinem Traumkörper',
                        'Perfekt für Männer und Frauen, für Anfänger und Fortgeschrittene: Effektives Krafttraining für zuhause oder unterwegs',
                        'Dauerhafter Erfolg: Nach dem 8-Wochenprogramm begleite ich dich weiterhin mit neuen Übungen und Workouts für ein ganzes Jahr',
                    ],
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/usp/usp-0-4.jpg',
                    title: 'Gesunde Ernährung',
                    text: [
                        'Detaillierter Ernährungsguide als PDF zum Download',
                        'Über 100 Iss-Dich-Schlank-Rezepte',
                    ],
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/usp/usp-0-5.jpg',
                    title: 'Motivation',
                    text: [
                        'Ich zeige dir den Weg und motiviere dich jede Woche neu',
                        'In der Gymondo Facebook-Community wirst du täglich motiviert, erhältst Tipps, Tricks & Rezepte und tauschst dich mit Mitstreiter*innen aus',
                    ],
                },
            ],
        },
    ],

    why: {
        title:
            'Willkommen in deinem neuen Leben! Abnehmen & besser fühlen in nur 8 Wochen',
        paragraphs: [
            'Maximale Fettverbrennung, schlanker athletischer Körperbau, fitteres und gesünderes Leben:',
            'Durch funktionales Ganzkörpertraining wirst du kraftvoller und definierter. Einfache Schritt für Schritt-Anleitung bringen dich zum Erfolg - du kannst sofort loslegen bei dir zuhause!',
            'Und damit du am Ball bleibst - werde ich dich mit meinem Experten-Team und der Community krass motivieren!',
        ],
    },

    successStories: {
        title: 'Zehntausende haben es schon geschafft!',

        // no translation needed here for now, since it's just
        // a name and a number
        content: [
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/success/success-0.jpg',
                text: {
                    title: 'Christian',
                    font: 'avenir-heavy',
                    text: '-10 kg',
                },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/success/success-1.jpg',
                text: { title: 'Lena', font: 'avenir-heavy', text: '-7 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/success/success-2.jpg',
                text: { title: 'Can', font: 'avenir-heavy', text: '-10 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/success/success-3.jpg',
                text: { title: 'Philipp', font: 'avenir-heavy', text: '-8 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/success/success-4.jpg',
                text: { title: 'Andrea', font: 'avenir-heavy', text: '-3 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/success/success-5.jpg',
                text: { title: 'Tobias', font: 'avenir-heavy', text: '-27 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/success/success-6.jpg',
                text: { title: 'Ivonne', font: 'avenir-heavy', text: '-5 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/success/success-7.jpg',
                text: { title: 'Marc', font: 'avenir-heavy', text: '-11 kg' },
            },
            {
                img:
                    '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/success/success-8.jpg',
                text: { title: 'Florian', font: 'avenir-heavy', text: '-8 kg' },
            },
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/testimonial/testimonial-0.jpg',
        quote:
            'Du bestimmst deinen Weg! Du bist für deinen Erfolg verantwortlich. Go for it!',
        author: 'Daniel Aminati',
    },

    iconGrid: {
        title: 'Diese Ziele wirst du erreichen',
        font: 'avenir-heavy',
        content: [
            {
                icon: 'FireWithBg',
                title: 'Fett verbrennen',
                text:
                    'Du lernst alle Basics rund um Training und Ernährung kennen und legst den Grundstein für ein gesünderes Leben',
            },
            {
                icon: 'ArmStrongWithBg',
                title: 'Kraft aufbauen',
                text:
                    'Schritt für Schritt zum Traumbody: Forderndes Bodyweight-Training mit steigernder Belastung powert dich aus und macht dich stark',
            },
            {
                icon: 'HeartsWithBg',
                title: 'Gesund sein',
                text:
                    'Ernährung, Bewegung, Motivation: Du wirst dich einfach besser fühlen!',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        newBadge: 'Neu',
        font: 'avenir-heavy',
        img: `${process.env.PUBLIC_URL}/static/img/daniel-aminati/device/devices.jpg`,
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/mdk/lp/devices/devices-mobile.jpg',
    },

    packageUsp: {
        title: 'Mein Komplettpaket für deine Traumfigur',
        text: [
            {
                title: 'Einfach und verständlich',
                text:
                    'Schritt für Schritt führe ich dich mit wöchentlich neuen Videos durchs Programm.',
            },
            {
                title: 'Wenig Aufwand',
                text:
                    'Nur dreimal die Woche 20 min Training lässt sich in jeden Alltag integrieren.',
            },
            {
                title: 'Komplettes Ernährungskonzept',
                text:
                    'Mit meinen Schlank- und Durchhaltetipps und einfachen und leckeren Rezepten.',
            },
            {
                title: 'Trainiere wann und wo du willst',
                text:
                    'Mit der mobilen App oder am Smart TV einfach überall trainieren.',
            },
            {
                title: 'Kein Fitnessstudio notwendig',
                text: 'Für das Training zu Hause genügt eine Gymnastikmatte.',
            },
            {
                title: 'Nie wieder Problemzonen',
                text:
                    'Mit meinen Fokus-Trainings sagst du hartnäckigen Problemzonen den Kampf an.',
            },
        ],
        cta: {
            text: '7 Tage kostenlos trainieren',
        },
    },
    /* eslint-enable max-len */
})
