import React from 'react'
import PropTypes from 'prop-types'

import { BtnRevWithSignupLink } from '..'
import HeaderLogo from './logos/HeaderLogo'
import LoginButton from '../buttons/LoginButton'
import MenuDrawerButton from './MenuDrawerButton'

const TopMenuDrawer = ({ data, modal }) => (
    <nav className="menu-drawer__ctrls">
        <HeaderLogo data={data.influencerLogo} />

        <ul className="menu-drawer-actions">
            {!modal && (
                <li className="menu-drawer-actions__item">
                    <BtnRevWithSignupLink
                        className="btn-rev--secondary btn--small"
                        text={
                            (data.header && data.header.btnGratis) ||
                            'Gratis Woche starten'
                        }
                    />
                </li>
            )}

            <li className="menu-drawer-actions__item menu-drawer-actions__item--modal">
                <LoginButton showModal={data.loginModal} data={data} />
            </li>

            <li className="menu-drawer-actions__item">
                <MenuDrawerButton modal={modal} />
            </li>
        </ul>
    </nav>
)

TopMenuDrawer.defaultProps = {
    modal: false,
}

TopMenuDrawer.propTypes = {
    data: PropTypes.object.isRequired,
    modal: PropTypes.bool,
}

export default TopMenuDrawer
