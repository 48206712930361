import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isEU } from '@gymondo/frontend-core/utils/intl'

import * as cookieAction from '../../store/actions/cookies'
import CookieText from './CookieText'
import CloseIcon from '../../images/svg/CloseIcon'

import COOKIE_NAMES from '../../config/cookie-names'

class CookieBanner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            accepted: true,
        }

        this.acceptCookies = this.acceptCookies.bind(this)
    }

    // eslint-disable-next-line react/no-deprecated
    UNSAFE_componentWillMount() {
        const { cookieActions } = this.props
        if (!Cookies.get(COOKIE_NAMES.CONSENT) && !isEU()) {
            this.setState({ accepted: false })
            cookieActions.acceptCookies(false)
        } else {
            cookieActions.acceptCookies(true)
        }
    }

    acceptCookies() {
        const { cookieActions } = this.props
        this.setState({ accepted: true })
        cookieActions.acceptCookies(true)
        Cookies.set(COOKIE_NAMES.CONSENT, true)
    }

    render() {
        const { accepted } = this.state
        const { locale } = this.props

        if (accepted) {
            return false
        }

        return (
            <section className="notice">
                <div className="notice__content">
                    <div className="notice__text">
                        <CookieText locale={locale} />
                    </div>

                    <span
                        className="notice__close"
                        onClick={this.acceptCookies}
                    >
                        <CloseIcon />
                    </span>
                </div>
            </section>
        )
    }
}

CookieBanner.defaultProps = {
    locale: 'de',
}

CookieBanner.propTypes = {
    cookieActions: PropTypes.object.isRequired,
    locale: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        cookies: state.cookies,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        cookieActions: bindActionCreators(cookieAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieBanner)
