const name = 'labrant'
const id = 13
const programId = 323

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'LaBrant Fit | Gymondo® Online Fitness',
        meta: {
            description:
                'Work out with Savannah & Cole LaBrant. Introducing LaBrant Fit, these 20-minute bodyweight workouts can be done from home or on-the-go. In just 8 weeks, you’ll blast unwanted fat & keep your heart rate pumping with this fun-filled full-body LaBrant Fit workout program.',
            keywords:
                'LaBrant Fit, LaBrant workouts, The LaBrants, Savannah & Cole LaBrant, 20-minute home workouts, home workouts, 8-week fitness program, bodyweight workouts, 20-minute bodyweight workouts, full-body workout , gym-free workouts, equipment-free workout',
            'og:title': 'LaBrant Fit | Gymondo® Online Fitness',
            'og:url': 'https://labrantfit.gymondo.fitness/',
            'og:description':
                'Work out with Savannah & Cole LaBrant. Introducing LaBrant Fit, these 20-minute bodyweight workouts can be done from home or on-the-go. In just 8 weeks, you’ll blast unwanted fat & keep your heart rate pumping with this fun-filled full-body LaBrant Fit workout program.',
        },
        favicon: 'favicon.ico',
    },

    locale: 'en',

    bodyClass: 'mlp-labrant',

    logo: 'logos/Labrant',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Register',
        btnLogin: 'Log in',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/323_480.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    activeCampaign: {
        desktopTitle: '',
    },

    title: {},

    cta: {
        text: {
            general: 'Start Free Trial',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/labrant/lp/usp/usp1.jpg',
                    title: 'Workouts for the Busy',
                    text:
                        'In just 20 minutes, target every muscle in your body with emphasis on your abs and legs. No equipment necessary and variations to suit every fitness level—including ‘no jumping’ options.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/labrant/lp/usp/usp2.jpg',
                    title: 'LaBrant Fun',
                    text:
                        'Who says working out is boring? Training with Cole, Savannah and their trainer, Katie, isn’t only effective but will keep you smiling. Have fun with the LaBrants, who will work out and sweat with you while Coach Katie dishes out helpful training tips.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/labrant/lp/usp/usp3.jpg',
                    title: 'Feel Great',
                    text:
                        "These short, effective full-body workouts will allow you to dedicate more time to you. By training consistently with Cole, Savannah and Katie, you'll feel amazing and reap the benefits of your hard work.",
                },
            ],
        },
    ],

    why: {
        title: 'Fun 20-Minute Home Workouts!',
        paragraphs: [
            "It’s easy to forget to set aside some 'me' time when you’re busy raising a family. Just ask Cole and Savannah LaBrant. Staying fit helps them juggle their busy family schedule while feeling great, both physically and mentally. The LaBrants and their trainer and friend, Katie Yovin, have created an 8-week program—perfect for even the busiest of schedules. Join them 3 times each week for short, 20-minute workouts that can be done using your own body weight from the comfort of your home (or on-the-go). No matter where you’re at in your fitness journey, there are easy variations to each exercise. Savannah will take you through a modified version while the more advanced variations are performed by Cole. Join the LaBrants and Get Fit & Feel Happy. Start now!",
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/labrant/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/labrant/lp/testimonial/testimonial-mb.jpg',
        quote:
            'With LaBrant Fit, we are so excited! We want you to grow into it, have a lot of fun and see results as time goes on. So push your body, sweat with us and have a great time.',
        author: 'Cole & Savannah LaBrant',
    },

    iconGrid: {
        title: 'You will achieve these goals',
        content: [
            {
                icon: 'Slim',
                title: 'Tone Up',
                text:
                    "With LaBrant Fit, you'll build toned, shapely muscles to feel more confident.",
            },
            {
                icon: 'Strong',
                title: 'Strengthen Your Muscles',
                text:
                    'Strong muscles boost metabolism, burn calories and decrease the risk of injury.',
            },
            {
                icon: 'Fit',
                title: 'Increase Your Endurance',
                text:
                    "Improve cardiovascular fitness—time flies when you're having fun!",
            },
            {
                icon: 'Challenge',
                title: 'Master Challenges',
                text:
                    'Cole, Savannah and Katie accompany you through every workout and always have a small challenge for you. Ready to face the challenge?',
            },
        ],
    },

    deviceList: {
        title: 'Unlock your LaBrant Fit all-access membership.',
        subtitle:
            ' Get access to LaBrant Fit and 20+ additional fitness programs from Yoga to HIIT. Available on Web, iOS, Android, Apple TV, Android TV and Amazon Fire TV.',
        img: `${process.env.PUBLIC_URL}/static/img/labrantfit/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/labrantfit/devices_mobile.jpg`,
    },

    packageUsp: {
        title: 'The complete package for LaBrant Fit and 20+ programs',
        text: [
            {
                title: 'Access 20+ additional fitness programs',
                text:
                    '250+ motivating workouts are included in your membership, from Yoga to HIIT.',
            },
            {
                title: 'Transform your body in less than 20 minutes',
                text:
                    'Training can be integrated into everyday life in as little as 3 times per week.',
            },
            {
                title: 'Train when and where you want',
                text:
                    'With the mobile app or on Smart TV, you can train anywhere, even offline.',
            },
            {
                title: 'No gym necessary',
                text: 'A mat and some space are sufficient for training.',
            },
            {
                title: 'Get LaBrant Fit with Cole and Savannah LaBrant',
                text: 'Train to feel confident, both inside and out.',
            },
            {
                title: "Workouts you'll look forward to...",
            },
        ],
        cta: {
            text: 'Start Free Trial',
        },
    },
    /* eslint-enable max-len */
})
