const name = 'ursula'
const id = 11
const programId = 269

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Ursula Karven - Mach dich leicht',
        meta: {
            description:
                'Mit Ursula Karven und ihrem Programm MACH DICH LEICHT zum Yoga-Profi.',
            keywords:
                'yoga, ursula karven, training, leicht, stark, mobilität, flexibilität, dehnung, körperbewusst, gesund, wohlfühlen',
            'og:title': 'Ursula Karven - Mach dich leicht',
            'og:url': 'https://www.machdichleicht.de',
            'og:description':
                'Mit Ursula Karven und ihrem Programm MACH DICH LEICHT zum Yoga-Profi.',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-ursula',

    logo: 'logos/Ursula',

    topHeader: 'white ',

    loginModal: false,

    header: {
        btnGratis: 'Gratis Woche starten',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/269_480.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ursula/lp/usp/usp1.jpg',
                    title: 'Yoga mit Ursula Karven',
                    text:
                        'Ursula Karven begleitet dich als deine persönliche Yogalehrerin in jeder Sequenz. Die Flows wirken entspannend auf Körper und Geist.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ursula/lp/usp/usp2.jpg',
                    title: 'Mehr Leichtigkeit',
                    text:
                        'Freu dich auf wohltuendes Yoga für mehr Leichtigkeit im Alltag! Verbessere in nur 10 Wochen deine Beweglichkeit, Kraft und Koordination.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ursula/lp/usp/usp3.jpg',
                    title: 'Motivation',
                    text:
                        'Mit Ursula bleibst du motiviert, denn ihre natürliche und entspannte Art lässt dich dich immer wieder auf die Yoga Matte zurückkehren.',
                },
            ],
        },
    ],

    why: {
        title:
            'Willkommen in deinem neuen Leben! Vitaler & leichter fühlen in nur 10 Wochen',
        paragraphs: [
            "Dein 10 Wochen Yoga Programm mit Ursula Karven. Mach dich leicht und fühl' dich einfach nur wunderbar nach den Yoga Einheiten. Neben deiner Beweglichkeit wirst du auch deine Kraft und deine Koordination verbessern.",
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ursula/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ursula/lp/testimonial/testimonial-mb.jpg',
        quote:
            'Yoga kann soviel Positives bewirken. Ich zeige dir, wie einfach es ist, dich leichter zu machen und einfach besser zu fühlen.',
        author: 'Ursula Karven',
    },

    iconGrid: {
        title: 'Diese Ziele wirst du erreichen',
        content: [
            {
                icon: 'Fit',
                title: 'Wohlfühlen',
                text:
                    'Yoga tut einfach gut - körperlich und mental. Woche für Woche wirst du dich immer wohler fühlen.',
            },
            {
                icon: 'CardioHeart',
                title: 'Abnehmen',
                text:
                    'Stress ist ein Dickmacher. Mach dich leicht reduziert Stresshormone und bringt dich mit kraftvollen Yogaübungen deinem Wohlfühlgewicht näher.',
            },
            {
                icon: 'Yoga',
                title: 'Entspannung',
                text:
                    'Yoga entspannt Körper und Geist - lass dich von Ursula Karven auf die Reise zu einem entspannteren Ich mitnehmen.',
            },
            {
                icon: 'Balance',
                title: 'Stressabbau',
                text:
                    'Besonders, wenn du in deinem Job stark gefordert bist, ist Stress ein Thema. Yoga wirkt ausgleichend und reduziert Stress.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ursula/lp/device/devices.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/ursula/lp/device/devices-mb.jpg',
    },

    packageUsp: {
        title: 'Mein Komplett-Paket für deine Wohlfühl-Figur',
        text: [
            {
                title: 'Einfache Übungen',
                text:
                    'Keine Vorkenntnisse nötig! Die Yoga Einheiten sind für Einsteiger wunderbar geeignet.',
            },
            {
                title: 'Wenig Aufwand',
                text:
                    'Mach dich leicht passt in jeden Alltag. Du brauchst nur wenig Zeit - max. 15 min am Tag!',
            },
            {
                title: 'Trainiere wann und wo du willst',
                text:
                    'Mit der mobilen App oder am Smart TV einfach überall Yoga machen.',
            },
            {
                title: 'Kein weiteres Equipment',
                text: 'Für das Training zuhause genügt eine Gymnastikmatte',
            },
        ],
        cta: {
            text: '7 Tage kostenlos trainieren',
        },
    },
    /* eslint-enable max-len */
})
