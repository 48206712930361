const id = 16
const name = 'vanessa'
const programId = 337

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Mai Fit by Vanessa Mai',
        meta: {
            description:
                'Bring deine Fitness mit Vanessa Mai auf das nächste Level!',
            keywords:
                'vanessa mai, training, personal training, fitness, fitnessprogramm, fitness programm, fitness coach, körper straffen',
            'og:title': 'Mai Fit by Vanessa Mai',
            'og:url': 'https://maifit.gymondo.fitness/',
            'og:description':
                'Bring deine Fitness mit Vanessa Mai auf das nächste Level!',
        },
    },

    bodyClass: 'mlp-vanessa',

    logo: 'logos/Vanessa',
    header: {
        btnGratis: 'Gratis Woche starten',
        preview:
            'https://akgymondo.akamaized.net/prgpreviews/RbRBJaC3G16MY4YIiVutV_360.mp4',
    },

    topHeader: 'white ',

    title: {},

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/vanessa/lp/usp/usp-1.jpeg',
                    title: 'Stärke und straffe deine Muskulatur',
                    text:
                        'Du brauchst nur 25 Minuten, um deinen gesamten Körper mit effektiven Workouts ganz ohne Geräte zu kräftigen und zu straffen.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/vanessa/lp/usp/usp-2.jpeg',
                    title: 'Verbrenne ungewolltes Körperfett',
                    text:
                        'Vanessa bringt mit abwechslungsreichen Übungen deinen Puls zum Rasen und dein Körperfett zum Schmelzen.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/vanessa/lp/usp/usp-3.jpeg',
                    title: 'Beeindruckende Resultate in kürzester Zeit',
                    text:
                        'Zielorientiertes Training sorgt mit minimalem Zeiteinsatz für maximale Resultate. Du wirst beeindruckt sein!',
                },
            ],
        },
    ],

    why: {
        title: 'Bring deine Fitness mit Vanessa Mai auf das nächste Level!',
        paragraphs: [
            'Mai Fit mit Vanessa Mai bringt deine Fitness auf das nächste Level! Transformiere deinen Körper mit 10 einzigartigen Workouts, die deine Kraft, Beweglichkeit und Ausdauer auf die Probe stellen und deinen Puls in die Höhe treiben. Verbrenne Fett und straffe deinen gesamten Körper mit gezieltem Training für Core, Arme, Beine und Po und teste deine Limits mit anspruchsvollen Bodyweight-Workouts. Keine Ausreden - nur voller Einsatz und ganz viel Spaß!',
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/vanessa/lp/testimonial/testimonial.jpeg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/vanessa/lp/testimonial/testimonial-mb.jpeg',
        quote: 'It’s All About Balance',
        author: 'Vanessa Mai',
    },

    iconGrid: {
        title: 'Mit Mai Fit erzielst du langfristige Resultate',
        content: [
            {
                icon: 'Slim',
                title: 'Straffe deinen Körper',
                text:
                    'Erreiche einen straffen, definierten Body und fühl dich besser als je zuvor!',
            },
            {
                icon: 'Strong',
                title: 'Steigere deine Kraft',
                text:
                    'Starke, durchtrainierte Muskulatur sorgt für einen echten Stoffwechsel-Boost, verbrennt Kalorien und senkt das Verletzungsrisiko.',
            },
            {
                icon: 'Fit',
                title: 'Verbessere deine Ausdauer',
                text:
                    'Effektives Cardiotraining steigert deine kardiovaskuläre Ausdauer und deine Leistungsfähigkeit, sodass du einfach noch mehr erreichen kannst.',
            },
            {
                icon: 'Challenge',
                title: 'Stell dich der Herausforderung!',
                text:
                    'Vanessa Mai & ihr Trainer David Flacke begleiten dich durch jedes Workout und stellen dich mit motivierenden Challenges auf die Probe. Bist du bereit?',
            },
        ],
    },
    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV.',
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/vanessa/lp/device/devices.jpeg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/vanessa/lp/device/devices-mb.jpeg',
    },

    packageUsp: {
        title: 'Dein Mai Fit-Gesamtpaket',
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text:
                    'Mai Fit ist genau auf deine persönlichen Bedürfnisse und Vorlieben zugeschnitten.',
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'In nur 20 Minuten kommst du deinen individuellen Zielen einen Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame hochauflösende Workout-Videos direkt per Browser, Smart-TV oder Handy - sogar offline!',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause und unterwegs ganz ohne Geräte oder Equipment. Du brauchst dafür nichts als deine Matte.',
            },
            {
                title: 'Werde Mai Fit!',
                text:
                    'Ob Anfänger oder echte Sportskanone - hier findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 Tage kostenlos trainieren',
        },
    },

    /* eslint-enable max-len */
})
