import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as menuAction from '../../store/actions/menu'

import CloseIcon from '../../images/svg/CloseIcon'
import MenuIcon from '../../images/svg/Menu'

class MenuDrawerButton extends Component {
    constructor(props) {
        super(props)

        this.toggleDrawerMenu = this.toggleDrawerMenu.bind(this)
    }

    toggleDrawerMenu() {
        const { menuActions, menu } = this.props
        menuActions.toggleMobileMenu(!menu.mobile.visible)
    }

    render() {
        const { modal } = this.props

        if (modal) {
            return (
                <div
                    className="drawer-btn--close"
                    onClick={this.toggleDrawerMenu}
                >
                    <CloseIcon />
                </div>
            )
        }

        return (
            <div className="drawer-btn" onClick={this.toggleDrawerMenu}>
                <MenuIcon />
            </div>
        )
    }
}

MenuDrawerButton.defaultProps = {
    modal: false,
}

MenuDrawerButton.propTypes = {
    menu: PropTypes.object.isRequired,
    menuActions: PropTypes.object.isRequired,
    modal: PropTypes.bool,
}

function mapStateToProps(state) {
    return {
        menu: state.menu,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        menuActions: bindActionCreators(menuAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuDrawerButton)
