import React from 'react'

import influencerData from '../../data-influencer/strong-mind-body'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const StrongMindBody = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default StrongMindBody
