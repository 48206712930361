const name = 'power'
const id = 328
const programId = 387

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Power by Lola Weippert | Gymondo®',
        meta: {
            description:
                'Entdecke Power by Lola Weippert, Lolas neues Fitnessprogramm. Teste Gymondo jetzt kostenlos & entdecke 16 Workouts, Yoga-Sessions & Meditationen mit Lola.',
            keywords:
                'Power by Lola Weippert, Lola Weippert, Lola Weippert Fitness, Lola Weippert Workouts, Lola Fitness, Lola Weippert Yoga, Lola Gymondo, Home-Workouts, Gymondo Influencer, Yoga mit Lola',
            'og:title': 'Power by Lola Weippert | Gymondo®',
            'og:url': ' https://lola-weippert.gymondo.fitness/power',
            'og:description':
                'Entdecke Power by Lola Weippert, Lolas neues Fitnessprogramm. Teste Gymondo jetzt kostenlos & entdecke 16 Workouts, Yoga-Sessions & Meditationen mit Lola.',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-power',

    logo: 'logos/Power', // pending from design, need svg

    topHeader: 'white',

    loginModal: false,

    header: {
        btnGratis: 'GRATISWOCHE STARTEN',
        btnLogin: 'LOGIN',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/422_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 TAGE GRATIS TESTEN',
        },
    },

    why: {
        title: '16 Workouts, 10 Minuten – Lolas Geheimnis für mehr Power',
        paragraphs: [
            'Stundenlang im Fitnessstudio abrackern war gestern! Lola Weippert zeigt dir, dass ein gutes Workout nicht länger als 10 Minuten dauern muss. Wie das geht? Mit der Extraportion Power! Lolas brandneues Programm ist ein echtes Gesamtpaket, mit dem du jedes deiner Ziele verwirklichen kannst. Dich erwarten effektive Bodyweight-Workouts, entspannende Yoga-Einheiten, bestärkende Meditationen und knackige Übungen für Bauch, Beine und Po – und all das in nur 10 Minuten. Spürst du schon die Power?',
        ],
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img: `${process.env.PUBLIC_URL}/static/img/power/usp/usp-1.jpg`,
                    title: 'Fit in 10 Minuten',
                    text:
                        'Jedes Workout fokussiert sich ohne Umschweife auf ein bestimmtes Ziel – so wirst du noch schneller und effektiver fit.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/power/usp/usp-2.jpg`,
                    title: 'Anfängerfreundliches Training',
                    text:
                        'Lola & Coach Bea zeigen dir Abwandlungen für fast alle Übungen. So kannst du dein Training optimal an deine Bedürfnisse anpassen.',
                },
                {
                    img: `${process.env.PUBLIC_URL}/static/img/power/usp/usp-3.jpg`,
                    title: 'Mix & Match',
                    text:
                        'Besonders motiviert? Dann kombiniere Lolas Workouts! Wie wär’s zum Beispiel mit HIIT, Yoga und einer Meditation zum Entspannen?',
                },
            ],
        },
    ],

    motivation: {
        img: `${process.env.PUBLIC_URL}/static/img/power/testimonial/testimonial.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/power/testimonial/testimonial-mb.jpg`,
        quote:
            '"Bist du bereit, mit mehr Power durchs Leben zu gehen? Dann lass uns gemeinsam durchstarten!"',
        author: 'Lola Weippert',
    },

    iconGrid: {
        title: 'Das erwartet dich in Power by Lola Weippert',
        content: [
            {
                icon: 'Yoga2',
                title: 'Bodyweight-Training',
                text:
                    'Lola und Coach Bea zeigen dir tolle Übungen, die dich von Kopf bis Fuß fit machen. Du brauchst kein Equipment, nur dein eigenes Körpergewicht.',
            },
            {
                icon: 'Fit',
                title: 'Yoga',
                text:
                    'Balance ist alles! Yoga stärkt Körper & Geist und hilft dir dabei, deinen inneren Akku wieder aufzuladen. So geht dir niemals die Power aus.',
            },
            {
                icon: 'Yoga3',
                title: 'Stretching',
                text:
                    'Um nicht nur stark, sondern auch beweglich zu bleiben, solltest du deine Muskulatur unbedingt regelmäßig dehnen – und Lola zeigt dir, wie!',
            },
            {
                icon: 'Yoga',
                title: 'Meditation',
                text:
                    'Niemand kann 24/7 Vollgas geben. Ab und zu musst du innehalten & neue Motivation tanken. Das geht am besten mit Lolas bestärkenden Meditationen.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte sind auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV verfügbar.',
        subtitle: '',
        img: `${process.env.PUBLIC_URL}/static/img/power/device/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/power/device/devices-mb.jpg`,
    },

    packageUsp: {
        title: 'Dein Power by Lola Weippert-Gesamtpaket',
        text: [
            {
                title: 'Personalisierter Trainingsplan',
                text:
                    'Power by Lola Weippert ist perfekt auf deine individuellen Wünsche und Bedürfnisse zugeschnitten.',
            },
            {
                title: 'Schnelle & effektive Workouts',
                text:
                    'Mit Gymondo sparst du Zeit und Geld – und kommst deinen Fitnesszielen zu Hause Schritt für Schritt näher.',
            },
            {
                title: 'Trainiere jederzeit und überall',
                text:
                    'Streame über 1400 hochauflösende Workout-Videos direkt per Smartphone, Web oder Smart-TV – sogar offline.',
            },
            {
                title: 'Kein Fitnessstudio nötig',
                text:
                    'Trainiere zu Hause oder unterwegs ganz ohne Geräte und Equipment – du brauchst nichts als deine Matte.',
            },
            {
                title: 'Fitness für jedes Level',
                text:
                    'Ob Anfänger oder echte Sportskanone – bei uns findest du immer dein perfektes Training.',
            },
        ],
        cta: {
            text: '7 TAGE GRATIS TESTEN',
        },
    },
    /* eslint-enable max-len */
})
