import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import cn from 'classnames'
import BreakPointsEnum from '../config/css-breakpoints'

const LpDeviceList = ({ className, data }) => {
    const { title, subtitle, newBadge, img, imgMobile, font } = data

    return (
        <MediaQuery maxWidth={BreakPointsEnum.XS}>
            {matches => (
                <section className={cn('lp-devices', className)}>
                    {newBadge && (
                        <div className={cn('lp-devices__badge', font)}>
                            {newBadge}
                        </div>
                    )}

                    <h3 className="lp-devices__title mlp-title">{title}</h3>
                    {subtitle && (
                        <h3 className="lp-devices__subtitle">{subtitle}</h3>
                    )}
                    <img
                        className="lp-devices__img"
                        src={matches && imgMobile ? imgMobile : img}
                        alt={title}
                    />
                </section>
            )}
        </MediaQuery>
    )
}

LpDeviceList.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

LpDeviceList.defaultProps = {
    className: '',
}

export default LpDeviceList
