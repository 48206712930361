import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import _ from 'lodash'
import BtnRev from './cta/BtnRev'
import URLS from '../config/urls'

const LpCharity = ({ className, data }) => {
    const text = data.charity.btnCharity || 'JOIN THE MOVEMENT'
    const locale = data.locale || 'de'
    const linkTupels = _.toPairs(data.links.signup)
    const influencerParameters = linkTupels
        .map(tupel => tupel.join('='))
        .join('&')
    const linkParameters = `${influencerParameters}`
    const charityURL = `${URLS.GYM_BASE_URL}/${locale}/checkout/?${linkParameters}`

    return (
        <section className={cn('lp-charity', className)}>
            <div className="lp-charity__content">
                <img
                    className="lp-charity__img"
                    src={data.charity.img}
                    alt="charity"
                />
                <h2 className="lp-charity__title mlp-title">
                    {data.charity.title}
                </h2>
                <p className="lp-charity__text">{data.charity.subtitle}</p>
                <BtnRev
                    className="lp-charity__button btn-rev--primary"
                    isATag
                    href={charityURL}
                    text={text}
                />
                <p className="lp-charity__legal">{data.charity.legal}</p>
            </div>
        </section>
    )
}

LpCharity.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

LpCharity.defaultProps = {
    className: '',
}

export default LpCharity
