import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import DynamicSvgIcon from '../DynamicSvgIcon'

const IconGridItem = ({ className, data, font }) => {
    const { icon, text, title } = data

    return (
        <div className={cn('lp-ig', className)}>
            {icon && <DynamicSvgIcon className="lp-ig__icon" name={icon} />}
            {title && <h4 className={cn('lp-ig__title', font)}>{title}</h4>}
            {text && <p className="lp-ig__text">{text}</p>}
        </div>
    )
}

IconGridItem.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
    font: PropTypes.string,
}

IconGridItem.defaultProps = {
    className: '',
    font: '',
}

export default IconGridItem
