const config = {
    de: {
        text: 'Vorschau',
    },
    en: {
        text: 'Preview',
    },
}

const getConfig = locale => config[locale] || config.de

export default getConfig
