import React from 'react'
import PropTypes from 'prop-types'

import MediaQuery from 'react-responsive'

import BreakPointsEnum from '../../config/css-breakpoints'
import getConfig from './cookie-banner.config'

const CookieText = ({ locale }) => {
    const config = getConfig(locale)

    return (
        <>
            <MediaQuery minWidth={BreakPointsEnum.SM}>
                {config.text}

                <a
                    href={config.link.url}
                    className="notice__link"
                    title={config.link.label}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {config.link.label}
                </a>
            </MediaQuery>
            <MediaQuery maxWidth={BreakPointsEnum.SM}>
                <span>{config.mobileText1}</span>
                <a
                    href={config.link.url}
                    className="notice__link"
                    title={config.link.mobileLabel}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {config.link.mobileLabel}
                </a>
                <span>{config.mobileText2}</span>
            </MediaQuery>
        </>
    )
}

CookieText.propTypes = {
    locale: PropTypes.string,
}

CookieText.defaultProps = {
    locale: 'de',
}

export default CookieText
