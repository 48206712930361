const name = 'harrison'
const id = 9
const programId = 298

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Team Harrison - In 8 Wochen zur Bestform',
        meta: {
            description:
                'Team Harrison bringt dich mit PERFECT SHAPE in 8 Wochen in Bestform',
            keywords:
                'bestform, gesund, team harrison, schlank, cardio intervall, training, fettverbrennung, personal training',
            'og:title': 'Perfect Shape by Team Harrison',
            'og:url': 'https://perfectshape.gymondo.fitness/',
            'og:description':
                'Team Harrison bringt dich mit PERFECT SHAPE in 8 Wochen in Bestform',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-harrison',

    logo: 'logos/Harrison',

    topHeader: 'white ',

    loginModal: false,

    header: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/harrison/lp/header/header.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/harrison/lp/header/header-mb.jpg',
        btnGratis: 'Gratis Woche starten',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/298_360.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/harrison/lp/usp/usp-1.jpg',
                    title: 'Workouts für eine formschöne Figur',
                    text:
                        'In jedem Workout erwarten dich nach dem Cardio Part zwei Runden, die deinen Body straffen. Hierbei deckst du alles ab - es warten effektive Ganzkörper Trainings auf dich, denen du leicht folgen kannst.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/harrison/lp/usp/usp-2.jpg',
                    title: 'Mehr Spaß beim Training',
                    text:
                        'Bestimmt kennst du Team Harrison bereits: Die beiden machen einfach gute Laune! Der Muskelkater im Bauch wird sicherlich nicht nur von den Crunches kommen! Hab Spaß mit Sarah, die mit dir gemeinsam durchzieht, und Coach Dominic, der dir immer wieder tolle Trainings-Tipps geben wird.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/harrison/lp/usp/usp-3.jpg',
                    title: 'Ein positives Körpergefühl',
                    text:
                        'Während der Übungen nimmst du nicht nur deinen Körper intensiv wahr, denn du konzentrierst dich besonders auf die korrekte Ausführung. Verändere durch das regelmäßige Training mit Team Harrison nach und nach deinen Körper zu deinem persönlichen Ideal.',
                },
            ],
        },
    ],

    why: {
        title: 'Workouts für Zuhause, die dich in Bestform bringen!',
        paragraphs: [
            'Du hast das Einsteiger Programm GET IN SHAPE erfolgreich abgeschlossen? Dann wird es jetzt Zeit für PERFECT SHAPE, der ideale Anschluß. Team Harrison verpasst deiner Figur den Feinschliff und bringt dich in deine persönliche Bestform! Die Workouts bieten dir alles, was du für einen fitten Body brauchst: Ein bisschen Cardio zum Schwitzen und immer zwei Zirkelrunden, die deinen ganzen Körper straffen. Ideal, wenn du nicht nur schlank werden, sondern auch straffen willst. Mehr als 20 Minuten an 3 Tagen deiner Wahl brauchst du nicht zu investieren. Starte jetzt!',
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/harrison/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/harrison/lp/testimonial/testimonial-mb.jpg',
        quote:
            'Dominic weiß einfach am besten welches Training für mich funktioniert. Ich hab mich nie wohler in meinem Körper gefühlt.',
        author: 'Sarah Harrison',
    },

    iconGrid: {
        title: 'Diese Ziele wirst du erreichen',
        content: [
            {
                icon: 'Slim',
                title: 'Figur straffen',
                text:
                    'Mit den Workouts von Dominic bringst du dich in Bestform.',
            },
            {
                icon: 'Strong',
                title: 'Kräftige deine Muskulatur',
                text:
                    'Kräftigende Übungen sorgen für den Traumbody: Dein ganzer Körper wird in jedem Workout beansprucht.',
            },
            {
                icon: 'Fit',
                title: 'Mehr Ausdauer',
                text:
                    'In jedem Workout erwartet dich ein kleiner Cardio Part. Schwitzen erlaubt!',
            },
            {
                icon: 'Challenge',
                title: 'Herausforderungen meistern',
                text:
                    'Team Harrison begleitet dich durch jedes Workout und hat immer eine kleine Challenge für dich dabei - stell dich der Herausforderung.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/harrison/lp/device/devices.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/harrison/lp/device/devices-mb.jpg',
    },

    packageUsp: {
        title: 'Das Komplett-Paket für deine Traumfigur',
        text: [
            {
                title: 'Einfach und intensiv',
                text:
                    'Keine komplizierten Übungen. Du trainierst auf deinem Level in deinem Tempo',
            },
            {
                title: 'Wenig Aufwand',
                text:
                    'Nur dreimal pro Woche 20 min Training lässt sich in jeden Alltag integrieren',
            },
            {
                title: 'Trainiere wann und wo du willst',
                text:
                    'Mit der mobilen App oder am Smart TV einfach überall trainieren',
            },
            {
                title: 'Kein Fitnessstudio notwendig',
                text: 'Für das Training genügt eine Matte und etwas Platz',
            },
            {
                title: 'In Top Form wie Sarah',
                text:
                    'Du trainierst für eine schöne Figur und straffst selbst hartnäckige Problemzonen',
            },
        ],
        cta: {
            text: '7 Tage kostenlos trainieren',
        },
    },
    /* eslint-enable max-len */
})
