import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { connect } from 'react-redux'

import {
    BtnRevWithSignupLink,
    IconGrid,
    ImgTextGrid,
    LpDeviceList,
    LpMotivationalQuote,
    LpWhy,
    PackageUsp,
    TopHeader,
    TopSection,
    Usp,
    ProductGrid,
} from '../../components'

const PageLayout = ({ lpData, bodyClasses }) => (
    <main className={cn(lpData.bodyClass, bodyClasses)}>
        <TopHeader lpData={lpData} showInfluencerLogo />

        <section className="page">
            <TopSection data={lpData} />

            <LpWhy data={lpData.why} />
            <Usp className="content" uspList={lpData.usp[0]} />
            <BtnRevWithSignupLink
                className="btn-rev--primary"
                text={lpData.cta.text.general}
                wrapper
            />

            <ImgTextGrid
                className="lp-stories content"
                data={lpData.successStories}
            />
            <BtnRevWithSignupLink
                className="btn-rev--primary"
                text={lpData.cta.text.general}
                wrapper
            />

            <LpMotivationalQuote className="content" data={lpData.motivation} />
            <IconGrid className="content" data={lpData.iconGrid} />
            <BtnRevWithSignupLink
                className="btn-rev--primary"
                text={lpData.cta.text.general}
                wrapper
            />

            <Usp className="content content--spaced" uspList={lpData.usp[1]} />
            <ImgTextGrid
                className="lp-benefits content"
                data={lpData.benefits}
            />
            <BtnRevWithSignupLink
                className="btn-rev--primary"
                text={lpData.cta.text.general}
                wrapper
            />

            <LpDeviceList className="content" data={lpData.deviceList} />
            <PackageUsp className="content" data={lpData.packageUsp} />
            <ProductGrid data={lpData.product} />
        </section>
    </main>
)

PageLayout.propTypes = {
    lpData: PropTypes.object.isRequired,
    bodyClasses: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
    return {
        bodyClasses: state.bodyClasses,
        lpData: state.lpData,
    }
}

export default connect(mapStateToProps)(PageLayout)
