const name = 'paola'
const id = 8
const programId = 296

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Paola Maria - In 10 Wochen zur Bestform',
        meta: {
            description:
                'Paola Maria bringt dich mit BACK IN SHAPE in 10 Wochen in Bestform',
            keywords:
                'bestform, gesund, paola maria, schlank, cardio intervall, training, fettverbrennung',
            'og:title': 'Paola Maria - In 10 Wochen zur Bestform',
            'og:url': 'https://backinshape.gymondo.fitness/',
            'og:description':
                'Paola Maria bringt dich mit BACK IN SHAPE in 10 Wochen in Bestform',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-paola',

    logo: 'logos/Paola',

    topHeader: 'white ',

    loginModal: false,

    header: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/paola/lp/header/header-v2.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/paola/lp/header/header-mb.jpg',
        btnGratis: 'Gratiswoche starten',
        preview: 'https://akgymondo.akamaized.net/prgpreviews/296_480.mp4',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    title: {},

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            align: 'center',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/paola/lp/usp/usp-1.jpg',
                    title: 'Training mit Paola Maria',
                    text:
                        'Paola Maria hat es geschafft, nach ihrer Schwangerschaft wieder fit zu werden. Ihr Trainer David war dabei eine große Hilfe. Jetzt trainiert ihr zu dritt – bist du bereit?',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/paola/lp/usp/usp-2.jpg',
                    title: 'Ganzkörpertraining in nur 20 Minuten',
                    text:
                        'Jedes Workout umfasst einfache, aber effektive Übungen für den ganzen Körper. Der Fokus liegt ganz klar auf straffenden Übungen und etwas Cardio. Besser kannst du 20 Minuten nicht nutzen.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/paola/lp/usp/usp-3.jpg',
                    title: 'Ohne Equipment',
                    text:
                        'Deine Workouts mit Paola und David benötigen keine Geräte. Du trainierst absolut sicher und bestimmst das Tempo dabei selbst. Matte und Handtuch sind alles, was du brauchst.',
                },
            ],
        },
    ],

    why: {
        title: 'Back in Shape mit Paola Maria: Zurück zur Wohlfühlfigur',
        paragraphs: [
            'In 10 Wochen bringt dich Paola Maria mit Personal Trainer David und einer ganzen Menge Spaß zu deiner persönlichen Wohlfühlfigur. Gemeinsam meistert ihr straffende Übungen für den ganzen Körper, die sich optimal für Einsteiger und Wiedereinsteiger eignen. Paola zeigt dir, wie sie es geschafft hat, nach der Schwangerschaft wieder richtig fit zu werden und ihren neuen Körper zu lieben. Das kannst du auch!',
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/paola/lp/testimonial/testimonial-v2.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/paola/lp/testimonial/testimonial-mb.jpg',
        quote:
            '"Nach der Schwangerschaft wollte ich zügig wieder fit werden und mich wohlfühlen. Mit den Übungen von David ging das viel leichter als erwartet. Du schaffst das auch, wetten?"',
        author: 'Paola Maria',
    },

    iconGrid: {
        title: 'Diese Ziele wirst du erreichen',
        content: [
            {
                icon: 'Slim',
                title: 'Fit werden & wohlfühlen',
                text:
                    'Mit Back in Shape wirst du nach einer Trainingspause wieder fit und straffst deinen ganzen Körper von Kopf bis Fuß.',
            },
            {
                icon: 'Strong',
                title: 'Muskeln definieren',
                text:
                    'Kräftigende Übungen mit dem eigenen Körpergewicht stärken deine Muskeln sanft, ohne dich zu überfordern.',
            },
            {
                icon: 'Yoga',
                title: 'Funktionelles Training',
                text:
                    'Die Workouts und Übungen sind funktionell und helfen dir auch im Alltag dabei, dich besser zu bewegen.',
            },
            {
                icon: 'Challenge',
                title: 'Trainiere wie Paola',
                text:
                    'Paola ist ein tolles Vorbild, wenn es darum geht, wieder in Form zu kommen und sich wohlzufühlen. Starte mit ihr durch!',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/paola/lp/device/devices.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/paola/lp/device/devices-mb.jpg',
    },

    packageUsp: {
        title: 'Das Komplettpaket für deine Wunschfigur',
        text: [
            {
                title: 'Einfach und intensiv',
                text:
                    'Keine komplizierten Übungen. Du trainierst auf deinem Level und in deinem Tempo.',
            },
            {
                title: 'Für deinen Terminkalender',
                text:
                    'Nur 3x20 min Training pro Woche lässt sich problemlos in jeden Alltag integrieren.',
            },
            {
                title: 'Trainiere, wann und wo du willst',
                text:
                    'Jederzeit & überall mit der mobilen App, im Web, per Tablet oder am Smart-TV verfügbar.',
            },
            {
                title: 'Kein Fitnessstudio notwendig',
                text:
                    'Für das Training genügt eine Matte und etwas Platz, zum Beispiel in deinem Wohnzimmer.',
            },
            {
                title: 'Mit Paola zur Wohlfühlfigur',
                text:
                    'Du trainierst 10 Wochen lang für eine straffe, sportliche Figur, auf die du wirklich stolz sein kannst.',
            },
        ],
        cta: {
            text: '7 Tage kostenlos trainieren',
        },
    },
    /* eslint-enable max-len */
})
