import React from 'react'

export default ({ className, onClick }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={className}
            onClick={onClick}
        >
            <g fillRule="nonzero">
                <path d="M12 2.18c3.197 0 3.575.01 4.84.07 1.165.055 1.802.25 2.226.414.557.219.956.478 1.379.896.418.418.677.817.896 1.38.164.423.359 1.055.413 2.225.06 1.265.07 1.643.07 4.84s-.01 3.575-.07 4.84c-.054 1.165-.249 1.802-.413 2.226-.219.557-.478.956-.896 1.379a3.717 3.717 0 0 1-1.38.896c-.423.164-1.055.359-2.225.413-1.265.06-1.643.07-4.84.07s-3.575-.01-4.84-.07c-1.165-.054-1.802-.249-2.226-.413a3.799 3.799 0 0 1-1.379-.896 3.717 3.717 0 0 1-.896-1.38c-.164-.423-.359-1.055-.413-2.225-.06-1.265-.07-1.643-.07-4.84s.01-3.575.07-4.84C2.3 6 2.495 5.363 2.659 4.94c.219-.557.478-.956.896-1.379a3.717 3.717 0 0 1 1.38-.896C5.357 2.5 5.99 2.305 7.16 2.25c1.265-.055 1.643-.07 4.84-.07zm0-2.155c-3.251 0-3.66.015-4.94.07-1.274.06-2.145.259-2.907.557a5.9 5.9 0 0 0-2.121 1.38 5.94 5.94 0 0 0-1.38 2.126C.36 4.92.154 5.79.095 7.066c-.06 1.28-.07 1.683-.07 4.939 0 3.251.015 3.66.07 4.94.06 1.274.259 2.145.557 2.907a5.9 5.9 0 0 0 1.38 2.121 5.834 5.834 0 0 0 2.12 1.38c.763.293 1.634.498 2.909.557 1.28.06 1.683.07 4.939.07 3.256 0 3.66-.015 4.94-.07 1.274-.06 2.145-.259 2.907-.557a5.9 5.9 0 0 0 2.121-1.38 5.834 5.834 0 0 0 1.38-2.12c.293-.763.498-1.634.557-2.909.06-1.28.07-1.683.07-4.939 0-3.256-.015-3.66-.07-4.94-.06-1.274-.259-2.145-.557-2.907a5.9 5.9 0 0 0-1.38-2.121 5.834 5.834 0 0 0-2.12-1.38C19.084.363 18.213.16 16.938.1 15.66.04 15.251.025 12 .025z" />
                <path d="M12 5.85a6.15 6.15 0 1 0 0 12.3 6.15 6.15 0 0 0 0-12.3zm0 10.143a3.993 3.993 0 1 1 0-7.985 3.993 3.993 0 0 1 0 7.985z" />
                <circle cx="18.393" cy="5.607" r="1.439" />
            </g>
        </svg>
    )
}
