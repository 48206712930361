import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import URLS from '../config/urls'

const HeadTagInjector = ({ data }) => {
    const { meta, title, favicon } = data
    const { isTestDomain } = URLS
    const testDomainMeta = {
        robots: 'noindex, nofollow, noodp, noarchive, noydir',
    }
    const domainMeta = isTestDomain
        ? {
              ...testDomainMeta,
              ...(meta || {}),
          }
        : meta

    return (
        <Helmet>
            <title>{title}</title>

            <link
                rel="shortcut icon"
                href={`${process.env.PUBLIC_URL}/favicons/${
                    favicon || 'favicon.ico'
                }`}
            />

            {Object.keys(domainMeta).map(name => (
                <meta name={name} content={meta[name]} key={name} />
            ))}
        </Helmet>
    )
}

HeadTagInjector.propTypes = {
    data: PropTypes.object.isRequired,
}

export default HeadTagInjector
