import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const SimpleHeadBanner = ({ data }) => {
    const { splash, text, font } = data

    return (
        <section className="content-banner">
            <h3 className="content-banner__title">
                <span className={cn('content-banner__splash', font)}>
                    {splash}
                </span>
                {text}
            </h3>
        </section>
    )
}

SimpleHeadBanner.propTypes = {
    data: PropTypes.object.isRequired,
}

export default SimpleHeadBanner
