import { combineReducers } from 'redux'

import { bodyClasses, cookies, links, lpData, menu } from './reducerList'

// each reducer represents a new state entry
const rootReducer = combineReducers({
    bodyClasses,
    cookies,
    links,
    lpData,
    menu,
})

export default rootReducer
