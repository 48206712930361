import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const LpWhy = ({ className, data }) => (
    <section className={cn('lp-why', className)}>
        <div className="lp-why__content">
            <h2 className="lp-why__title mlp-title">{data.title}</h2>

            {data.paragraphs.map(paragraph => (
                <p className="lp-why__text" key={paragraph}>
                    {paragraph}
                </p>
            ))}

            {data.link && (
                <a
                    href={data.link.href}
                    className="lp-why__link btn-rev btn-rev--ghost"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {data.link.text}
                </a>
            )}
        </div>
    </section>
)

LpWhy.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
}

LpWhy.defaultProps = {
    className: '',
}

export default LpWhy
