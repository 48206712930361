import React from 'react'

import influencerData from '../../data-influencer/shape-360'
import PageBuilder from '../PageBuilder'
import PageLayout from './PageLayout'

const Shape360 = () => (
    <PageBuilder influencerData={influencerData}>
        <PageLayout />
    </PageBuilder>
)

export default Shape360
