import React from 'react'
import PropTypes from 'prop-types'

import BtnRevWithSignupLink from '../cta/BtnRevWithSignupLink'
import HeaderLogo from './logos/HeaderLogo'
import LoginButton from '../buttons/LoginButton'

const TopNavigation = ({ lpData, links, config }) => (
    <nav className="top-nav">
        <HeaderLogo data={lpData.influencerLogo} />

        <ul className="top-nav__list">
            <li className="top-nav__item">
                <a href={links.signup} title={config.signup}>
                    {config.signup}
                </a>
            </li>

            <li className="top-nav__item">
                <a
                    href={config.instructions.url}
                    title={config.instructions.label}
                >
                    {config.instructions.label}
                </a>
            </li>

            <li className="top-nav__item">
                <BtnRevWithSignupLink
                    className="btn-rev--secondary btn--small"
                    text={
                        (lpData.header && lpData.header.btnGratis) ||
                        config.button
                    }
                    wrapper={false}
                />
            </li>

            <li className="top-nav__item">
                <LoginButton showModal={lpData.loginModal} data={lpData} />
            </li>
        </ul>
    </nav>
)

TopNavigation.propTypes = {
    links: PropTypes.object.isRequired,
    lpData: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
}

export default TopNavigation
