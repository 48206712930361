import React from 'react'
import PropTypes from 'prop-types'

import URLS from '../../../config/urls'
import DynamicSvgIcon from '../../DynamicSvgIcon'
import GymondoLogo from '../../../images/svg/logos/GymondoLogo'

const HeaderLogo = ({ data }) => {
    if (!data) {
        return (
            <a href={URLS.GYM_BASE_URL} className="top-header__logo">
                <GymondoLogo className="header-logo__svg" />
            </a>
        )
    }

    const { url, svg, target, className, img, imgSrcSet, imgAlt } = data

    const logo = svg ? (
        <DynamicSvgIcon className="header-logo__svg" name={svg} />
    ) : (
        <img src={img} srcSet={imgSrcSet} alt={imgAlt || ''} />
    )

    return (
        <a href={url} className="top-header__logo" target={target || '_self'}>
            <div className={`header-logo--${className}`}>{logo}</div>
        </a>
    )
}

HeaderLogo.defaultProps = {
    data: null,
}

HeaderLogo.propTypes = {
    data: PropTypes.object,
}

export default HeaderLogo
