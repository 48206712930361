const name = 'adrienne'
const id = 6
const programId = 271

export default Object.freeze({
    /* eslint-disable max-len */
    id,
    head: {
        title: 'Adrienne Koleszár - In 12 Wochen zur Bestform',
        meta: {
            description:
                'Adrienne Koleszár bringt dich mit CROSS & SHAPE in 12 Wochen in Bestform',
            keywords:
                'bestform, gesund, adrienne koleszar, schlank, cardio intervall, training, fettverbrennung',
            'og:title': 'Adrienne Koleszár - In 12 Wochen zur Bestform',
            'og:url': 'https://www.crossandshape.de/',
            'og:description':
                'Adrienne Koleszár bringt dich mit CROSS & SHAPE in 12 Wochen in Bestform',
        },
        favicon: 'favicon.ico',
    },

    bodyClass: 'mlp-adry',

    logo: 'logos/Adrienne',

    topHeader: 'white ',

    loginModal: false,

    header: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/adrienne/lp/header/header.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/adrienne/lp/header/header-mb.jpg',
        btnGratis: 'Gratis Woche starten',
    },

    links: {
        signup: {
            channel: name,
            channel_id: id,
            program_id: programId,
        },
    },

    cta: {
        text: {
            general: '7 Tage kostenlos trainieren',
        },
    },

    usp: [
        {
            id: 0,
            font: 'guillon',
            content: [
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/adrienne/lp/usp/usp-1.jpg',
                    title: 'Effektives Training',
                    text:
                        'In jedem Workout trainierst du auf deinem Level, egal ob du Einsteiger oder schon fortgeschritten bist: CROSS & SHAPE ist effektives Krafttraining für zuhause oder unterwegs.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/adrienne/lp/usp/usp-2.jpg',
                    title: 'Maximale Fettverbrennung ',
                    text:
                        'Die Wechsel zwischen Anstrengung und Erholung pushen deinen Stoffwechsel. Durch diese Intervalle verbrennst du massenhaft Kalorien während und nach dem Training.',
                },
                {
                    img:
                        '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/adrienne/lp/usp/usp-3.jpg',
                    title: 'Mehr Selbstbewußtsein',
                    text:
                        'Persönliche Tipps von Adrienne helfen dir Herausforderungen zu meistern. Mit dem richtigen Mindset baust du nach und nach körperliche und mentale Stärke auf.',
                },
            ],
        },
    ],

    why: {
        title:
            'In Bestform mit Home Workouts im Crosstraining Style - intensiv, athletisch, funktional.',
        paragraphs: [
            'In nur 12 Wochen bringt dich Adrienne in Bestform. CROSS & SHAPE kombiniert Krafttraining mit Kurzhanteln mit Cardio Intervall Training. Genau dieser Mix ist das Geheimrezept von Adriennes Top Figur. Starte jetzt dein Programm für einen starken, definierten Körper und straffe Kurven!',
        ],
    },

    motivation: {
        img:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/adrienne/lp/testimonial/testimonial.jpg',
        imgMobile:
            '//d11y8h2xe0qeoe.cloudfront.net/static/influencer/adrienne/lp/testimonial/testimonial-mb.jpg',
        quote:
            '"Power, Energie, Herausforderungen... Mit CROSS & SHAPE trainieren wir 12 Wochen zusammen für deinen Traumbody. Ich freue mich auf euch."',
        author: 'Adrienne Koleszár',
    },

    iconGrid: {
        title: 'Diese Ziele wirst du erreichen',
        content: [
            {
                icon: 'Fire',
                text:
                    'Kalorien verbrennen - Mit High Intensity Power Training verbrennst du massenhaft Kalorien und Fett. Die Workouts sind ideal, wenn du abnehmen möchtest.',
            },
            {
                icon: 'Strong',
                text:
                    'Kraft aufbauen - Krafttraining für einen sexy Traumbody: Übungen mit Kurzhanteln powern dich aus, machen dich stark und formen deine Muskeln.',
            },
            {
                icon: 'Slim',
                text:
                    'Figur straffen - Deine Muskulatur wird intensiv gestärkt. Dein Körper fühlt sich straffer und fester an. Die Workouts bringen deine Figur in Bestform.',
            },
            {
                icon: 'Heart',
                text:
                    'Skills lernen - In jedem Workout zeigt dir Adrienne und ihr Team eine Übung im Detail, sodass du lernst die Moves im Training richtig zu machen.',
            },
        ],
    },

    deviceList: {
        title:
            'Alle Inhalte auch in der mobilen App für IOS und Android und auf deinem Smart TV mit Apple TV oder Amazon Fire TV',
        img: `${process.env.PUBLIC_URL}/static/img/adrienne/devices.jpg`,
        imgMobile: `${process.env.PUBLIC_URL}/static/img/adrienne/devices_mobile.jpg`,
    },

    packageUsp: {
        title: 'Mein Komplett-Paket für deine Traumfigur',
        text: [
            {
                title: 'Einfach und intensiv ',
                text:
                    'Keine komplizierten Übungen. Du trainierst auf deinem Level in deinem Tempo',
            },
            {
                title: 'Wenig Aufwand',
                text:
                    'Nur dreimal pro Woche 20 min Training lässt sich in jeden Alltag integrieren',
            },
            {
                title: 'Trainiere wann und wo du willst',
                text:
                    'Mit der mobilen App oder am Smart TV einfach überall trainieren',
            },
            {
                title: 'Kein Fitnessstudio notwendig',
                text:
                    'Für das Training genügt eine Matte und Kurzhanteln oder Wasserflaschen',
            },
            {
                title: 'In Top Form wie Adrienne',
                text:
                    'Du trainierst für eine Top Figur und sagst hartnäckigen Problemzonen den Kampf an',
            },
        ],
        cta: {
            text: '7 Tage kostenlos trainieren',
        },
    },
    /* eslint-enable max-len */
})
