import React from 'react'
import { Redirect } from 'react-router'

import hostnameTable from './hostnameTable'
import FallbackRedirect from './FallbackRedirect'

const DomainApplication = () => {
    const { hostname, pathname, search } = window.location
    const subAppToLoad = hostnameTable[hostname]

    if (!subAppToLoad) {
        return <FallbackRedirect />
    }

    return <Redirect to={hostnameTable[hostname] + pathname + search} />
}

export default DomainApplication
