import PrefixEnum from '../routing/prefixes'

export default Object.freeze({
    BIG: {
        [PrefixEnum.DANIEL_AMINATI]:
            '//classic.machdichkrass.de/de/home/big/refund',
        [PrefixEnum.SOPHIA_THIEL]:
            '//classic.sophia-thiel.com/de/home/big/refund',
    },
    FALLBACK: '//www.gymondo.com',
})
