import React from 'react'

export default ({ className, onClick }) => {
    return (
        <svg
            width="30"
            height="21"
            viewBox="0 0 30 21"
            className={className}
            onClick={onClick}
        >
            <path
                fillRule="nonzero"
                d="M28.874 4.466s-.284-2.007-1.155-2.89C26.613.413 25.37.407 24.803.34 20.732.043 14.616.043 14.616.043h-.012s-6.11 0-10.187.297c-.568.068-1.81.074-2.916 1.235C.631 2.46.346 4.466.346 4.466S.056 6.82.056 9.18v2.212c0 2.353.29 4.713.29 4.713S.63 18.113 1.5 18.996c1.106 1.162 2.564 1.124 3.213 1.242 2.328.222 9.896.29 9.896.29s6.116-.006 10.193-.303c.568-.067 1.81-.074 2.916-1.235.871-.883 1.155-2.891 1.155-2.891s.29-2.354.29-4.714V9.174c0-2.354-.29-4.708-.29-4.708zM11.602 14.06V5.881l7.864 4.102-7.864 4.077z"
            />
        </svg>
    )
}
