import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders a simple ul with li containing text.
 *
 * @param {Object} props
 * @param {Array} props.list
 * @return {React.Component}
 */
const UspListText = ({ list }) => (
    <ul>
        {list.map(item => (
            <li key={item}>{item}</li>
        ))}
    </ul>
)

UspListText.propTypes = {
    list: PropTypes.array.isRequired,
}

export default UspListText
