import React, { Component } from 'react'
import PropTypes from 'prop-types'

const PageWithTracking = PageToWrap =>
    class extends Component {
        constructor(props) {
            super(props)

            const {
                location: { pathname },
            } = this.props

            this.state = {
                dataLayer: window.dataLayer || [],
                pathname,
            }
        }

        static get propTypes() {
            return {
                location: PropTypes.object.isRequired,
            }
        }

        componentDidMount() {
            this.trackWebsite()
        }

        trackWebsite() {
            const { dataLayer, pathname } = this.state

            if (!pathname) {
                return
            }

            const pathSegments = pathname.substr(1).split('/')

            // only the first path of the paths is important in this case
            dataLayer.push(
                {
                    event: 'set_custom_dimension',
                    name: 'website',
                    website: pathSegments[0].toUpperCase(),
                },
                {
                    event: 'set_custom_dimension',
                    name: 'language',
                    value: 'de',
                }
            )
        }

        render() {
            return <PageToWrap {...this.props} />
        }
    }

export default PageWithTracking
