/* eslint new-cap: 0 */
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import DeepLinkController from './DeepLinkController'
import PageWithTracking from '../pages/hoc/PageWithTracking'
import DomainApplication from './DomainApplication'

import routes from './routingTable'

const GymondoInfluencerRoutes = () => (
    <Router>
        <Switch>
            {/* do not wrap the DomainApplication with a tracking */}
            <Route exact path="/" component={DomainApplication} />

            {routes.map(route => (
                <Route
                    key={route.path}
                    exact
                    path={route.path}
                    component={PageWithTracking(route.component)}
                />
            ))}

            {/* when a given path did not match anything, show a 404 page */}
            {/* this must always be the last element! */}
            <Route component={DeepLinkController} />
        </Switch>
    </Router>
)

export default GymondoInfluencerRoutes
