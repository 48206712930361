import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

// we are using "dynamic" props list here and we are not
// destructuring the props object in the parameter list
const BtnPay = ({ className, disabled, href, isATag, onClick, text }) => {
    if (isATag) {
        return (
            <a className={cn('btn-pay', className)} href={href} title={text}>
                {text}
            </a>
        )
    }

    return (
        <button
            type="button"
            disabled={disabled}
            className={cn('btn-pay', className)}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

BtnPay.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    isATag: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
}

BtnPay.defaultProps = {
    className: '',
    href: null,
    isATag: false,
    disabled: false,
    onClick: () => {},
}

export default BtnPay
