import _ from 'lodash'

import React from 'react'
import PropTypes from 'prop-types'

import BtnRev from '../cta/BtnRev'
import LoginButtonModal from './LoginButtonModal'
import URLS from '../../config/urls'

const LoginButton = ({ showModal, data }) => {
    const locale = data.locale || 'de'
    const linkTupels = _.toPairs(data.links.signup)
    const influencerParameters = linkTupels
        .map(tupel => tupel.join('='))
        .join('&')
    const linkParameters = `${influencerParameters}&login=true`
    const LoginUrl = `${URLS.GYM_BASE_URL}/${locale}/checkout/?${linkParameters}`
    const text = (data.header && data.header.btnLogin) || 'Einloggen'

    return (
        <>
            {showModal ? (
                <LoginButtonModal />
            ) : (
                <BtnRev
                    className="btn--small btn-rev--ghost"
                    isATag
                    href={LoginUrl}
                    text={text}
                />
            )}
        </>
    )
}

LoginButton.defaultProps = {
    showModal: false,
    data: {},
}

LoginButton.propTypes = {
    showModal: PropTypes.bool,
    data: PropTypes.object,
}

export default LoginButton
