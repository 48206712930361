import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BtnRev from '../cta/BtnRev'
import UserLoginModal from '../modals/UserLoginModal'

class LoginButtonModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false,
        }

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    openModal() {
        this.setState({ modalOpen: true })
    }

    closeModal() {
        this.setState({ modalOpen: false })
    }

    render() {
        const { modalOpen } = this.state
        const { lpData } = this.props

        if (!lpData) {
            return <h3>... loading data</h3>
        }

        return (
            <>
                <UserLoginModal
                    open={modalOpen}
                    onClose={this.closeModal}
                    login={lpData.links.login}
                    texts={lpData.modals.login}
                />
                <BtnRev
                    className="btn--small btn-rev--ghost"
                    onClick={this.openModal}
                    text="Einloggen"
                />
            </>
        )
    }
}

LoginButtonModal.propTypes = {
    lpData: PropTypes.object,
}

LoginButtonModal.defaultProps = {
    lpData: {},
}

function mapStateToProps(state) {
    return {
        lpData: state.lpData,
    }
}

export default connect(mapStateToProps)(LoginButtonModal)
