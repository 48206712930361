/* eslint react/display-name: 0, react/prop-types: 0 */
import React from 'react'

export default ({ className, onClick }) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 500 500"
            className={className}
            onClick={onClick}
        >
            <polygon
                points="55.7,15.2 13.2,57.6 208.2,252.6 13.2,447.5 55.7,489.9 250,295.6 444.3,489.9 486.8,447.5 293,252.6 486.8,57.6
            444.3,15.2 250,209.5"
            />
        </svg>
    )
}
